import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateAdvertisementSliderDto } from "types/entitiesTypes/advertisementSlider";
import * as yup from "yup";

interface IAddAdvertisementSliderProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddAdvertisementSlider: React.FC<IAddAdvertisementSliderProps> = (
  props
) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm =
    React.useRef<FormikProps<ICreateAdvertisementSliderDto>>(null);

  const mutation = useCreateService<ICreateAdvertisementSliderDto, string>(
    AppUrls.AdvertisementSlider
  );

  const validationSchema = yup.object({
    link: yup.string().required(String(messages["validation.required"])),
    file: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        } 
        maxWidth="sm"
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            link: "",
            file: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            if (uploadedFiles) data = { ...data, file: uploadedFiles[0] };
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AppTextField
                    label={<IntlMessages id="common.link" />}
                    name="link"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <AppUploadPhoto
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  keyName="file"
                  formRef={refForm}
                  errors={!!errors.file}
                  uploadText="common.uploadPhoto"
                  helperText="validation.file"
                  uploadModernXs={12}
                  appGridXs={12}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddAdvertisementSlider;
