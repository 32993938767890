import { Grid, Hidden } from "@mui/material";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";

import { IAttendanceList } from "types/entitiesTypes/attendance";
import AttendanceCard from "./AttendanceCard";
import { AttendanceColumns } from "./AttendanceColumns";
interface IAttendancePageProps {
  sessionId: string;
}
const AttendancePage: FC<IAttendancePageProps> = (props) => {
  const { sessionId } = props;
  const { messages } = useIntl();

  const {
    data: AttendanceList,
    isFetching,
    refetch,
    error,
  } = useGetByIdService<Array<IAttendanceList>>(
    AppUrls.Session,
    `${sessionId}/Attendance`
  );

  const columns = AttendanceColumns();

  return (
    <>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={AttendanceList?.length ?? 0}
              pageNumber={1}
              //for pagination
              onPageSizeChange={() => {}}
              onPageChange={() => {}}
              pageSize={AttendanceList?.length ?? 0}
              columns={columns}
              rows={AttendanceList ?? []}
              loading={isFetching}
              //for selection

              checkboxSelection={false}
              //for sorting
              onSortModelChange={() => {}}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <AttendanceCard
              AttendanceList={AttendanceList!}
              loading={isFetching}
              errorMessage={error!}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default AttendancePage;
