import IntlMessages from "@crema/utility/IntlMessages";
import { Divider, Grid, Typography } from "@mui/material";
import SubscriptionStatusBadge from "components/basicComponents/badge/SubscriptionStatusBadge";
import AppItemView from "components/basicComponents/others/AppItemView";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { removeHTMLTags } from "helpers";
import { useIntl } from "react-intl";
import { Fonts } from "shared/constants/AppEnums";
import { IInvoiceDetailsDto } from "types/entitiesTypes/invoices";
import { IStudentSubscriptionDetailsDto } from "types/entitiesTypes/studentSubscription";

interface IUserDetailsProps {
  invoiceItem: IInvoiceDetailsDto;
}

const InvoiceDetails = (props: IUserDetailsProps) => {
  const { invoiceItem } = props;
  const { messages } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={invoiceItem as IInvoiceDetailsDto}
      >
        <Grid container item spacing={5} xs={12}>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.BasicInformation" />
            </Divider>
          </Grid>

          <AppItemView
            label="invoices.invoiceNumber"
            value={invoiceItem?.invoiceNumber}
            sm={3}
          />

          <AppItemView
            label="invoices.diplomaCost"
            value={invoiceItem?.diplomaCost}
            sm={3}
          />
          <AppItemView
            label="invoices.totalCost"
            value={invoiceItem?.totalCost}
            sm={3}
          />
          {/* <AppItemView
            label="diplomas.discountCost"
            value={invoiceItem?.discount}
            sm={3}
          />
          <AppItemView
            label="diplomas.instructorSalary"
            value={invoiceItem?.instructorSalary}
            sm={3}
          />
          <AppItemView
            label="diplomas.educationalCenterFees"
            value={invoiceItem?.educationalCenterFees}
            sm={3}
          />

          <AppItemView
            label="diplomas.expenses"
            value={invoiceItem?.expenses}
            sm={3}
          />
          <AppItemView label="diplomas.tax" value={invoiceItem?.tax} sm={3} /> */}
          {/* <AppItemView
            label="diplomas.academyProfit"
            value={invoiceItem?.academyProfit}
            sm={3}
          /> */}
          <AppItemView label="diplomas.tax" value={invoiceItem?.tax} sm={3} />
          <AppItemView
            label="invoices.issueDate"
            value={invoiceItem?.issueDate}
            type="dateTime"
            sm={3}
          />
          {/* <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="invoices.info" />
            </Divider>
          </Grid>
          <AppItemView
            label="student.subscriptionType"
            value={
              messages[
                invoiceItem?.subscription?.subscriptionType === 1
                  ? "student.subscriptionType.onePayment"
                  : "student.subscriptionType.multiPayment"
              ] as string
            }
            sm={4}
          />
          <AppItemView
            label="student.subscriptionDate"
            value={invoiceItem?.subscription?.subscriptionDate}
            type="dateTime"
            sm={4}
          />
          <Grid item xs={12} sm={4}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"student.subscriptionStatus"} />
            </Typography>
            <SubscriptionStatusBadge
              subscriptionStatus={invoiceItem?.subscription?.subscriptionStatus}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.info" />
            </Divider>
          </Grid>
          <AppItemView
            label="common.nameAr"
            value={invoiceItem?.subscription?.diploma?.nameAr}
            sm={4}
          />
          <AppItemView
            label="common.nameEn"
            value={invoiceItem?.subscription?.diploma?.nameEn}
            sm={4}
          />
          <AppItemView
            label="diplomas.isCertified"
            value={invoiceItem?.subscription?.diploma?.isCertified}
            sm={4}
            type="isExist"
          />
          {/* <AppItemView
            label="diplomas.outcomes"
            value={removeHTMLTags(invoiceItem?.subscription?.diploma?.outcomes)}
            sm={12}
          />
          <AppItemView
            label="common.descriptionAr"
            value={removeHTMLTags(
              invoiceItem?.subscription?.diploma?.descriptionAr
            )}
            sm={6}
          />
          <AppItemView
            label="common.descriptionEn"
            value={removeHTMLTags(
              invoiceItem?.subscription?.diploma?.descriptionEn
            )}
            sm={6}
          /> */}

          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="student.info" />
            </Divider>
          </Grid>
          <AppItemView
            label="student.name"
            value={invoiceItem?.subscription?.student?.fullName}
            sm={4}
          />
          <AppItemView
            label="common.email"
            value={invoiceItem?.subscription?.student?.email}
            sm={4}
          />
          <AppItemView
            label="common.nationalNumber"
            value={invoiceItem?.subscription?.student?.nationalNumber}
            sm={4}
          />
          <AppItemView
            label="student.academicNumber"
            value={invoiceItem?.subscription?.student?.academicNumber}
            sm={4}
            type="isPhoneNumber"
          />
          <AppItemView
            label="common.phone"
            value={invoiceItem?.subscription?.student?.phoneNumber}
            sm={4}
            type="isPhoneNumber"
          />
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default InvoiceDetails;
