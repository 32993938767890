import { Card, CardContent, Grid } from "@mui/material";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import EditWorkShops from "components/scientificContentManagement/workShops/editWorkShops/EditWorkShop";
import { getValueBasedOnLang } from "helpers/index";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IWorkShopsDetailsDto } from "types/entitiesTypes/workShops";

const WorkShopsEditPage: React.FC = () => {
  const { messages } = useIntl();
  const { WorkShopsIdParams } = useParams();
  const {
    data: WorkShopsItem,
    error,
    isLoading,
  } = useGetByIdService<IWorkShopsDetailsDto>(
    AppUrls.WorkShops,
    WorkShopsIdParams || ""
  );

  return (
    <Grid container spacing={3}>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
            {
              name: messages["sidebar.WorkShops"],
              path: "/scientificContentManagement/WorkShops",
            },
            {
              name: messages["WorkShops.edit"],
            },
            {
              name: getValueBasedOnLang(
                WorkShopsItem?.nameAr || "",
                WorkShopsItem?.nameEn || ""
              ),
            },
          ]}
          currentPathName={""}
        />

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <EditWorkShops
                isLoading={isLoading}
                WorkShopsItem={WorkShopsItem!}
              />
            </CardContent>
          </Card>
        </Grid>
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default WorkShopsEditPage;
