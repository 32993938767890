import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  useAdvanceFilterAutocompleteService,
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

import { CoursesColumns } from "./CoursesColumns";
import { ICoursesListDto, ICoursesSearch } from "types/entitiesTypes/courses";
import AddCourse from "components/trainStructure/courses/AddCourse";
import EditCourse from "components/trainStructure/courses/EditCourse";
import { useParams } from "react-router-dom";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import { debounce } from "lodash";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import IntlMessages from "@crema/utility/IntlMessages";
import CoursesCard from "./CoursesCard";

const CoursesPages = () => {
  const { messages, locale } = useIntl();
  const { diplomaIdParams } = useParams();
  const [search, setSearch] = useState<ICoursesSearch>({
    pageNumber: 1,
    pageSize: 10,
    diplomaId: diplomaIdParams || null,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  let objectFilter = {
    diplomaId: search.diplomaId ? search.diplomaId : null,
    termId: search.termId ? search.termId : null,
  };
  const [selectedRow, setSelectedRow] = useState<ICoursesListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>("");
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: coursesList,
    isFetching,
    error,
  } = useGetListService<ICoursesListDto>(AppUrls.Courses, {
    ...search,
    advancedFilter,
  });

  const {
    data: diplomasList,
    isFetching: isFetchingDiplomas,
    refetch: refetchDiplomas,
  } = useAdvanceFilterAutocompleteService<IDiplomasListDto>(
    AppUrls.Diplomas,
    {
      keyword: diplomaKeyword,
      pageSize: 10,
      pageNumber: diplomaPageNumber,
    },
    Boolean(!diplomaIdParams)
  );

  useEffect(() => {
    if (Boolean(!diplomaIdParams)) refetchDiplomas();
  }, [diplomaPageNumber, diplomaIdParams]);

  // For details
  const handleDetails = useCallback((selectedRow: ICoursesListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: ICoursesListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: ICoursesListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  //for Activation
  const handleToggleActive = useCallback((selectedRow: ICoursesListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);
  const columns = CoursesColumns(
    handleToggleActive,
    handleUpdate,
    handleDelete,
    handleDetails
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.Courses,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.Courses,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    { key: "nameAr", value: String(messages["common.nameAr"]) },
    {
      key: "nameEn",
      value: String(messages["common.nameEn"]),
    },
    { key: "diplomaResult.nameAr", value: String(messages["diploma.nameAr"]) },
    {
      key: "diplomaResult.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
    {
      key: "startDate",
      value: String(messages["common.startDate"]),
    },
    {
      key: "endDate",
      value: String(messages["common.endDate"]),
    },

    {
      key: "isActive",
      value: String(messages["common.isActive"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
          ]}
          currentPathName={"sidebar.courses"}
          isShow={!diplomaIdParams}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="course.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Courses}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={coursesList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.courses"])}
            pdfHeaders={pdfHeaders}
            withExportExcelButton={true}
            customQueries={{
              ...objectFilter,
            }}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              Boolean(!diplomaIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="diplomas.name"
                  fieldName="diplomaId"
                  options={diplomasList?.data}
                  isLoading={isFetchingDiplomas}
                  searchText={diplomaKeyword}
                  setSearchText={setDiplomaKeyword}
                  onChangeTextField={debounce((event) => {
                    setDiplomaKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={diplomaPageNumber}
                  setPageNumber={setDiplomaPageNumber}
                  hasNextPage={diplomasList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              <AppAdvanceFilterSelect
                fieldName="termId"
                label={"course.semester"}
                labelForView={locale == "en-US" ? "semeste" : "seme"}
                advancedFilter={search}
                setAdvancedFilter={setSearch}
              >
                <MenuItem value={1}>
                  <IntlMessages id="course.semester.first" />
                </MenuItem>
                <MenuItem value={2}>
                  <IntlMessages id="course.semester.second" />
                </MenuItem>
              </AppAdvanceFilterSelect>,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={coursesList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={coursesList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <CoursesCard
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              coursesList={coursesList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((coursesList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              handleToggleActive={handleToggleActive}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddCourse
          label="course.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showEditModal && (
        <EditCourse
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="course.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.nameEn || "",
          }}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"course.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          title={"course.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "course.actions.deactivationTitle"
              : "course.actions.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "course.actions.deactivationConfirmation"
              : "course.actions.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default CoursesPages;
