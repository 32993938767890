import IntlMessages from "@crema/utility/IntlMessages";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  Grid,
  Hidden,
  Typography
} from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppItemView from "components/basicComponents/others/AppItemView";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IExamQuestionDetailsDto } from "types/entitiesTypes/examQuestions";

interface IExamQuestionDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const ExamQuestionDetails = (props: IExamQuestionDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale, messages } = useIntl();

  const {
    data: examQuestionItem,
    error,
    isLoading,
  } = useGetByIdService<IExamQuestionDetailsDto>(AppUrls.ExamQuestions, id);

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && examQuestionItem?.textEn
            ? `- ${examQuestionItem?.textEn!}`
            : `- ${examQuestionItem?.textAr!}`
        }
        label="exams.question.details"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={examQuestionItem as IExamQuestionDetailsDto}
        maxWidth="sm"
        columns={12}
        tabs={[
          {
            id: "1",
            label: "details",
            component: (
              <Grid container spacing={5} item xs={12}>
                <AppItemView
                  label="exams.question.textAr"
                  value={examQuestionItem?.textAr}
                  sm={3}
                />
                <AppItemView
                  label="exams.question.textEn"
                  value={examQuestionItem?.textEn}
                  sm={3}
                />
                <AppItemView
                  label="exam.questions.type"
                  value={
                    examQuestionItem?.type == 2
                      ? (messages["exam.questions.type.single"] as string)
                      : (messages["exam.questions.type.multi"] as string)
                  }
                  sm={3}
                />
              </Grid>
            ),
          },

          {
            id: "2",
            label: "examQuestion.answers",
            component: (
              <Grid container spacing={5} item xs={12}>
                <Hidden mdDown>
                  <Grid item xs={12}>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: "#003654",
                          borderColor: "#003654",
                          color: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "start",
                              padding: "8px",
                            }}
                          >
                            <IntlMessages id="exam.questions.option.textAr" />
                          </th>
                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "start",
                              padding: "8px",
                            }}
                          >
                            <IntlMessages id="exam.questions.option.textEn" />
                          </th>

                          <th
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "start",
                              padding: "8px",
                            }}
                          >
                            <IntlMessages id="exam.questions.options.isCorrect" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {examQuestionItem?.options?.map((item, index) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "start",
                                  padding: "8px",
                                }}
                              >
                                {item.textAr}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "start",
                                  padding: "8px",
                                }}
                              >
                                {item.textEn}
                              </td>

                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "start",
                                  padding: "8px",
                                }}
                              >
                                {item.isCorrect ? (
                                  <CheckIcon color="info" />
                                ) : (
                                  <CloseIcon color="error" />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  {examQuestionItem?.options?.length != undefined &&
                  examQuestionItem?.options.length >= 1 ? (
                    examQuestionItem?.options?.map((item, index) => (
                      <>
                        <Grid key={`examQuestionItem${index}`} item xs={12}>
                          <DataItem
                            label={
                              messages["exam.questions.option.textAr"] as string
                            }
                            value={item?.textAr}
                            marginY={2}
                          />

                          <DataItem
                            label={
                              messages["exam.questions.option.textEn"] as string
                            }
                            value={item?.textEn}
                            marginY={2}
                          />

                          <DataItem
                            label={
                              messages[
                                "exam.questions.options.isCorrect"
                              ] as string
                            }
                            value={
                              item?.isCorrect ? (
                                <CheckIcon color="info" />
                              ) : (
                                <CloseIcon color="error" />
                              )
                            }
                            marginY={2}
                          />
                        </Grid>
                        <Grid key={`DividerHorizontal${index}`} xs={12}>
                          <Divider orientation="horizontal" />
                        </Grid>
                      </>
                    ))
                  ) : (
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      item
                      xs={12}
                      marginTop={10}
                    >
                      <Typography variant="h5" component={"span"}>
                        {messages["common.dataFound"]}
                      </Typography>
                    </Grid>
                  )}
                </Hidden>
              </Grid>
            ),
          },
        ]}
      />
    </>
  );
};

export default ExamQuestionDetails;
