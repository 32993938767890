import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Pagination,
  Typography,
} from "@mui/material";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import defaultImage from "assets/appDefaultImages/branch.png";
import { AxiosError } from "axios";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import AppIsExistView from "components/basicComponents/others/AppIsExistView";

interface IDiplomaCardProps {
  handleDelete: (row: IDiplomasListDto) => void;
  handleToggleActive: (row: IDiplomasListDto) => void;
  diplomaList: Array<IDiplomasListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const DiplomaCard: FC<IDiplomaCardProps> = (props) => {
  const {
    handleToggleActive,
    handleDelete,
    diplomaList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();

  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {diplomaList?.length != undefined && diplomaList?.length >= 1 ? (
          diplomaList?.map((item, index) => (
            <Grid key={`diplomaList${index}`} item xs={12}>
              <Card>
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    // image={
                    //   item.coverPhoto
                    //     ? `${BaseFileUrl}${item.coverPhoto}`
                    //     : defaultImage
                    // }
                    image={
                      item.coverPhoto
                        ? `${generatePresignedUrl(
                            item.coverPhoto,
                            process.env.NODE_ENV
                          )}`
                        : defaultImage
                    }
                    sx={{ objectFit: "cover", aspectRatio: "9/6" }}
                  />

                  <Box
                    marginX={4}
                    marginTop={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap={3}
                  >
                    {/* Details */}
                    <AppIconButton
                      label="table.actions.details"
                      withLink={true}
                      toPathLink={`/scientificContentManagement/diplomas/profile/${item?.id}`}
                      childComp={
                        <RemoveRedEyeIcon
                          fontSize="small"
                          sx={{ color: "#007991" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Details}
                    />

                    {/* Edit */}
                    <AppIconButton
                      label="table.actions.edit"
                      withLink={true}
                      toPathLink={`/scientificContentManagement/diplomas/edit/${item?.id}`}
                      childComp={
                        <EditOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#A0CCDA" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Edit}
                    />

                    {/* Activation */}
                    <AddDataGridActivation
                      value={item.isActive}
                      handleClick={() => {
                        handleToggleActive(item);
                      }}
                      // For Permissions
                      schemaName={SchemaName.ClientsManagement}
                      entityName={EntityName.Departments}
                      action={PermissionActionsTypes.Activate}
                    />

                    {/* Delete */}
                    <AppIconButton
                      label="table.actions.delete"
                      handleClick={() => {
                        handleDelete(item);
                      }}
                      childComp={
                        <DeleteOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#D72638" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Delete}
                    />
                  </Box>

                  <CardContent
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 3,
                      paddingTop: "0 !important",
                    }}
                  >
                    <DataItem
                      label={messages["common.nameAr"] as string}
                      value={item?.nameAr}
                    />

                    <DataItem
                      label={messages["common.nameEn"] as string}
                      value={item?.nameEn}
                    />
                    {/* <DataItem
                      label={messages["diplomas.instructorSalary"] as string}
                      value={item?.instructorSalary}
                    /> */}
                    {/* <DataItem
                      label={messages["diplomas.coursesCount"] as string}
                      value={item?.coursesCount}
                    /> */}
                    <DataItem
                      label={messages["diplomas.startDate"] as string}
                      value={
                        item.startDate
                          ? moment(item?.startDate).format("YYYY-MM-DD")
                          : "-"
                      }
                    />
                    <DataItem
                      label={messages["diplomas.category"] as string}
                      value={getValueBasedOnLang(
                        item?.category?.nameAr,
                        item?.category?.nameEn
                      )}
                    />
                    <DataItem
                      label={messages["diplomas.region"] as string}
                      value={getValueBasedOnLang(
                        item?.region?.nameAr,
                        item?.region?.nameEn
                      )}
                    />
                    {/* <DataItem
                      label={messages["diplomas.singlePaymentCost"] as string}
                      value={item.singlePaymentCost}
                    />
                    <DataItem
                      label={
                        messages["diplomas.multiplePaymentsCost"] as string
                      }
                      value={item.multiplePaymentsCost}
                    /> */}
                    <DataItem
                      label={messages["diplomas.isFree"] as string}
                      value={<AppIsExistView isExist={item.isFree} />}
                    />
                    <DataItem
                      label={messages["common.isActive"] as string}
                      value={<StatusBadge isActive={item?.isActive} />}
                    />
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default DiplomaCard;
