import { Grid } from "@mui/material";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { getValueBasedOnLang } from "helpers";

import { FC } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IWorkShopAttendanceList,
  IWorkshopSessionsAttendeceDetailsDto,
} from "types/entitiesTypes/workshopAttendance";

interface IComponentOneProps {
  WorkShopSessionItem: IWorkShopAttendanceList;
}

const ComponentOne: FC<IComponentOneProps> = (props) => {
  const { WorkShopSessionItem } = props;
  const { locale, messages } = useIntl();
  return (
    <>
      <Grid container spacing={5} item xs={12}>
        <AppItemView
          label="student.name"
          value={WorkShopSessionItem?.studentName}
          sm={6}
        />
        <AppItemView
          label="WorkShops.name"
          value={getValueBasedOnLang(
            WorkShopSessionItem?.workshopNameAr,
            WorkShopSessionItem?.workshopNameEn
          )}
          sm={6}
        />
        <AppItemView
          label="instructor.name"
          value={getValueBasedOnLang(
            WorkShopSessionItem?.instructorNameAr,
            WorkShopSessionItem?.instructorNameEn
          )}
          sm={6}
        />
        <AppItemView
          label="session.attendanceTime"
          value={WorkShopSessionItem?.attendanceTime}
          sm={6}
          type="time"
        />
        <AppItemView
          label="common.note"
          value={WorkShopSessionItem?.note}
          sm={6}
        />
        {/* <AppItemView
          label="WorkShopParticipants.overAllRating"
          value={WorkShopSessionItem?.rate}
          sm={6}
        /> */}
        <AppItemView
          label="columns.status"
          value={<StatusAttendance status={WorkShopSessionItem?.status} />}
          sm={6}
        />
      </Grid>
    </>
  );
};

interface IAttendanceDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  selectedRow: IWorkShopAttendanceList;
}

const AttendanceDetails = (props: IAttendanceDetailsProps) => {
  const { showDetails, setShowDetails, selectedRow } = props;
  const { locale } = useIntl();

  // const {
  //   data: WorkShopSessionItem,
  //   error,
  //   isLoading,
  // } = useGetByIdService<IWorkshopSessionsAttendeceDetailsDto>(
  //   AppUrls.WorkShopSession,
  //   id
  // );

  return (
    <>
      <BaseDetails
        itemName={""}
        label="session.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={null!}
        moreDetails={selectedRow as IWorkshopSessionsAttendeceDetailsDto}
        maxWidth="sm"
        columns={12}
        tabs={[
          {
            id: "1",
            label: "details",
            component: <ComponentOne WorkShopSessionItem={selectedRow!} />,
          },
        ]}
      />
    </>
  );
};

export default AttendanceDetails;
