import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import {
  IListRatingDetailsDto,
  IListRatingDto,
} from "types/entitiesTypes/workShops";

interface IRatingCardProps {
  handleDetails: (row: IListRatingDto) => void;
  RatingList: Array<IListRatingDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const RatingCard: FC<IRatingCardProps> = (props) => {
  const {
    handleDetails,
    RatingList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
  } = props;
  const { messages, locale } = useIntl();

  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {RatingList?.length != undefined && RatingList?.length >= 1 ? (
          RatingList?.map((item, index) => (
            <Grid key={`RatingList${index}`} item xs={12}>
              <Card>
                <Box sx={{ position: "relative" }}>
                  <Box
                    marginX={4}
                    marginTop={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap={3}
                  >
                    {/* Details */}
                    <AppIconButton
                      label="table.actions.details"
                      handleClick={() => handleDetails(item)}
                      childComp={
                        <RemoveRedEyeIcon
                          fontSize="small"
                          sx={{ color: "#007991" }}
                        />
                      }
                      // For Permissions
                      schemaName={SchemaName.SystemConfigurations}
                      entityName={EntityName.Countries}
                      action={PermissionActionsTypes.Details}
                    />
                  </Box>

                  <CardContent
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 3,
                      paddingTop: "0 !important",
                    }}
                  >
                    <DataItem
                      label={messages["student.name"] as string}
                      value={item?.student.fullName}
                    />
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default RatingCard;
