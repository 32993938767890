import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { ColorLibConnector } from "components/basicComponents/modals/stepper/ColorLibConnector";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IWorkShopsDetailsDto,
  IUpdateWorkShopsDto,
} from "types/entitiesTypes/workShops";
import { ColorLibStepIcon } from "../ColorLibStepIcon";
import { FirstStep, SecondStep } from "../EditSteps";
import useValidationSchema from "../validationSchemas";
import { useNavigate, useParams } from "react-router-dom";
import { generatePresignedUrl } from "helpers";
import moment from "moment";
import AppEditWorkShops from "./AppEditWorkShops";
interface IEditWorkShopsProps {
  onSuccess?: () => void;
  onFailed?: () => void;
  isLoading: boolean;
  WorkShopsItem: IWorkShopsDetailsDto;
}

const EditWorkShops = (props: IEditWorkShopsProps) => {
  const { isLoading, WorkShopsItem } = props;
  const refForm = useRef<FormikProps<IUpdateWorkShopsDto>>(null);
  const { validationSchemas } = useValidationSchema();
  const [activeStep, setActiveStep] = useState(0);
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const steps = ["WorkShops.BasicInformation", "WorkShops.CourseDetails"];
  const navigate = useNavigate();
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const mutation = useUpdateService<IUpdateWorkShopsDto, string>(
    AppUrls.WorkShops,
    () => {
      navigate("/scientificContentManagement/WorkShops");
    }
  );

  useEffect(() => {
    if (WorkShopsItem && WorkShopsItem.imageUrl)
      setUploadedFiles([
        {
          preview: generatePresignedUrl(
            WorkShopsItem.imageUrl,
            process.env.NODE_ENV
          ),
          // BaseFileUrl + WorkShopsItem.coverPhoto,
          originalUrl: WorkShopsItem.imageUrl,
        },
      ]);
  }, [WorkShopsItem]);

  useEffect(() => {
    if (uploadedFiles) refForm.current?.setFieldValue("image", uploadedFiles);
  }, [uploadedFiles]);

  const currentValidationSchema = validationSchemas[activeStep];

  const handleNext = async () => {
    if (refForm.current) {
      await refForm.current.validateForm();
      const errors = refForm.current.errors;

      if (Object.keys(errors).length > 0) {
        return;
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = async (data: IUpdateWorkShopsDto) => {
    if (activeStep === steps.length - 1) {
      if (uploadedFiles) {
        data = {
          ...data,
          image: uploadedFiles[0],
          startDate: moment.utc(data?.startDate).local().format(),
        };
      }
      await mutation.submitData(data);
    } else {
      handleNext();
    }
  };
  return (
    <>
      <AppEditWorkShops
        handleSubmit={submit}
        handleBack={activeStep > 0 ? () => handleBack() : undefined}
        labelForEditBtn={
          activeStep === steps.length - 1 ? "common.save" : "common.next"
        }
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
      >
        <Formik
          validationSchema={currentValidationSchema}
          validateOnChange={true}
          initialValues={{
            id: WorkShopsItem?.id || "",
            nameAr: WorkShopsItem?.nameAr || "",
            nameEn: WorkShopsItem?.nameEn || "",

            isActive: WorkShopsItem?.isActive!,
            descriptionAr: WorkShopsItem?.descriptionAr || "",
            descriptionEn: WorkShopsItem?.descriptionEn || "",
            regionId: WorkShopsItem?.region?.id || "",
            instructorId:
              instructorIdParams || WorkShopsItem?.instructor?.id || "",
            educationalCenterId:
              educationalCenterIdParams ||
              WorkShopsItem?.educationalCenter?.id ||
              "",

            educationalCenter: WorkShopsItem?.educationalCenter,
            region: WorkShopsItem?.region,
            instructor: WorkShopsItem?.instructor,

            durationInHours: WorkShopsItem?.durationInHours || null!,
            isCertified: WorkShopsItem?.isCertified || true,
            outcomesAr: WorkShopsItem?.outcomesAr || "",
            outcomesEn: WorkShopsItem?.outcomesEn || "",
            startDate:
              moment.utc(WorkShopsItem?.startDate).local().format() || "",
            image: WorkShopsItem?.imageUrl || "",
            code: WorkShopsItem.code || "",
          }}
          innerRef={refForm}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={4}
              >
                <Grid item>
                  <Stepper
                    activeStep={activeStep}
                    sx={{ py: 3 }}
                    alternativeLabel
                    connector={<ColorLibConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step key={`stepsEdit-${index}`}>
                        <StepLabel StepIconComponent={ColorLibStepIcon}>
                          <IntlMessages id={label} />
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item>
                  {activeStep === 0 && (
                    <FirstStep
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={values}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                    />
                  )}
                  {activeStep === 1 && (
                    <SecondStep
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditWorkShops>
    </>
  );
};

export default EditWorkShops;
