import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import useGeneralAutoComplete from "./useGeneralAutoComplete";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAutocompleteService, useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { DiplomasType, typeDocument } from "types/entitiesTypes/enums";
import {
  ICreateStudentAttachments,
  ImportStudentDocuments,
  IStudentAttachmentsList,
} from "types/entitiesTypes/studentAttachments";
import * as yup from "yup";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { IStudentListDto } from "types/entitiesTypes/student";
import { ICoursesDiplomaListDto } from "types/entitiesTypes/courses";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";

interface IImportDocumentProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const ImportDocument: React.FC<IImportDocumentProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ImportStudentDocuments>>(null);

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>();
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);

  const [educationalCenterKeyword, setEducationalCenterKeyword] =
    useState<string>();
  const [educationalCenterPageNumber, setEducationalCenterPageNumber] =
    useState(1);
  const [educationalCenterItem, setEducationalCenterItem] =
    useState<IEducationalCenterListDto>();

  const mutation = useCreateService<ImportStudentDocuments, string>(
    AppUrls.ImportStudentDocuments,
    null,
    true,
    () => {},
    false
  );
  const validationSchema = yup.object({
    type: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),

    diplomaId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    educationalCenterId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    documents: yup
      .array()
      .nullable()
      .min(1, String(messages["common.maxFilesExceeded"]))
      .max(50, String(messages["common.maxFilesExceeded"])),
  });
  const {
    data: diplomaList,
    isFetching: isFetchingDiploma,
    refetch,
  } = useAutocompleteService<ICoursesDiplomaListDto>(
    AppUrls.DiplomasCourses,
    {
      isActive: true,
      keyword: diplomaKeyword,
      pageSize: 10,
      pageNumber: diplomaPageNumber,
      educationalCenterId: educationalCenterItem?.id || null,
    },
    Boolean(educationalCenterItem?.id)
  );

  useEffect(() => {
    if (diplomaPageNumber > 1) refetch();
  }, [diplomaPageNumber]);

  const {
    data: educationalCenterList,
    isFetching: isFetchingEducationalCenter,
    refetch: refetchEducationalCenter,
  } = useAutocompleteService<IEducationalCenterListDto>(
    AppUrls.EducationalCenter,
    {
      isActive: true,
      keyword: educationalCenterKeyword,
      pageSize: 10,
      pageNumber: educationalCenterPageNumber,
    }
  );

  useEffect(() => {
    if (educationalCenterPageNumber > 1) refetchEducationalCenter();
  }, [educationalCenterPageNumber]);

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            type: "",
            educationalCenterId: "",
            diploma: null,
            diplomaId: "",
            documents: [],
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            if (uploadedFiles)
              data = {
                ...data,
                documents: uploadedFiles,
              };
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <AppBaseAutoComplete
                      label="diplomas.educationalCenter"
                      options={educationalCenterList?.data}
                      isLoading={isFetchingEducationalCenter}
                      onChangeAutoComplete={(event, value) => {
                        setFieldValue("diploma", null);
                        setFieldValue("diplomaId", null);
                        setEducationalCenterItem(value);
                        setFieldValue(
                          "educationalCenterId",
                          value ? value?.id : ""
                        );
                        !value && setEducationalCenterKeyword("");
                      }}
                      //pagination
                      pageNumber={educationalCenterPageNumber}
                      setPageNumber={setEducationalCenterPageNumber}
                      hasNextPage={educationalCenterList?.hasNextPage}
                      searchText={educationalCenterKeyword}
                      onChangeTextField={debounce((event) => {
                        setEducationalCenterPageNumber(1);
                        setEducationalCenterKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors?.educationalCenterId}
                      touched={touched?.educationalCenterId}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppBaseAutoComplete
                      key={values?.educationalCenterId}
                      label="diplomas.name"
                      disabled={!values?.educationalCenterId}
                      options={diplomaList?.data}
                      isLoading={isFetchingDiploma}
                      defaultValue={values?.diploma}
                      onChangeAutoComplete={(event, value) => {
                        setFieldValue("diploma", value ? value : null);

                        setFieldValue("diplomaId", value ? value?.id : "");

                        !value && setDiplomaKeyword("");
                      }}
                      //pagination
                      pageNumber={diplomaPageNumber}
                      setPageNumber={setDiplomaPageNumber}
                      hasNextPage={diplomaList?.hasNextPage}
                      searchText={diplomaKeyword}
                      onChangeTextField={debounce((event) => {
                        setDiplomaPageNumber(1);
                        setDiplomaKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors?.diplomaId}
                      touched={touched?.diplomaId}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppSelectField
                      label={<IntlMessages id="student.typeDocument" />}
                      name="type"
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      {/* <MenuItem value={typeDocument.VirtualFile}>
                        <IntlMessages id="student.attachment.virtualFile" />
                      </MenuItem> */}
                      <MenuItem value={typeDocument.AcademicRecord}>
                        <IntlMessages id="student.attachment.academicRecord" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Statement}>
                        <IntlMessages id="student.attachment.statement" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Certificate}>
                        <IntlMessages id="student.attachment.certificate" />
                      </MenuItem>
                    </AppSelectField>
                  </Grid>

                  <Grid item xs={12} />
                  <AppUploadDocument
                    formRef={refForm}
                    keyName={"documents"}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    errors={!!errors.documents}
                    helperText={errors.documents as string}
                    uploadText="common.uploadFiles"
                    multiple
                    appGridXs={6}
                    uploadModernXs={3}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default ImportDocument;
