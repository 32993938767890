import { Card, CardContent, Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";

import { IRegionsSearch } from "types/entitiesTypes/regions";

import { InstructorColumns } from "./InstructorColumns";
import {
  IInstructorsListDto,
  IInstructorsSearch,
} from "types/entitiesTypes/instructors";
import AddInstructor from "components/trainStructure/instructor/AddInstructor";
import EditInstructor from "components/trainStructure/instructor/EditInstructor";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InstructorCard from "./InstructorCard";
import ChangeInstructorPassword from "components/trainStructure/instructor/ChangeInstructorPassword";

const InstructorPages = () => {
  const { messages, locale } = useIntl();
  const [search, setSearch] = useState<IInstructorsSearch>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  let objectFilter = {};
  const [selectedRow, setSelectedRow] = useState<IInstructorsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: instructorsList,
    isFetching,
    error,
  } = useGetListService<IInstructorsListDto>(AppUrls.Instructor, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IInstructorsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IInstructorsListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // For ChangePassword
  const handleChangePassword = useCallback(
    (selectedRow: IInstructorsListDto) => {
      setSelectedRow(selectedRow);
      setShowChangePasswordModal(true);
    },
    []
  );

  // for delete
  const handleDelete = useCallback((selectedRow: IInstructorsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  //for Activation
  const handleToggleActive = useCallback((selectedRow: IInstructorsListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);

  const columns = InstructorColumns(
    handleDetails,
    handleToggleActive,
    handleUpdate,
    handleDelete,
    handleChangePassword
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.Instructor,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );
  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.Instructor,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );
  const pdfHeaders: IPdfHeader[] = [
    { key: "firstNameAr", value: String(messages["common.firstNameAr"]) },
    {
      key: "lastNameAr",
      value: String(messages["common.lastNameAr"]),
    },
    { key: "firstNameEn", value: String(messages["common.firstNameEn"]) },
    {
      key: "lastNameEn",
      value: String(messages["common.lastNameEn"]),
    },

    {
      key: "phoneNumber",
      value: String(messages["common.phone"]),
    },

    {
      key: "isActive",
      value: String(messages["common.isActive"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.usersManagement"] }]}
          currentPathName={"sidebar.trainers"}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="instructor.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Instructor}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            withExportExcelButton={true}
            totalCount={instructorsList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.instructors"])}
            pdfHeaders={pdfHeaders}
            customQueries={{
              ...objectFilter,
            }}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterTextField
                fieldName="email"
                label="common.email"
                setAdvancedFilter={setAdvancedFilter}
                advancedFilter={advancedFilter}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={instructorsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={instructorsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <InstructorCard
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              handleChangePassword={handleChangePassword}
              instructorsList={instructorsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((instructorsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              handleToggleActive={handleToggleActive}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddInstructor
          label="instructor.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}
      {showChangePasswordModal && (
        <ChangeInstructorPassword
          label="common.myAccount.changePassword"
          selectedRow={selectedRow!}
          setShowChangePasswordModal={setShowChangePasswordModal}
          showChangePasswordModal={showChangePasswordModal}
        />
      )}
      {showEditModal && (
        <EditInstructor
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="instructor.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr:
              `${selectedRow?.firstNameAr} ${selectedRow?.lastNameAr}` || "",
            nameEn:
              `${selectedRow?.firstNameEn} ${selectedRow?.lastNameEn}` || "",
          }}
        />
      )}

      {/* {showDetailsModal && (
        <RegionsDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )} */}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"instructor.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            `${selectedRow?.firstNameAr} ${selectedRow?.lastNameAr}` || "",
            `${selectedRow?.firstNameEn} ${selectedRow?.lastNameEn}` || ""
          )}
          title={"instructor.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}

      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "instructor.actions.deactivationTitle"
              : "instructor.actions.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={getValueBasedOnLangForConfirmModal(
            `${selectedRow?.firstNameAr} ${selectedRow?.lastNameAr}` || "",
            `${selectedRow?.firstNameEn} ${selectedRow?.lastNameEn}` || ""
          )}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "instructor.actions.deactivationConfirmation"
              : "instructor.actions.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default InstructorPages;
