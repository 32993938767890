import IntlMessages from "@crema/utility/IntlMessages";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Hidden, Zoom } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { dateTimeComparison } from "helpers/string/dateFormatter";
import moment from "moment";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IExamQuestionsListDto,
  IExamQuestionsSearch,
} from "types/entitiesTypes/examQuestions";
import { IExamDetailsDto } from "types/entitiesTypes/exams";
import AddExamQuestion from "./AddExamQuestion";
import EditExamQuestion from "./EditExamQuestion";
import { ExamQuestionColumns } from "./ExamQuestionColumns";
import ExamQuestionDetails from "./ExamQuestionDetails";
import ExamQuestionsCard from "./ExamQuestionsCard";

interface IExamQuestionsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  examItem: IExamDetailsDto;
}

const ExamQuestionsPage = (props: IExamQuestionsProps) => {
  const { messages } = useIntl();
  const { setShowDetails, examItem } = props;
  const [search, setSearch] = useState<IExamQuestionsSearch>({
    pageNumber: 1,
    pageSize: 10,
    examId: examItem?.id!,
  });
  const [showAddQuestionModal, setShowAddQuestionModal] =
    useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IExamQuestionsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const now = moment();
  const {
    resultData: examQuestionsList,
    isFetching,
    error,
  } = useGetListService<IExamQuestionsListDto>(AppUrls.ExamQuestions, {
    ...search,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IExamQuestionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IExamQuestionsListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  const closeExamModalDetails = () => setShowDetails(false);

  // for delete
  const handleDelete = useCallback((selectedRow: IExamQuestionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  const columns = ExamQuestionColumns(
    examItem.date,
    handleDetails,
    handleUpdate,
    handleDelete
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.ExamQuestions,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  return (
    <>
      <Grid container spacing={3}>
        {dateTimeComparison(examItem.date) && (
          <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={4}>
            <Zoom in style={{ transitionDelay: "300ms" }}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 8,
                  "& .MuiSvgIcon-root": {
                    fontSize: 26,
                  },
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowAddQuestionModal(true);
                }}
              >
                <IntlMessages id={"table.actions.addQuestion"} />
              </Button>
            </Zoom>
          </Grid>
        )}
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={examQuestionsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={examQuestionsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <ExamQuestionsCard
              ExamQuestionsList={examQuestionsList?.data!}
              loading={isFetching}
              errorMessage={error!}
              handleDelete={handleDelete}
              examItemDate={examItem.date!}
              handleDetails={handleDetails}
              handleUpdate={handleUpdate}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((examQuestionsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {/* {showAddModal && (
        <AddExam
          label="exams.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )} */}

      {showAddQuestionModal && dateTimeComparison(examItem.date) && (
        <AddExamQuestion
          label="exams.question.add"
          showAddModal={showAddQuestionModal}
          setShowAddModal={setShowAddQuestionModal}
          selectedRow={examItem}
        />
      )}

      {showEditModal && dateTimeComparison(examItem.date) && (
        <EditExamQuestion
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="exams.question.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.textAr || "",
            nameEn: selectedRow?.textEn || "",
          }}
        />
      )}

      {showDetailsModal && (
        <ExamQuestionDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"exams.question.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.textAr || "",
            selectedRow?.textEn || ""
          )}
          title={"exams.question.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default ExamQuestionsPage;
