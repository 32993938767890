import { useIntl } from "react-intl";
import * as yup from "yup";

const useValidationSchema = () => {
  const { messages } = useIntl();

  //   First Step Validation Schema
  const firstStepValidationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    educationalCenterId: yup
      .string()
      .required(String(messages["validation.required"])),
    regionId: yup.string().required(String(messages["validation.required"])),
    image: yup
      .array()
      .min(1, String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
  });

  //   Second Step Validation Schema
  const secondStepValidationSchema = yup.object({
    instructorId: yup
      .string()
      .required(String(messages["validation.required"])),
    durationInHours: yup
      .number()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"])),
    startDate: yup.string().required(String(messages["validation.required"])),
    code: yup.string().required(String(messages["validation.required"])),
  });

  const validationSchemas = [
    firstStepValidationSchema,
    secondStepValidationSchema,
  ];
  return { validationSchemas };
};

export default useValidationSchema;
