import moment from "moment";

export const dateFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment(strDate).local().format("DD/MM/yyyy");
    return date;
  }
  return "-";
};

export const timeFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment(strDate).local().format("HH:mm A");
    return date;
  }
  return "-";
};

export const dateTimeFormatter = (strDate: string) => {
  if (strDate) {
    const date = moment.utc(strDate).local().format("DD/MM/yyyy HH:mm A");
    return date;
  }
  return "-";
};

export const dateTimeComparison = (strDate: string) => {
  if (moment(strDate).isAfter(moment(), "dates")) {
    return true;
  }
  return false;
};
