// Ammar LOCAL real IP

// export const PACKET = "darrebni-test";
//FOR ONLINE
// export const APP_BASIC_URL =
//   "https://staging-darrebni-abha-api.doctor-devops.com";

// export const APP_BASIC_URL = "http://10.207.252.142:7070";
// export const APP_BASIC_FILES_URL = `https://${PACKET}.s3.amazonaws.com/`;

// Ammar for tester
// export const APP_BASIC_URL = "http://10.207.252.142:9046/api";
// export const APP_BASIC_FILES_URL = "http://10.207.252.142:9046";

//Ammar localhost
// export const APP_BASIC_URL = "https://localhost:6001";
// export const APP_BASIC_FILES_URL = "https://localhost:6001";

//online Gaith
// export const APP_BASIC_URL = "http://185.194.124.99:9096/api";
// export const APP_BASIC_FILES_URL = "http://185.194.124.99:9096";

// export const BaseApiUrl =
//   process.env.NODE_ENV === "production"
//     ? // ? process.env.BASE_URL
//       APP_BASIC_URL
//     : APP_BASIC_URL;

// process.env.REACT_APP_BASIC_URL
// "http://10.207.252.142:7070"
export const BaseApiUrl = process.env.REACT_APP_BASIC_URL;

export const BaseFileUrl = process.env.REACT_APP_BASIC_FILES_URL;

// export const BaseFileUrl =
//   process.env.NODE_ENV === "production"
//     ? // ? process.env.BASE_FILE_URL
//       APP_BASIC_FILES_URL
//     : APP_BASIC_FILES_URL;

export const ApiVersion = "v1";

export const AppUrls = {
  WorkShopSession: "WorkshopSessions",
  WorkshopRates: "WorkshopRates",
  WorkshopSessionAttendances: "WorkshopSessionAttendances",
  WorkshopParticipantsAttend: "WorkshopParticipants/Attend",
  WorkshopParticipantsCertificate: "WorkshopParticipants/Certificate",
  WorkshopParticipants: "WorkshopParticipants",
  WorkShops: "WorkShops",
  AuthChangePassword: "Auth/Change-password",
  DiplomasCourses: "Diplomas",
  Regions: "regions",
  Exam: "exam",
  ExamQuestions: "examQuestions",
  ExamResult: "examResult",
  Instructor: "instructor",
  Categories: "categories",
  Courses: "courses",
  Session: "Session",
  SessionUpdate: "Session/Update",
  SessionAttendance: "Session/Attendance",
  CreateWithAttach: "Session/CreateWithAttach",
  SessionAttachment: "Session/Attachment",
  ImportStudentDocuments: "studentDocuments/import",
  studentAcademicNumber: "Student/setAcademicNumber",

  SystemSettings: "SystemSettings",
  Student: "Student",
  Subscription: "Subscription",
  WebsiteNeeds: "WebsiteNeeds",
  WebsiteNeedsRead: "WebsiteNeeds/read",
  WebsiteContactUs: "WebsiteContactUs",
  WebsiteContactUsRead: "WebsiteContactUs/read",
  RefundApprovement: "Refund/refund-approvement",
  StudentDocuments: "StudentDocuments",
  Refund: "Refund",
  Invoice: "Invoice",
  Diplomas: "diplomas",
  DiplomaInstallment: "DiplomaInstallment",
  Payment: "Payment",
  PaymentApprovement: "/Payment/approvement",
  DucationalCenter: "educationalCenter",
  EducationalCenter: "EducationalCenter",
  AdvertisementSlider: "advertisementSlider",
  SupportTickets: "supportTickets",
  Countries: "countries",
  CountriesAutoComplete: "countries/relation-search",
  Shared: "shared",
  States: "states",
  TaskAssignments: "taskAssignments",
  Branches: "branches",
  Departments: "departments",
  InstanceNotifications: "notifications",
  CancelNotification: `/notifications/cancel`,
  ResendNotification: `/notifications/resend`,
  NOTIFICATIONS: `personal/notifications`,
  Tickets: "Tickets",
  TicketsLifeCycle: "TicketsLifeCycle",
  Users: "users",
  Roles: "roles",

  PersonalExportedFile: "exportedfiles",
  AssignUserToRole: "users/assign-user-role",
  AssignUserToMultiRole: "users/assign-user-multiple-roles",

  DeAssignRoleFromUser: "users/deassign-user-role",
  ResetUserPassword: "users/reset-password",
  ResetEmployeePassword: "employees/reset-password",

  Dashboard: "dashboard/statistics",
  ChangeUserPassword: `personal/change-password`,
  PersonalProfile: "personal/profile",
  //Report
  sharedExport: "shared/export",
  PersonalExportedFileDelete: "exportedfiles/delete",
  // Permissions
  AllPermissionsSchema: "permissions/schema-all",
  AllPermissionsSchemaUser: `permissions/user/schema-all`,
  AllPermissionsTableUser: `permissions/user/table-all`,
  AllPermissionsTable: "permissions/table-all",
  RolePermissions: "roles/permissions",
  TablePermissions: `${BaseApiUrl}/personal/permissions-table`,
  Permissions: `${BaseApiUrl}/personal/permissions`,
};
