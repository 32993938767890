import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import { getValueBasedOnLang } from "helpers/index";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import EducationalCenterDetails from "components/trainStructure/educationalCenter/EducationalCenterDetails";
import { IEducationalCenterDetailsDto } from "types/entitiesTypes/educationalCenter";
import DiplomasPages from "pages/scientificContentManagement/diplomas/DiplomasPages";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkShopsPages from "pages/scientificContentManagement/workShops/WorkShopsPages";
import WorkIcon from "@mui/icons-material/Work";
const EducationalCenterProfile: React.FC = () => {
  const { messages } = useIntl();
  const { educationalCenterIdParams } = useParams();
  const {
    data: educationalCenterItem,
    error,
    isLoading,
  } = useGetByIdService<IEducationalCenterDetailsDto>(
    AppUrls.EducationalCenter,
    educationalCenterIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: (
        <EducationalCenterDetails
          educationalCenterItem={educationalCenterItem!}
        />
      ),
    },
    {
      path: "/WorkShops",
      routeComponent: <WorkShopsPages />,
    },
    {
      path: "/diplomas",
      routeComponent: <DiplomasPages />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "WorkShops",
      icon: <WorkIcon fontSize={"small"} />,
      label: "sidebar.WorkShops",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "diplomas",
      icon: <MenuBookIcon fontSize="small" />,
      label: "sidebar.diplomas",
      schemaName: SchemaName.UsersManagement,
      entityName: EntityName.Users,
      action: PermissionActionsTypes.List,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.trainStructure.title"] },
            {
              name: messages["sidebar.educationalCenter"],
              path: "/trainStructure/university",
            },
            {
              name: getValueBasedOnLang(
                educationalCenterItem?.nameAr || "",
                educationalCenterItem?.nameEn || ""
              ),
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default EducationalCenterProfile;
