import { getFormattedDateTime } from "@crema/utility/helper/DateHelper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import defaultImage from "assets/appDefaultImages/user.png";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import moment from "moment";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWorkShopsListDto } from "types/entitiesTypes/workShops";

export const WorkShopsColumns = (
  handleDetails = (row: IWorkShopsListDto) => {},
  handleUpdate = (row: IWorkShopsListDto) => {},
  handleDelete = (row: IWorkShopsListDto) => {},
  handleToggleActive = (row: IWorkShopsListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const WorkShopsColumns: GridColDef[] = [
    {
      field: "image",
      type: "image",
      headerName: messages["WorkShops.image"] as string,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <AppViewImage
            image={params.row?.imageUrl}
            defaultImage={defaultImage}
          />
        );
      },
    },
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameAr} />,
    },
    {
      field: "nameEn",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameEn} />,
    },
    {
      field: "startDate",
      headerName: messages["WorkShops.startDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params?.row?.startDate
              ? dateTimeFormatter(params?.row?.startDate)
              : "-"
          }
        />
      ),
    },
    {
      field:
        locale == "en-US"
          ? "educationalCenter.nameEn"
          : "educationalCenter.nameAr",
      headerName: messages["educationalCenter.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.educationalCenter?.nameAr
              ? params.row?.educationalCenter?.nameAr
              : "-",
            params.row?.educationalCenter?.nameEn
              ? params.row?.educationalCenter?.nameAr
              : "-"
          )}
        />
      ),
    },
    // {
    //   field: locale ? "instructor.firstNameEn" : "instructor.firstNameAr",
    //   headerName: messages["instructor.name"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,

    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell
    //       value={getValueBasedOnLang(
    //         params.row?.instructor?.firstNameAr
    //           ? params.row?.instructor?.firstNameAr
    //           : "-",
    //         params.row?.instructor?.firstNameEn
    //           ? params.row?.instructor?.firstNameEn
    //           : "-"
    //       )}
    //     />
    //   ),
    // },
    {
      field: locale === "ar-SA" ? "region.nameAr" : "region.nameEn",
      headerName: messages["WorkShops.region"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.region?.nameAr ? params.row?.region?.nameAr : "-",
            params.row?.region?.nameEn ? params.row?.region?.nameEn : "-"
          )}
        />
      ),
    },
    {
      field: "code",
      headerName: messages["WorkShops.code"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <AppDataGridCell value={params?.row?.code} />;
      },
    },
    {
      field: "isActive",
      headerName: messages["common.isActive"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      align: "center",
      renderCell: (params) => {
        return <StatusBadge isActive={params?.row?.isActive} />;
      },
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/scientificContentManagement/WorkShops/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit */}
            <AppIconButton
              label="table.actions.edit"
              withLink={true}
              toPathLink={`/scientificContentManagement/WorkShops/edit/${params?.row?.id}`}
              childComp={
                <EditOutlinedIcon fontSize="small" sx={{ color: "#A0CCDA" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            />

            {/* Activation */}
            <AddDataGridActivation
              value={params.row.isActive}
              handleClick={() => {
                handleToggleActive(params.row);
              }}
              // For Permissions
              schemaName={SchemaName.ClientsManagement}
              entityName={EntityName.Departments}
              action={PermissionActionsTypes.Activate}
            />

            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return WorkShopsColumns;
};
