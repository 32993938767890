import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateDiplomasDto } from "types/entitiesTypes/diplomas";
import useValidationSchema from "../validationSchemas";
import AppStepperAddModal from "components/basicComponents/modals/stepper/AppStepperAddModal";
import { ColorLibConnector } from "components/basicComponents/modals/stepper/ColorLibConnector";
import { DiplomasType } from "types/entitiesTypes/enums";
import { FirstStep, SecondStep, ThirdSteps } from "../AddSteps";
import { ColorLibStepIcon } from "../ColorLibStepIcon";
import AppAddDiploma from "./AppAddDiploma";
import { useNavigate, useParams } from "react-router-dom";

interface IAddDiplomasProps {
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddDiplomas: React.FC<IAddDiplomasProps> = (props) => {
  const { instructorIdParams, educationalCenterIdParams } = useParams();
  const refForm = React.useRef<FormikProps<ICreateDiplomasDto>>(null);
  const { validationSchemas } = useValidationSchema();
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const steps = [
    "diplomas.BasicInformation",
    "diplomas.CourseDetails",
    "diplomas.FinancialDetails",
  ];

  const navigate = useNavigate();
  const mutation = useCreateService<ICreateDiplomasDto, string>(
    AppUrls.Diplomas,
    null,
    true,
    () => {
      navigate("/scientificContentManagement/diplomas");
    }
  );

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const currentValidationSchema = validationSchemas[activeStep];

  const handleNext = async () => {
    if (refForm.current) {
      await refForm.current.validateForm();
      const errors = refForm.current.errors;

      if (Object.keys(errors).length > 0) {
        return;
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = async (data: ICreateDiplomasDto) => {
    if (activeStep === steps.length - 1) {
      if (uploadedFiles) {
        data = { ...data, coverPhoto: uploadedFiles[0] };
      }
      await mutation.submitData(data);
    } else {
      handleNext();
    }
  };

  return (
    <>
      <AppAddDiploma
        handleSubmit={submit}
        handleBack={activeStep > 0 ? () => handleBack() : undefined}
        labelForAddBtn={
          activeStep === steps.length - 1 ? "common.add" : "common.next"
        }
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
      >
        <Formik
          validationSchema={currentValidationSchema}
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            isActive: true,
            descriptionAr: "",
            descriptionEn: "",
            categoryId: "",
            educationalCenterId: educationalCenterIdParams
              ? educationalCenterIdParams
              : "",
            regionId: "",
            instructorId: instructorIdParams ? instructorIdParams : "",
            instructorSalary: null,
            durationInHours: null,
            isCertified: true,
            outcomes: "",
            coursesCount: null,
            startDate: "",
            singlePaymentCost: null,
            multiplePaymentsCost: null,
            discountCost: null,
            expenses: null,
            expensesType: DiplomasType.FixedValue,
            whatsappChatLink: "",
            educationalCenterFees: null,
            educationalCenterFeesType: DiplomasType.FixedValue,
            coverPhoto: "",
            tax: null,
            isFree: false,
            // installments: [
            //   {
            //     dueDate: "",
            //     installmentOrder: null,
            //     amount: null,
            //     daysMargin: null,
            //   },
            // ],
          }}
          innerRef={refForm}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => {
            return (
              <Form>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  spacing={4}
                >
                  <Grid item>
                    <Stepper
                      activeStep={activeStep}
                      sx={{ py: 3 }}
                      alternativeLabel
                      connector={<ColorLibConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={index}>
                          <StepLabel StepIconComponent={ColorLibStepIcon}>
                            <IntlMessages id={label} />
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid item>
                    {activeStep === 0 && (
                      <FirstStep
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        refForm={refForm}
                      />
                    )}
                    {activeStep === 1 && (
                      <SecondStep
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                    )}
                    {activeStep === 2 && (
                      <ThirdSteps
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddDiploma>
    </>
  );
};

export default AddDiplomas;
