import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AddWorkShopSessions from "components/scientificContentManagement/workShops/workshopSessions/AddSession";
import EditWorkShopSession from "components/scientificContentManagement/workShops/workshopSessions/EditSession";
import WorkShopSessionsDetails from "components/scientificContentManagement/workShops/workshopSessions/SessionsDetails";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useDeleteService, useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { ICoursesListDto } from "types/entitiesTypes/courses";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import {
  IWorkshopSessionsListDto,
  IWorkshopSessionsSearch,
} from "types/entitiesTypes/workshopSessions";
import WorkShopSessionsCard from "./WorkshopSessionsCard";
import { WorkShopSessionsColumns } from "./WorkshopSessionsColumns";
interface IWorkShopSessionPagesProps {
  item?: ICoursesListDto;
}
const WorkShopSessionsPages: FC<IWorkShopSessionPagesProps> = (props) => {
  const { WorkShopsIdParams } = useParams();
  const { item } = props;
  const { messages } = useIntl();
  const [search, setSearch] = useState<IWorkshopSessionsSearch>({
    pageNumber: 1,
    pageSize: 10,
    workshopId: WorkShopsIdParams!,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showAddAttachmentModal, setShowAddAttachmentModal] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IWorkshopSessionsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: WorkShopSessionList,
    isFetching,
    error,
  } = useGetListService<IWorkshopSessionsListDto>(AppUrls.WorkShopSession, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IWorkshopSessionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IWorkshopSessionsListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IWorkshopSessionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  // const handleAddAttachment = useCallback(
  //   (selectedRow: IWorkshopSessionsListDto) => {
  //     setSelectedRow(selectedRow);
  //     setShowAddAttachmentModal(true);
  //   },
  //   []
  // );

  const columns = WorkShopSessionsColumns(
    handleDetails,
    handleUpdate,
    handleDelete
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.WorkShopSession,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="session.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.WorkShopSession}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={WorkShopSessionList?.totalCount || 0}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterTextField
                fieldName="nameAr"
                label="common.nameAr"
                setAdvancedFilter={setAdvancedFilter}
                advancedFilter={advancedFilter}
              />,
              <AppAdvanceFilterTextField
                fieldName="nameEn"
                label="common.nameEn"
                setAdvancedFilter={setAdvancedFilter}
                advancedFilter={advancedFilter}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={WorkShopSessionList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={WorkShopSessionList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <WorkShopSessionsCard
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              WorkShopSessionsList={WorkShopSessionList?.data!}
              handleDetails={handleDetails}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (WorkShopSessionList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddWorkShopSessions
          label="session.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          item={item}
        />
      )}

      {showEditModal && (
        <EditWorkShopSession
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="session.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.nameEn || "",
          }}
        />
      )}
      {/* {showAddAttachmentModal && (
        <AddAttachments
          label="table.actions.addAttachment"
          showAddModal={showAddAttachmentModal}
          setShowAddModal={setShowAddAttachmentModal}
          WorkShopSessionId={selectedRow?.id!}
        />
      )} */}

      {showDetailsModal && (
        <WorkShopSessionsDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle="WorkShopSession.actions.delete.dialog.title"
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          title="WorkShopSession.actions.delete.title"
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default WorkShopSessionsPages;
