import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppDatePicker from "components/basicComponents/inputs/dateTimePickers/AppDatePicker";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  ICourseDetailsDto,
  IUpdateCourseDto,
} from "types/entitiesTypes/courses";

import * as yup from "yup";

interface IEditCourseProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditCourse: React.FC<IEditCourseProps> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<IUpdateCourseDto>>(null);
  const {
    data: courseItem,
    error,
    isLoading,
  } = useGetByIdService<ICourseDetailsDto>(AppUrls.Courses, selectedRow?.id);

  const { diplomaIdParams } = useParams();

  const mutation = useUpdateService<IUpdateCourseDto, string>(
    AppUrls.Courses,
    () => {},
    false
  );
  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    diplomaId: yup.string().required(String(messages["validation.required"])),
    termId: yup.string().required(String(messages["validation.required"])),
    contentAr: yup.string().required(String(messages["validation.required"])),
    contentEn: yup.string().required(String(messages["validation.required"])),
    startDate: yup
      .date()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.date"])),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            nameAr: courseItem?.nameAr || "",
            nameEn: courseItem?.nameEn || "",
            contentAr: courseItem?.contentAr || "",
            contentEn: courseItem?.contentEn || "",
            startDate: courseItem?.startDate || "",
            endDate: courseItem?.endDate || "",
            termId: courseItem?.termId || 1,
            diplomaId: diplomaIdParams
              ? diplomaIdParams || ""
              : courseItem?.diplomaResult?.id || "",
            isActive: courseItem?.isActive!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppSelectField
                    label={<IntlMessages id="course.semester" />}
                    name="termId"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={values?.termId}
                    defaultValue={1}
                  >
                    <MenuItem value={1}>
                      <IntlMessages id="course.semester.first" />
                    </MenuItem>
                    <MenuItem value={2}>
                      <IntlMessages id="course.semester.second" />
                    </MenuItem>
                  </AppSelectField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <AppDatePicker
                    label="common.startDate"
                    name="startDate"
                    value={values?.startDate!}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AppDatePicker
                    label="common.endDate"
                    name="endDate"
                    value={values?.endDate!}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <AppCustomizedSwitch
                    keyName="isActive"
                    onChange={setFieldValue}
                    defaultChecked={values?.isActive}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="common.contentAr"
                    name="contentAr"
                    setFieldValue={setFieldValue}
                    value={values?.contentAr || ""}
                    error={!!(errors?.contentAr && touched?.contentAr)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppEditor
                    label="common.contentEn"
                    name="contentEn"
                    setFieldValue={setFieldValue}
                    value={values?.contentEn || ""}
                    error={!!(errors?.contentEn && touched?.contentEn)}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditCourse;
