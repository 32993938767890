import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  useAdvanceFilterAutocompleteService,
  useGetListService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

import RefundablePaymentsDetails from "components/managePayments/refundablePayments/RefundablePaymentsDetails";
import {
  ICreateRefundablePaymentsDto,
  IRefundablePaymentsListDto,
  IRefundablePaymentsSearch,
} from "types/entitiesTypes/refundablePayments";
import { RefundablePaymentsColumns } from "./RefundablePaymentsColumns";
import { IStudentListDto } from "types/entitiesTypes/student";
import { useParams } from "react-router-dom";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import { debounce } from "lodash";
import { IInvoicesListDto } from "types/entitiesTypes/invoices";
import RefundablePaymentsCard from "./RefundablePaymentsCard";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import {
  useCustomToggleActivationService,
  useCustomToggleService,
} from "services/additionalServices";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";

const RefundablePaymentsPages = () => {
  const { messages } = useIntl();
  const [search, setSearch] = useState<IRefundablePaymentsSearch>({
    pageNumber: 1,
    pageSize: 10,
    withSubscription: true,
  });
  const { studentIdParams, invoiceIdParams } = useParams();
  let objectFilter = {
    studentId: search.studentId ? search.studentId : null,
    invoiceId: search.invoiceId ? search.invoiceId : null,
    withSubscription: search.withSubscription ? search.withSubscription : true,
  };
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IRefundablePaymentsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showApprovedModal, setShowApprovedModal] = useState<boolean>(false);

  const [showRejectedModal, setShowRejectedModal] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [studentKeyword, setStudentKeyword] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [invoiceKeyword, setInvoiceKeyword] = useState<string>("");
  const [invoicePageNumber, setInvoicePageNumber] = useState(1);

  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: RefundablePaymentsList,
    isFetching,
    error,
  } = useGetListService<IRefundablePaymentsListDto>(AppUrls.Refund, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback(
    (selectedRow: IRefundablePaymentsListDto) => {
      setSelectedRow(selectedRow);
      setShowDetailsModal(true);
    },
    []
  );

  const mutationForToggleApproved = useCustomToggleService<
    ICreateRefundablePaymentsDto,
    boolean
  >(
    AppUrls.RefundApprovement,
    AppUrls.Refund,
    "",
    {
      refundId: selectedRow?.id!,
      refundStatus: "Approved",
    },
    () => setShowApprovedModal(false),
    "setAllSuccessfully",
    "desAllSuccessfully"
  );

  const mutationForToggleRejected = useCustomToggleService<
    ICreateRefundablePaymentsDto,
    boolean
  >(
    AppUrls.RefundApprovement,
    AppUrls.Refund,
    "",
    {
      refundId: selectedRow?.id!,
      refundStatus: "Rejected",
    },
    () => setShowRejectedModal(false),
    "setAllSuccessfully",
    "desAllSuccessfully"
  );

  const handleToggleApproved = useCallback(
    (selectedRow: IRefundablePaymentsListDto) => {
      setSelectedRow(selectedRow);
      setShowApprovedModal(true);
    },
    []
  );

  const handleToggleRejected = useCallback(
    (selectedRow: IRefundablePaymentsListDto) => {
      setSelectedRow(selectedRow);
      setShowRejectedModal(true);
    },
    []
  );

  //for Activation

  const columns = RefundablePaymentsColumns(
    handleToggleApproved,
    handleToggleRejected,
    handleDetails
  );

  const {
    data: studentsList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(
    AppUrls.Student,
    {
      keyword: studentKeyword,
      pageSize: 10,
      pageNumber: studentPageNumber,
    },
    Boolean(!studentIdParams)
  );

  useEffect(() => {
    if (Boolean(!studentIdParams)) refetchStudents();
  }, [studentPageNumber, studentIdParams]);

  const {
    data: invoicesList,
    isFetching: isFetchingInvoices,
    refetch: refetchInvoice,
  } = useAdvanceFilterAutocompleteService<IInvoicesListDto>(
    AppUrls.Invoice,
    {
      keyword: invoiceKeyword,
      pageSize: 10,
      pageNumber: invoicePageNumber,
    },
    Boolean(!invoiceIdParams)
  );

  useEffect(() => {
    if (Boolean(!invoiceIdParams)) refetchInvoice();
  }, [invoicePageNumber, invoiceIdParams]);

  const pdfHeaders: IPdfHeader[] = [
    { key: "supscription.student.id", value: String(messages["student.id"]) },
    {
      key: "supscription.student.fullName",
      value: String(messages["student.name"]),
    },

    {
      key: "supscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "supscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
    {
      key: "supscription.diploma.educationalCenter.nameAr",
      value: String(messages["educationalCenter.nameAr"]),
    },
    {
      key: "supscription.diploma.educationalCenter.nameEn",
      value: String(messages["educationalCenter.nameEn"]),
    },

    {
      key: "supscription.diploma.instructor.firstNameAr",
      value: String(messages["instructor.firstNameAr"]),
    },
    {
      key: "supscription.diploma.instructor.lastNameAr",
      value: String(messages["instructor.lastNameAr"]),
    },
    {
      key: "supscription.diploma.instructor.firstNameEn",
      value: String(messages["instructor.firstNameEn"]),
    },
    {
      key: "supscription.diploma.instructor.lastNameEn",
      value: String(messages["instructor.lastNameEn"]),
    },
    {
      key: "amount",
      value: String(messages["refundablePayments.amount"]),
    },
    {
      key: "payment.amount",
      value: String(messages["refundablePayments.payment.amount"]),
    },
    {
      key: "date",
      value: String(messages["refundablePayments.date"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.managePayments.title"] }]}
          currentPathName={"sidebar.refundablePayments"}
        />
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            showSearchInput={false}
            showAddButton={false}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Refund}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={RefundablePaymentsList?.totalCount || 0}
            pdfHeaders={pdfHeaders}
            customQueries={{
              ...objectFilter,
            }}
            withExportExcelButton
            excelFileTitle={String(messages["sidebar.refundablePayments"])}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              Boolean(!invoiceIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="invoices.invoiceNumber"
                  fieldName="invoiceId"
                  filterKey="invoiceNumber"
                  getOptionLabel={(option) => option?.invoiceNumber}
                  options={invoicesList?.data}
                  isLoading={isFetchingInvoices}
                  searchText={invoiceKeyword}
                  setSearchText={setInvoiceKeyword}
                  onChangeTextField={debounce((event) => {
                    setInvoiceKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={invoicePageNumber}
                  setPageNumber={setInvoicePageNumber}
                  hasNextPage={invoicesList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              Boolean(!studentIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="session.studentName"
                  fieldName="studentId"
                  options={studentsList?.data}
                  isLoading={isFetchingStudents}
                  searchText={studentKeyword}
                  filterKey="fullName"
                  getOptionLabel={(option) => option.fullName}
                  setSearchText={setStudentKeyword}
                  onChangeTextField={debounce((event) => {
                    setStudentKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={studentPageNumber}
                  setPageNumber={setStudentPageNumber}
                  hasNextPage={studentsList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={RefundablePaymentsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={RefundablePaymentsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <RefundablePaymentsCard
              RefundablePayments={RefundablePaymentsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (RefundablePaymentsList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              handleDetails={handleDetails}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showDetailsModal && (
        <RefundablePaymentsDetails
          RefundablePaymentsItem={selectedRow!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}

      {showApprovedModal && (
        <AppConfirmModal
          dialogTitle={"refundablePayments.approved"}
          open={showApprovedModal}
          setOpen={setShowApprovedModal}
          loading={mutationForToggleApproved.isLoading}
          itemName={""}
          maxWidth="xs"
          title={"refundablePayments.approvedConfirmation"}
          onConfirm={() => {
            mutationForToggleApproved.mutate();
          }}
        />
      )}

      {showRejectedModal && (
        <AppConfirmModal
          dialogTitle={"refundablePayments.rejectedTitle"}
          open={showRejectedModal}
          setOpen={setShowRejectedModal}
          loading={mutationForToggleRejected.isLoading}
          itemName={""}
          maxWidth="xs"
          title={"refundablePayments.rejectedConfirmation"}
          onConfirm={() => {
            mutationForToggleRejected.mutate();
          }}
        />
      )}
    </>
  );
};

export default RefundablePaymentsPages;
