import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppDatePicker from "components/basicComponents/inputs/dateTimePickers/AppDatePicker";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IUpdateWorkshopParticipantsDto,
  IWorkshopParticipantsDetailsDto,
  IWorkshopParticipantsListDto,
} from "types/entitiesTypes/workshopParticipants";

import * as yup from "yup";

interface IEditWorkshopParticipantsProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IWorkshopParticipantsListDto;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditWorkshopParticipants: React.FC<IEditWorkshopParticipantsProps> = (
  props
) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const refForm =
    React.useRef<FormikProps<IUpdateWorkshopParticipantsDto>>(null);

  const { diplomaIdParams } = useParams();
  const mutation = useUpdateService<IUpdateWorkshopParticipantsDto, string>(
    AppUrls.WorkshopParticipants,
    () => {},
    false
  );
  const validationSchema = yup.object({
    overAllRating: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
  });
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={` - ${selectedRow?.student?.fullName} `}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={false}
        errorMessage={null!}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            overAllRating: selectedRow.overAllRating || null!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={
                      <IntlMessages id="WorkShopParticipants.overAllRating" />
                    }
                    name="overAllRating"
                    variant="outlined"
                    size="small"
                    type={"number"}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditWorkshopParticipants;
