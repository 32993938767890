import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import useGeneralAutoComplete from "components/scientificContentManagement/useGeneralAutoComplete";
import {
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from "formik";
import { debounce } from "lodash";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { ICreateWorkShopsDto } from "types/entitiesTypes/workShops";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";
import { IRegionsListDto } from "types/entitiesTypes/regions";

interface IFirstStepProps {
  setFieldValue: FormikHelpers<ICreateWorkShopsDto>["setFieldValue"];
  errors: FormikErrors<ICreateWorkShopsDto>;
  touched: FormikTouched<ICreateWorkShopsDto>;
  values: ICreateWorkShopsDto;
  uploadedFiles: any[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<any[]>>;
}

const FirstStep = (props: IFirstStepProps) => {
  const refForm = useRef<FormikProps<ICreateWorkShopsDto>>(null);
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const {
    errors,
    setFieldValue,
    touched,
    values,
    setUploadedFiles,
    uploadedFiles,
  } = props;

  const {
    keyword: educationalCenterKeyword,
    setKeyword: setEducationalCenterKeyword,
    dataList: educationalCentersList,
    isFetching: isFetchingeducationalCenter,
    pageNumber: educationalCenterPageNumber,
    setPageNumber: setEducationalCenterPageNumber,
  } = useGeneralAutoComplete<IEducationalCenterListDto>({
    url: AppUrls.EducationalCenter,
  });

  const {
    keyword: regionKeyword,
    setKeyword: setRegionKeyword,
    dataList: regionsList,
    isFetching: isFetchingregion,
    pageNumber: regionPageNumber,
    setPageNumber: setRegionPageNumber,
  } = useGeneralAutoComplete<IRegionsListDto>({
    url: AppUrls.Regions,
  });
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={3}>
        <AppTextField
          label={<IntlMessages id="common.nameAr" />}
          name="nameAr"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AppTextField
          label={<IntlMessages id="common.nameEn" />}
          name="nameEn"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      {!educationalCenterIdParams && (
        <Grid item xs={12} sm={3}>
          <AppBaseAutoComplete
            key={values?.educationalCenterId}
            label="WorkShops.educationalCenter"
            options={educationalCentersList?.data}
            isLoading={isFetchingeducationalCenter}
            defaultValue={values?.educationalCenter}
            value={values?.educationalCenter}
            onChangeAutoComplete={(event, value) => {
              setFieldValue("educationalCenter", value ? value : null);
              setFieldValue("educationalCenterId", value ? value?.id : "");

              !value && setEducationalCenterKeyword("");
            }}
            //pagination
            pageNumber={educationalCenterPageNumber}
            setPageNumber={setEducationalCenterPageNumber}
            hasNextPage={educationalCentersList?.hasNextPage}
            searchText={educationalCenterKeyword}
            onChangeTextField={debounce((event) => {
              setEducationalCenterPageNumber(1);
              setEducationalCenterKeyword(event.target.value);
            }, DEBOUNCE_TIME)}
            error={errors?.educationalCenterId}
            touched={touched?.educationalCenterId}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={3}>
        <AppBaseAutoComplete
          key={values?.regionId}
          label="WorkShops.region"
          options={regionsList?.data}
          isLoading={isFetchingregion}
          defaultValue={values?.region}
          value={values?.region}
          onChangeAutoComplete={(event, value) => {
            setFieldValue("region", value ? value : null);
            setFieldValue("regionId", value ? value?.id : "");

            !value && setRegionKeyword("");
          }}
          //pagination
          pageNumber={regionPageNumber}
          setPageNumber={setRegionPageNumber}
          hasNextPage={regionsList?.hasNextPage}
          searchText={regionKeyword}
          onChangeTextField={debounce((event) => {
            setRegionPageNumber(1);
            setRegionKeyword(event.target.value);
          }, DEBOUNCE_TIME)}
          error={errors?.regionId}
          touched={touched?.regionId}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <AppEditor
          label="common.descriptionAr"
          name="descriptionAr"
          setFieldValue={setFieldValue}
          value={values?.descriptionAr}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <AppEditor
          label="common.descriptionEn"
          name="descriptionEn"
          setFieldValue={setFieldValue}
          value={values?.descriptionEn}
        />
      </Grid>

      <AppUploadPhoto
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        keyName="image"
        formRef={refForm}
        errors={!!errors.image}
        uploadText="common.uploadPhoto"
        helperText="validation.image"
        appGridXs={12}
        uploadModernXs={12}
        smUpload={4}
        smShow={6}
      />
      <Grid item sm={6} xs={12} display={"flex"} justifyContent={"flex-start"}>
        <AppCustomizedSwitch
          keyName="isActive"
          onChange={setFieldValue}
          defaultChecked={values?.isActive}
        />
      </Grid>
    </Grid>
  );
};

export default FirstStep;
