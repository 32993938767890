import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLang } from "helpers";
import { FC, useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useGetListService, useUpdateService } from "services";
import { useCustomToggleActivationService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import {
  IUpdateWorkshopParticipantsDto,
  IWorkshopParticipantsListDto,
  IWorkshopParticipantsSearch,
} from "types/entitiesTypes/workshopParticipants";
import { IWorkShopsDetailsDto } from "types/entitiesTypes/workShops";
import WorkshopParticipantsCard from "./WorkshopParticipantsCard";
import { WorkshopParticipantsColumns } from "./WorkshopParticipantsColumns";
import CertificateTemplate from "components/scientificContentManagement/workShops/workshopParticipants/CertificateTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppCertificateModal from "components/basicComponents/modals/certificate/AppCertificateModal";
import EditWorkshopParticipants from "components/scientificContentManagement/workShops/workshopParticipants/EditWorkshopParticipants";
interface IWorkShopsParticipantsPageProps {
  workShopItem: IWorkShopsDetailsDto;
}
const WorkshopParticipantsPages: FC<IWorkShopsParticipantsPageProps> = (
  props
) => {
  const printRef = useRef<HTMLDivElement>(null);
  const Uploadpdf = useUpdateService<IUpdateWorkshopParticipantsDto, string>(
    AppUrls.WorkshopParticipants,
    () => {},
    true
  );
  const { workShopItem } = props;
  const { WorkShopsIdParams } = useParams();
  const [search, setSearch] = useState<IWorkshopParticipantsSearch>({
    pageNumber: 1,
    pageSize: 10,
    workshopId: WorkShopsIdParams!,
  });
  const { messages } = useIntl();
  let objectFilter = {
    workshopId: WorkShopsIdParams!,
  };
  const [showCertificateModal, setShowCertificateModal] =
    useState<boolean>(false);

  const [selectedRow, setSelectedRow] =
    useState<IWorkshopParticipantsListDto>();
  const [showhandleUpdateModal, setShowhandleUpdateModal] =
    useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: WorkshopParticipantsList,
    isFetching,
    error,
  } = useGetListService<IWorkshopParticipantsListDto>(
    AppUrls.WorkshopParticipants,
    {
      ...search,
      advancedFilter,
    }
  );

  // // get Certificate
  const handleCertificate = useCallback(
    (selectedRow: IWorkshopParticipantsListDto) => {
      setSelectedRow(selectedRow);
      setShowCertificateModal(true);
      // handleDownloadPdf(selectedRow);
      // handleDownloadAllAttachments();
    },
    []
  );

  //  For Update
  const handleUpdate = useCallback(
    (selectedRow: IWorkshopParticipantsListDto) => {
      setSelectedRow(selectedRow);
      setShowhandleUpdateModal(true);
    },
    []
  );

  const handleDownloadPdf = async () => {
    if (printRef.current) {
      const pdf = new jsPDF({
        orientation: "landscape", // or 'portrait'
        unit: "mm",
        format: [297, 210], // A4 size in landscape (width x height)
      });
      const content = printRef.current;
      const children = Array.from(content.children);
      const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
      const imgWidth = 280; // Adjusted width for better fit

      const headerMargin = 10; // Set header margin
      let position = headerMargin; // Start position for content

      for (const child of children) {
        const canvas = await html2canvas(child as HTMLElement, {
          scale: 2,
          useCORS: true,
        });
        const imgData = canvas.toDataURL("image/jpeg", 0.5);
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate the height of the image

        // Check if the current image fits on the page
        if (position + imgHeight > pageHeight) {
          pdf.addPage(); // Add a new page
          position = headerMargin; // Reset position for new page with header margin
        }

        pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
        position += imgHeight; // Move position down by the height of the image
      }
      console.log("pdf", pdf);
      const pdfBlob = pdf.output("blob"); // Save the PDF
      console.log("pdfBlob", pdfBlob);
      Uploadpdf.submitData({
        Certificate: pdfBlob,
        id: selectedRow?.id!,
      });
    }
  };

  const isCertified = workShopItem.isCertified;

  const columns = WorkshopParticipantsColumns(
    isCertified,
    handleCertificate,
    handleUpdate
  );

  const mutationForUhandleUpdate = useCustomToggleActivationService<
    string,
    string
  >(
    AppUrls.WorkshopParticipantsAttend,
    AppUrls.WorkshopParticipants,
    selectedRow?.id || "",
    () => setShowhandleUpdateModal(false),
    "Attend.message",
    "NotAttend.message"
  );

  const pdfHeaders: IPdfHeader[] = [];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            showSearchInput={false}
            showAddButton={false}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.WorkshopParticipants}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={WorkshopParticipantsList?.totalCount || 0}
            customQueries={{
              ...objectFilter,
            }}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={WorkshopParticipantsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={WorkshopParticipantsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <WorkshopParticipantsCard
              WorkshopParticipantsList={WorkshopParticipantsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (WorkshopParticipantsList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              // handleCertificate={handleCertificate}
              isCertified={workShopItem.isCertified!}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* {showCertificateModal && (
        <WorkshopParticipantsCertificate
          selectedRow={selectedRow!}
          setShowCertificate={setShowCertificateModal}
          showCertificate={showCertificateModal}
        />
      )} */}

      {showhandleUpdateModal && (
        <EditWorkshopParticipants
          label="rating.edit"
          selectedRow={selectedRow!}
          setShowEditModal={setShowhandleUpdateModal}
          showEditModal={showhandleUpdateModal}
        />
      )}
      {showCertificateModal && (
        <AppCertificateModal
          open={showCertificateModal}
          onClose={() => setShowCertificateModal(false)}
          setShowCertificateModal={setShowCertificateModal}
          withoutSecoundTab={false}
          Uploadpdf={Uploadpdf}
          // maxWidthAsPixel={"1200"}
          maxWidth="xl"
          loading={Uploadpdf.isLoading}
          errorMessage={error}
          itemName={selectedRow?.student?.fullName!}
          successClose={Uploadpdf.isSuccess}
          label="showCertificate"
        >
          <CertificateTemplate
            Uploadpdf={Uploadpdf}
            workShop={workShopItem!}
            item={selectedRow!}
          />
        </AppCertificateModal>
      )}
    </>
  );
};

export default WorkshopParticipantsPages;
