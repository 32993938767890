import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { dateTimeComparison } from "helpers/string/dateFormatter";
import WorkShopAttendancesPage from "pages/scientificContentManagement/workShops/workshopSessions/attendance/AttendancesPage";

import { FC } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IWorkshopSessionsDetailsDto } from "types/entitiesTypes/workshopSessions";

interface IComponentOneProps {
  WorkShopSessionItem: IWorkshopSessionsDetailsDto;
}

const ComponentOne: FC<IComponentOneProps> = (props) => {
  const { WorkShopSessionItem } = props;
  const { locale, messages } = useIntl();
  return (
    <>
      <Grid container spacing={5} item xs={12}>
        <AppItemView
          label="common.nameAr"
          value={WorkShopSessionItem?.nameAr}
          sm={4}
        />
        <AppItemView
          label="common.nameEn"
          value={WorkShopSessionItem?.nameEn}
          sm={4}
        />
        <AppItemView
          label="session.date"
          value={WorkShopSessionItem?.date}
          sm={4}
          type="dateTime"
        />
        <AppItemView
          label="session.duration"
          value={WorkShopSessionItem?.duration}
          sm={4}
        />

        <AppItemView
          label="session.sessionLink"
          value={
            WorkShopSessionItem?.link ? (
              <a href={WorkShopSessionItem?.link} target="_blank">
                {messages["session.sessionLink.href"]}
              </a>
            ) : (
              "-"
            )
          }
          type="text"
          sm={4}
        />

        {/* <AppItemView
          label="WorkShopSession.fileUrl"
          value={
            WorkShopSessionItem?.files ? (
              <a
                href={`${APP_BASIC_FILES_URL} ${WorkShopSessionItem?.files}`}
                target="_blank"
              >
                {messages["WorkShopSession.fileUrl.href"]}
              </a>
            ) : (
              "-"
            )
          }
          type="text"
          column={4}
        /> */}
      </Grid>
    </>
  );
};

interface IWorkShopSessionsDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const WorkShopSessionsDetails = (props: IWorkShopSessionsDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale } = useIntl();

  const {
    data: WorkShopSessionItem,
    error,
    isLoading,
  } = useGetByIdService<IWorkshopSessionsDetailsDto>(
    AppUrls.WorkShopSession,
    id
  );

  return (
    <>
      <BaseDetails
        itemName={
          locale == "en-US" && WorkShopSessionItem?.nameEn
            ? `- ${WorkShopSessionItem?.nameEn!}`
            : `- ${WorkShopSessionItem?.nameAr!}`
        }
        label="session.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={WorkShopSessionItem as IWorkshopSessionsDetailsDto}
        maxWidth="md"
        columns={12}
        tabs={[
          {
            id: "1",
            label: "details",
            component: (
              <ComponentOne WorkShopSessionItem={WorkShopSessionItem!} />
            ),
          },
          // {
          //   id: "2",
          //   label: "WorkShopSession.attchments",
          //   component: (
          //     <AttachmentsPage
          //       setShowDetailsWorkShopSession={setShowDetails}
          //       WorkShopSessionId={WorkShopSessionItem?.id!}
          //     />
          //   ),
          // },
          {
            id: "2",
            label: "session.attendance",
            component: (
              <WorkShopAttendancesPage sessionId={WorkShopSessionItem?.id!} />
            ),
            disabled: dateTimeComparison(WorkShopSessionItem?.date!),
          },
        ]}
      />
    </>
  );
};

export default WorkShopSessionsDetails;
