import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import { getValueBasedOnLang } from "helpers/index";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "../../../shared/constants/AppConst";
import { PermissionActionsTypes } from "../../../types/entitiesTypes/enums";
import InstructorDetails from "components/trainStructure/instructor/InstructorDetails";
import { IInstructorsDetailsDto } from "types/entitiesTypes/instructors";
import DiplomasPages from "pages/scientificContentManagement/diplomas/DiplomasPages";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkShopsPages from "pages/scientificContentManagement/workShops/WorkShopsPages";
import WorkIcon from "@mui/icons-material/Work";
const InstructorProfile: React.FC = () => {
  const { messages } = useIntl();
  const { instructorIdParams } = useParams();
  const {
    data: instructorsItem,
    error,
    isLoading,
  } = useGetByIdService<IInstructorsDetailsDto>(
    AppUrls.Instructor,
    instructorIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <InstructorDetails instructorsItem={instructorsItem!} />,
    },
    {
      path: "/WorkShops",
      routeComponent: <WorkShopsPages />,
    },
    {
      path: "/diplomas",
      routeComponent: <DiplomasPages />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "WorkShops",
      icon: <WorkIcon fontSize={"small"} />,
      label: "sidebar.WorkShops",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "diplomas",
      icon: <MenuBookIcon fontSize="small" />,
      label: "sidebar.diplomas",
      schemaName: SchemaName.UsersManagement,
      entityName: EntityName.Users,
      action: PermissionActionsTypes.List,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.usersManagement"] },
            {
              name: messages["sidebar.trainers"],
              path: "/usersManagement/trainers",
            },
            {
              name: getValueBasedOnLang(
                `${instructorsItem?.firstNameAr} ${instructorsItem?.lastNameAr}` ||
                  "",
                `${instructorsItem?.firstNameEn} ${instructorsItem?.lastNameEn}` ||
                  ""
              ),
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default InstructorProfile;
