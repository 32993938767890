import DiplomaAddPage from "./diplomas/DiplomaAddPage";
import DiplomaEditPage from "./diplomas/DiplomaEditPage";
import DiplomaProfile from "./diplomas/DiplomaProfile";
import DiplomasPages from "./diplomas/DiplomasPages";
import WorkShopsPages from "./workShops/WorkShopsPages";
import WorkShopsAddPage from "./workShops/WorkShopsAddPage";
import WorkShopsEditPage from "./workShops/WorkShopsEditPage";
import WorkShopsProfile from "./workShops/WorkShopsProfile";

export const scientificContentManagement = [
  {
    path: "/scientificContentManagement/diplomas",
    element: <DiplomasPages />,
  },  
  {
    path: "/scientificContentManagement/diplomas/profile/:diplomaIdParams/*",
    element: <DiplomaProfile />,
  },
  {
    path: "/scientificContentManagement/diplomas/edit/:diplomaIdParams",
    element: <DiplomaEditPage />,
  },
  {
    path: "/scientificContentManagement/diplomas/add/",
    element: <DiplomaAddPage />,
  },

  {
    path: "/scientificContentManagement/diplomas/add/educationalCenter/:educationalCenterIdParams",
    element: <DiplomaAddPage />,
  },
  {
    path: "/scientificContentManagement/diplomas/add/instructor/:instructorIdParams",
    element: <DiplomaAddPage />,
  },
  // WorkShops Page
  {
    path: "/scientificContentManagement/WorkShops",
    element: <WorkShopsPages />,
  },
  {
    path: "/scientificContentManagement/WorkShops/add/instructor/:instructorIdParams",
    element: <WorkShopsAddPage />,
  },
  {
    path: "/scientificContentManagement/WorkShops/add/educationalCenter/:educationalCenterIdParams",
    element: <WorkShopsAddPage />,
  },
  {
    path: "/scientificContentManagement/WorkShops/add/",
    element: <WorkShopsAddPage />,
  },
  {
    path: "/scientificContentManagement/WorkShops/edit/:WorkShopsIdParams",
    element: <WorkShopsEditPage />,
  },
  {
    path: "/scientificContentManagement/WorkShops/profile/:WorkShopsIdParams/*",
    element: <WorkShopsProfile />,
  },
];
