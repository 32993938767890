import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import { Form, Formik, FormikProps } from "formik";
import { getValueBasedOnLang } from "helpers";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { QueryObserverResult } from "react-query";
import { useParams } from "react-router-dom";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";

import {
  IWorkShopAttendanceList,
  IWorkShopUpdateDto,
} from "types/entitiesTypes/workshopAttendance";
import * as yup from "yup";

interface IEditAttendancerops {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: IWorkShopAttendanceList;
  onSuccess?: () => void;
  onFailed?: () => void;
  refetch: () => {};
}
const EditAttendance: React.FC<IEditAttendancerops> = (props) => {
  const { showEditModal, setShowEditModal, label, selectedRow, refetch } =
    props;

  const { messages } = useIntl();
  const { WorkShopsIdParams } = useParams();

  const refForm = React.useRef<FormikProps<IWorkShopUpdateDto>>(null);

  const mutation = useUpdateService<IWorkShopUpdateDto, string>(
    AppUrls.WorkshopSessionAttendances,
    () => {
      refetch();
    },
    false
  );
  const validationSchema = yup.object({
    note: yup.string().required(String(messages["validation.required"])),
    rate: yup.string().required(String(messages["validation.required"])),
  });

  useEffect(() => {}, []);
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={` - ${selectedRow?.studentName!} -`}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={false}
        errorMessage={null!}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow.id,
            note: selectedRow?.note || "",
            rate: selectedRow?.rate || null!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            // let dataToSend = {
            //   ...data,
            //   date: moment
            //     .utc(data?.date)
            //     .subtract(3, "hours")
            //     .local()
            //     .format(),
            // };
            mutation.submitData(data);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <AppTextField
                    label={<IntlMessages id="common.note" />}
                    name="note"
                    value={values?.note}
                    variant="outlined"
                    multiline
                    minRows={3}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <AppTextField
                    label={
                      <IntlMessages id="WorkShopParticipants.overAllRating" />
                    }
                    name="rate"
                    value={values?.rate}
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditAttendance;
