import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetListService } from "services";
import { AppUrls } from "services/appUrls";
import {
  IExamResultsListDto,
  IExamResultsSearch,
} from "types/entitiesTypes/examResult";
import { IExamDetailsDto } from "types/entitiesTypes/exams";
import ExamResultCard from "./ExamResultCard";
import ExamResultDetails from "./ExamResultDetails";
import { ExamResultsColumns } from "./ExamResultsColumns";
interface IExamResultsProps {
  examItem: IExamDetailsDto;
}
const ExamResultsPage = (props: IExamResultsProps) => {
  const { examItem } = props;
  const { studentIdParams } = useParams();
  const [search, setSearch] = useState<IExamResultsSearch>({
    pageNumber: 1,
    pageSize: 10,
    examId: examItem?.id,
    studentId: studentIdParams,
  });
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IExamResultsListDto>();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: examResultsList,
    isFetching,
    error,
  } = useGetListService<IExamResultsListDto>(AppUrls.ExamResult, {
    ...search,
    advancedFilter,
  });

  // for delete
  const handleDetails = useCallback((selectedRow: IExamResultsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  const columns = ExamResultsColumns(handleDetails);

  return (
    <>
      <Grid container spacing={3}>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={examResultsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={examResultsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <ExamResultCard
              ExamResultList={examResultsList?.data!}
              loading={isFetching}
              errorMessage={error!}
              handleDetails={handleDetails}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((examResultsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
            />
          </Grid>
        </Hidden>
      </Grid>
      {showDetailsModal && (
        <ExamResultDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}
    </>
  );
};
//ExamResultDetails

export default ExamResultsPage;
