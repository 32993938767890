import { Card, CardContent, Grid } from "@mui/material";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AddWorkShops from "components/scientificContentManagement/workShops/addWorkShops/AddWorkShops";

import { useIntl } from "react-intl";

const WorkShopsAddPage: React.FC = () => {
  const { messages } = useIntl();
  return (
    <Grid container spacing={3}>
      <AppBreadcrumb
        paths={[
          { name: messages["sidebar.scientificContentManagement.title"] },
          {
            name: messages["sidebar.WorkShops"],
            path: "/scientificContentManagement/WorkShops",
          },
          {
            name: messages["WorkShops.add"],
          },
        ]}
        currentPathName=""
      />

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <AddWorkShops />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default WorkShopsAddPage;
