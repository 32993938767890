import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { AppUrls } from "services/appUrls";

import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import { useParams } from "react-router-dom";
import { useGetListWithEnable } from "services/additionalServices";
import {
  IAttendanceList,
  IAttendanceSearch,
} from "types/entitiesTypes/attendance";
import { AttendanceColumns } from "./AttendanceColumns";
import AttendanceFilter from "./AttendanceFilter";
import AttendancesCard from "./AttendancesCard";
interface IAttendancesPageProps {
  sessionId?: string;
}
const AttendancesPage: FC<IAttendancesPageProps> = (props) => {
  const { sessionId } = props;
  const { messages } = useIntl();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [search, setSearch] = useState<IAttendanceSearch>({});
  const [isSelected, setIsSelected] = useState(false);
  const { courseIdParams } = useParams();
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);
  const [dataList, setDataList] = useState<IAttendanceList>();
  const {
    isFetching,
    error,
    data: attendanceList,
  } = useGetListWithEnable<IAttendanceList>(
    AppUrls.SessionAttendance,
    {
      ...search,
    },
    isSelected
  );

  const columns = AttendanceColumns();

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
          ]}
          currentPathName={"session.attendance"}
        />

        <Grid item xs={12}>
          <AttendanceFilter
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            search={search}
            setSearch={setSearch}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              isSelected={isSelected}
              showMessage={"message.filter"}
              rowCount={attendanceList?.totalCount!}
              pageNumber={search?.pageNumber! ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize! ?? 10}
              columns={columns}
              rows={attendanceList?.data!}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <AttendancesCard
              isSelected={isSelected}
              AttendancesList={attendanceList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((attendanceList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default AttendancesPage;
