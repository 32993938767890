import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useAdvanceFilterAutocompleteService,
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { ICategoriesListDto } from "types/entitiesTypes/categories";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInstructorsListDto } from "types/entitiesTypes/instructors";
import { IRegionsListDto } from "types/entitiesTypes/regions";
import {
  IWorkShopsListDto,
  IWorkShopsSearch,
} from "types/entitiesTypes/workShops";
import WorkShopsCard from "./WorkShopsCard";
import { WorkShopsColumns } from "./WorkShopsColumns";

const WorkShopsPages = () => {
  const { messages, locale } = useIntl();
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const [search, setSearch] = useState<IWorkShopsSearch>({
    pageNumber: 1,
    pageSize: 10,
    educationalCenterId: educationalCenterIdParams || null!,
    instructorId: instructorIdParams || null!,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IWorkShopsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  let objectFilter = {
    instructorId: search.instructorId ? search.instructorId : null,
    educationalCenterId: search.educationalCenterId
      ? search.educationalCenterId
      : null,
    regionId: search.regionId ? search.regionId : null,
  };

  const [regionKeyword, setRegionKeyword] = useState<string>("");
  const [regionPageNumber, setRegionPageNumber] = useState(1);

  const [categoryKeyword, setCategoryKeyword] = useState<string>("");
  const [categoryPageNumber, setCategoryPageNumber] = useState(1);

  const [educationalCenterKeyword, setEducationalCenterKeyword] =
    useState<string>("");
  const [educationalCenterPageNumber, setEducationalCenterPageNumber] =
    useState(1);

  const [instructorKeyword, setInstructorKeyword] = useState<string>("");
  const [instructorPageNumber, setInstructorPageNumber] = useState(1);

  const {
    resultData: WorkShopsList,
    isFetching,
    error,
  } = useGetListService<IWorkShopsListDto>(AppUrls.WorkShops, {
    ...search,
    advancedFilter,
  });

  const {
    data: educationalCentersList,
    isFetching: isFetchingEducationalCenters,
    refetch: refetchEducationalCenters,
  } = useAdvanceFilterAutocompleteService<IEducationalCenterListDto>(
    AppUrls.EducationalCenter,
    {
      keyword: educationalCenterKeyword,
      pageSize: 10,
      pageNumber: educationalCenterPageNumber,
    },
    Boolean(!educationalCenterIdParams)
  );

  useEffect(() => {
    if (Boolean(!educationalCenterIdParams)) refetchEducationalCenters();
  }, [educationalCenterPageNumber, educationalCenterIdParams]);

  const {
    data: instructorList,
    isFetching: isFetchingInstructors,
    refetch: refetchInstructors,
  } = useAdvanceFilterAutocompleteService<IInstructorsListDto>(
    AppUrls.Instructor,
    {
      keyword: instructorKeyword,
      pageSize: 10,
      pageNumber: instructorPageNumber,
    },
    Boolean(!instructorIdParams)
  );

  useEffect(() => {
    if (Boolean(!educationalCenterIdParams)) refetchEducationalCenters();
  }, [educationalCenterPageNumber, educationalCenterIdParams]);

  useEffect(() => {
    if (Boolean(!instructorIdParams)) refetchInstructors();
  }, [instructorPageNumber, instructorIdParams]);

  // For details
  const handleDetails = useCallback((selectedRow: IWorkShopsListDto) => {
    setSelectedRow(selectedRow);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IWorkShopsListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IWorkShopsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  //for Activation
  const handleToggleActive = useCallback((selectedRow: IWorkShopsListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);

  const columns = WorkShopsColumns(
    handleDetails,
    handleUpdate,
    handleDelete,
    handleToggleActive
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.WorkShops,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );
  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.WorkShops,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    { key: "nameAr", value: String(messages["WorkShops.nameAr"]) },
    {
      key: "nameEn",
      value: String(messages["WorkShops.nameEn"]),
    },
    {
      key: "educationalCenter.nameAr",
      value: String(messages["educationalCenter.nameAr"]),
    },
    {
      key: "educationalCenter.nameEn",
      value: String(messages["educationalCenter.nameEn"]),
    },

    {
      key: "instructor.firstNameAr",
      value: String(messages["instructor.firstNameAr"]),
    },
    {
      key: "instructor.lastNameAr",
      value: String(messages["instructor.lastNameAr"]),
    },
    {
      key: "instructor.firstNameEn",
      value: String(messages["instructor.firstNameEn"]),
    },
    {
      key: "instructor.lastNameEn",
      value: String(messages["instructor.lastNameEn"]),
    },

    {
      key: "region.nameAr",
      value: String(messages["region.nameAr"]),
    },
    {
      key: "region.nameEn",
      value: String(messages["region.nameEn"]),
    },
    {
      key: "code",
      value: String(messages["WorkShops.code"]),
    },
    {
      key: "isActive",
      value: String(messages["common.isActive"]),
    },
  ];
  const {
    data: regionsList,
    isFetching: isFetchingRegions,
    refetch: refetchRegions,
  } = useAdvanceFilterAutocompleteService<IRegionsListDto>(AppUrls.Regions, {
    keyword: regionKeyword,
    pageSize: 10,
    pageNumber: regionPageNumber,
  });

  const {
    data: categoriesList,
    isFetching: isFetchingCategories,
    refetch: refetchCategories,
  } = useAdvanceFilterAutocompleteService<ICategoriesListDto>(
    AppUrls.Categories,
    {
      keyword: categoryKeyword,
      pageSize: 10,
      pageNumber: categoryPageNumber,
    }
  );
  useEffect(() => {
    refetchCategories();
  }, [categoryPageNumber]);
  useEffect(() => {
    refetchRegions();
  }, [regionPageNumber]);

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
          ]}
          currentPathName={"sidebar.WorkShops"}
          isShow={!educationalCenterIdParams && !instructorIdParams}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="WorkShops.add"
            showAddButton={false}
            showAddButtonWithPage={true}
            pathPage={`/scientificContentManagement/WorkShops/add/${
              educationalCenterIdParams
                ? "educationalCenter/" + educationalCenterIdParams
                : instructorIdParams
                ? "instructor/" + instructorIdParams
                : ""
            }`}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.WorkShops}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={WorkShopsList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.WorkShops"])}
            pdfHeaders={pdfHeaders}
            withExportExcelButton={true}
            customQueries={{
              ...objectFilter,
            }}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterAutoComplete
                label="WorkShops.region"
                fieldName="regionId"
                options={regionsList?.data}
                isLoading={isFetchingRegions}
                searchText={regionKeyword}
                setSearchText={setRegionKeyword}
                onChangeTextField={debounce((event) => {
                  setRegionKeyword(event.target.value);
                }, DEBOUNCE_TIME)}
                //pagination
                pageNumber={regionPageNumber}
                setPageNumber={setRegionPageNumber}
                hasNextPage={regionsList?.hasNextPage}
                setAdvancedFilter={setSearch}
                advancedFilter={search}
              />,
              Boolean(!educationalCenterIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="WorkShops.educationalCenter"
                  fieldName="educationalCenterId"
                  options={educationalCentersList?.data}
                  isLoading={isFetchingEducationalCenters}
                  searchText={educationalCenterKeyword}
                  setSearchText={setEducationalCenterKeyword}
                  onChangeTextField={debounce((event) => {
                    setEducationalCenterKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={educationalCenterPageNumber}
                  setPageNumber={setEducationalCenterPageNumber}
                  hasNextPage={educationalCentersList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
              Boolean(!instructorIdParams) && (
                <AppAdvanceFilterAutoComplete
                  label="WorkShops.instructor"
                  fieldName="instructorId"
                  filterKey="firstNameAr"
                  getOptionLabel={(option) =>
                    `${option?.firstNameAr} ${option?.lastNameAr}`
                  }
                  options={instructorList?.data}
                  isLoading={isFetchingInstructors}
                  searchText={instructorKeyword}
                  setSearchText={setInstructorKeyword}
                  onChangeTextField={debounce((event) => {
                    setInstructorKeyword(event.target.value);
                  }, DEBOUNCE_TIME)}
                  //pagination
                  pageNumber={instructorPageNumber}
                  setPageNumber={setInstructorPageNumber}
                  hasNextPage={instructorList?.hasNextPage}
                  setAdvancedFilter={setSearch}
                  advancedFilter={search}
                />
              ),
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={WorkShopsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={WorkShopsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <WorkShopsCard
              handleDelete={handleDelete}
              WorkShopsList={WorkShopsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((WorkShopsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              handleToggleActive={handleToggleActive}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* {showEditModal && (
        <EditWorkShops
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="WorkShops.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.nameEn || "",
          }}
        />
      )} */}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"WorkShops.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          title={"WorkShops.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}

      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "WorkShops.actions.deactivationTitle"
              : "WorkShops.actions.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "WorkShops.actions.deactivationConfirmation"
              : "WorkShops.actions.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default WorkShopsPages;
