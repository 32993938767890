import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import defaultImage from "assets/appDefaultImages/user.png";
import moment from "moment";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import { useParams } from "react-router-dom";
import { getValueBasedOnLang } from "helpers";
import AppIsExistView from "components/basicComponents/others/AppIsExistView";

export const DiplomasColumns = (
  handleDetails = (row: IDiplomasListDto) => {},
  handleUpdate = (row: IDiplomasListDto) => {},
  handleDelete = (row: IDiplomasListDto) => {},
  handleToggleActive = (row: IDiplomasListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { educationalCenterIdParams, instructorIdParams } = useParams();

  const DiplomasColumns: GridColDef[] = [
    {
      field: "coverPhoto",
      type: "image",
      headerName: messages["diplomas.coverPhotoPath"] as string,
      headerAlign: "center",
      minWidth: 10,
      align: "center",
      filterable: false,
      hide: educationalCenterIdParams || instructorIdParams ? true : false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <AppViewImage
            image={params.row?.coverPhoto}
            defaultImage={defaultImage}
          />
        );
      },
    },
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 80,
      hide: false,
      // locale == "en-US" && (educationalCenterIdParams || instructorIdParams)
      //   ? true
      //   : false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row.nameAr} />,
    },
    {
      field: "nameEn",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 80,
      hide: false,
      // locale != "en-US" && (educationalCenterIdParams || instructorIdParams)
      //   ? true
      //   : false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row.nameEn} />,
    },
    {
      field: "instructorSalary",
      headerName: messages["diplomas.instructorSalary"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.instructorSalary} />
      ),
    },
    {
      field: "coursesCount",
      headerName: messages["diplomas.coursesCount"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.coursesCount} />
      ),
    },
    {
      field: "startDate",
      headerName: messages["diplomas.startDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params?.row?.startDate
              ? moment(params?.row?.startDate).local().format("YYYY-MM-DD HH A")
              : "-"
          }
        />
      ),
    },

    {
      field: "singlePaymentCost",
      headerName: messages["diplomas.singlePaymentCost"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.singlePaymentCost} />
      ),
    },
    {
      field: "multiplePaymentsCost",
      headerName: messages["diplomas.multiplePaymentsCost"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.multiplePaymentsCost} />
      ),
    },
    {
      field: "discountCost",
      headerName: messages["diplomas.discountCost"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.discountCost} />
      ),
    },
    {
      field: "expenses",
      headerName: messages["diplomas.expenses"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params?.row?.expenses} />,
    },
    {
      field: "educationalCenterFees",
      headerName: messages["diplomas.educationalCenterFees"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params?.row?.educationalCenterFees} />
      ),
    },
    {
      field: "tax",
      headerName: messages["diplomas.tax"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      hide: true,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params?.row?.tax} />,
    },

    {
      field: locale === "ar-SA" ? "category.nameAr" : "category.nameEn",
      headerName: messages["diplomas.category"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.category?.nameAr,
            params.row?.category?.nameEn
          )}
        />
      ),
    },

    {
      field: locale === "ar-SA" ? "region.nameAr" : "region.nameEn",
      headerName: messages["diplomas.region"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      hide: educationalCenterIdParams || instructorIdParams ? true : false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.region?.nameAr,
            params.row?.region?.nameEn
          )}
        />
      ),
    },
    {
      field: "isFree",
      headerName: messages["diplomas.isFree"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppIsExistView isExist={params?.row?.isFree} />,
    },
    {
      field: "isActive",
      headerName: messages["common.isActive"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      align: "center",
      renderCell: (params) => {
        return <StatusBadge isActive={params?.row?.isActive} />;
      },
    },

    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              withLink={true}
              toPathLink={`/scientificContentManagement/diplomas/profile/${params?.row?.id}`}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit */}
            <AppIconButton
              label="table.actions.edit"
              withLink={true}
              toPathLink={`/scientificContentManagement/diplomas/edit/${params?.row?.id}`}
              childComp={
                <EditOutlinedIcon fontSize="small" sx={{ color: "#A0CCDA" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            />

            {/* Activation */}
            <AddDataGridActivation
              value={params.row.isActive}
              handleClick={() => {
                handleToggleActive(params.row);
              }}
              // For Permissions
              schemaName={SchemaName.ClientsManagement}
              entityName={EntityName.Departments}
              action={PermissionActionsTypes.Activate}
            />

            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return DiplomasColumns;
};
