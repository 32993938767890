import AttendancePage from "components/trainStructure/courses/attendance/AttendancePage";
import CourseProfile from "./courses/CourseProfile";
import CoursesPages from "./courses/CoursesPages";
import ExamsPages from "./courses/exams/ExamsPages";
import EducationalCenterPages from "./educationalCenter/EducationalCenterPages";
import EducationalCenterProfile from "./educationalCenter/EducationalCenterProfile";
import InstructorPages from "./instructor/InstructorPages";
import InstructorProfile from "./instructor/InstructorProfile";
import StudentPages from "./student/StudentPages";
import StudentProfile from "./student/StudentProfile";
import StudentsSubscriptionPage from "./studentsSubscription/StudentsSubscriptionPage";
import StudentsSubscriptionProfile from "./studentsSubscription/StudentsSubscriptionProfile";
import AttendancesPage from "components/trainStructure/courses/attendance/AttendancesPage";
import VirtualSceneRequestsPage from "./virtualSceneRequests/VirtualSceneRequestsPage";
import RequestsEducationalDocumentsPage from "./virtualSceneRequests/RequestsEducationalDocumentsPage";
import StudentAttachmentsPage from "./student/studentAttachments/StudentAttachmentsPage";
import AttachmentsPage from "components/trainStructure/courses/attachments/AttachmentsPage";
import AttachmentsSidePage from "./attachments/AttachmentsSidePage";

export const educationalCentersPages = [
  {
    path: "/trainStructure/university",
    element: <EducationalCenterPages />,
  },

  {
    path: "/trainStructure/exams",
    element: <ExamsPages />,
  },
  {
    path: "/trainStructure/attendance",
    element: <AttendancesPage />,
  },

  {
    path: "/trainStructure/virtualSceneRequests",
    element: <VirtualSceneRequestsPage />,
  },

  {
    path: "/trainStructure/requestsEducationalDocuments",
    element: <AttachmentsSidePage />,

    // element: <RequestsEducationalDocumentsPage />,
  },
  {
    path: "/trainStructure/university/profile/:educationalCenterIdParams/*",
    element: <EducationalCenterProfile />,
  },
  //EducationalCenterProfile trainStructure/instructors
  {
    path: "/trainStructure/instructors",
    element: <InstructorPages />,
  },
  {
    path: "/trainStructure/instructors/profile/:instructorIdParams/*",
    element: <InstructorProfile />,
  },
  // Courses page
  {
    path: "/trainStructure/courses",
    element: <CoursesPages />,
  },
  // Courses Details with Paramas
  {
    path: "/trainStructure/courses/profile/:courseIdParams/*",
    element: <CourseProfile />,
  },

  // Student page StudentsSubscriptionPage
  {
    path: "/trainStructure/student",
    element: <StudentPages />,
  },

  // {
  //   path: "/trainStructure/subscriptions",
  //   element: <StudentsSubscriptionPage />,
  // },
  // {
  //   path: "/trainStructure/subscriptions/profile/:subscriptionIdParams/*",
  //   element: <StudentsSubscriptionProfile />,
  // },
  // Student Details with Paramas StudentsSubscriptionProfile
  {
    path: "/trainStructure/student/profile/:studentIdParams/*",
    element: <StudentProfile />,
  },
];
