import { queryClient } from "App";
import { AxiosError, ResponseType } from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { handleError, handleSuccess } from "services";
import {
  IBaseCreateDto,
  IBaseUpdateDto,
} from "types/appTypes/baseEntity/baseDto";
import {
  IBaseResponse,
  IListBaseResponse,
} from "types/appTypes/response/baseResponse";
import { IBaseSearchDto } from "types/appTypes/searchDto";
import axiosInstance from "./axiosInstance";
import { BaseActions } from "./baseActions";
import { getHeaders } from "redux/actions/exporting";
import { bool } from "aws-sdk/clients/signer";

export const useDeleteIndicatorParameterService = <TResponse>(
  url: string,
  indicatorId: string | number,
  indexId: string | number,
  onSuccess: () => void
) => {
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.delete<IBaseResponse<TResponse>>(
        url.buildUrl(""),
        {
          data: {
            indicatorId: indicatorId,
            indexId: indexId,
          },
        }
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("deletedSuccessfully");
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useDeleteParameterService = <TResponse, TBody>(
  url: string,
  urlAfterRequest: string,
  body: TBody,
  onSuccess: () => void,
  apiVersion: boolean = true
) => {
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.delete<IBaseResponse<TResponse>>(
        url.buildUrl("", "", apiVersion),
        {
          data: body,
        }
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("deletedSuccessfully");
        onSuccess && onSuccess();
        if (url) queryClient.invalidateQueries(urlAfterRequest + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

// For Call Get Request (Such as List) With custom react query key
export const useCustomCreateService = <TBody extends IBaseCreateDto, TResponse>(
  url: string,
  urlAfterRequest?: string,
  successMessage?: string,
  body?: TBody | null,
  asFormData: boolean = true,
  onSuccess?: (data: any) => void
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TResponse>>(
        url,
        asFormData ? serialize(dataToSend) : dataToSend
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess(successMessage || "createSuccess");
        onSuccess && onSuccess(data?.data);
        if (url) {
          queryClient.invalidateQueries(urlAfterRequest + `-list`);
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useCustomUpdateService = <TBody extends IBaseCreateDto, TResponse>(
  url: string,
  urlAfterRequest?: string,
  successMessage?: string,
  asFormData: boolean = true,
  endRefresh: string = "id",
  onSuccess?: (data: any) => void,
  body?: TBody | null
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.put<IBaseResponse<TResponse>>(
        url,
        asFormData ? serialize(dataToSend) : dataToSend
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess(successMessage || "createSuccess");
        onSuccess && onSuccess(data?.data);
        if (url) {
          queryClient.invalidateQueries(`${urlAfterRequest}-${endRefresh}`);
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useDownloadFileService = <TBody extends IBaseCreateDto, TResponse>(
  url: string,
  body?: TBody | null,
  onSuccess?: (data: any) => void,
  withApiVersion: boolean = true
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  useEffect(() => {
    if (dataToSend) request.mutate();
  }, [dataToSend]);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TResponse>>(
        withApiVersion ? url.buildUrl() : url,
        dataToSend
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess("setAllSuccessfully");
        onSuccess && onSuccess(data?.data);
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    // request.mutate();
  };

  return { ...request, submitData: submitData };
};

// For using in any service with put method
export const useCustomToggleActivationService = <TBody, TResponse>(
  url: string,
  urlAfterRequest: string,
  id: string,
  onSuccess?: () => void,
  activatedSuccessfully: string = "activatedSuccessfully",
  deactivatedSuccessfully: string = "deactivatedSuccessfully"
) => {
  var result;
  // const [dataToSend, setDataToSend] = useState<TBody>();
  // if (body) setDataToSend(body);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.put<IBaseResponse<TResponse>>(
        id ? `${url}/${id}` : url
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess(
          result.data.data ? activatedSuccessfully : deactivatedSuccessfully
        );
        onSuccess && onSuccess();
        if (url) {
          queryClient.invalidateQueries(urlAfterRequest + "-list");
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    // setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useCustomToggleService = <TBody, TResponse>(
  url: string,
  urlAfterRequest: string,
  id: string,
  body?: TBody | null,

  onSuccess?: () => void,
  activatedSuccessfully: string = "activatedSuccessfully",
  deactivatedSuccessfully: string = "deactivatedSuccessfully"
) => {
  var result;

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.post<IBaseResponse<TResponse>>(
        id ? `${url}/${id}` : url,
        { ...body }
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess(
          result.data.data ? activatedSuccessfully : deactivatedSuccessfully
        );
        onSuccess && onSuccess();
        if (url) {
          queryClient.invalidateQueries(urlAfterRequest + "-list");
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    // setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useCustomToggleReadService = <
  TResponse,
  TBody extends IBaseCreateDto
>(
  url: string,
  id: string | number,
  onSuccess: () => void
) => {
  var result;
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.put<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.ReadMessage, id)
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess(
          result.data.data ? "setAllSuccessfully" : "desAllSuccessfully"
        );
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

// For Call Get Request (Such as List) With custom react query key
export const useCustomCreateServiceForTick = <
  TBody extends IBaseCreateDto,
  TResponse
>(
  url: string,
  urlAfterRequest?: string,
  successMessage?: string,
  body?: TBody | null,
  asFormData: boolean = true,
  onSuccess?: (data: any) => void
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  useEffect(() => {
    if (dataToSend) request.mutate();
  }, [dataToSend]);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TResponse>>(
        url.buildUrl(),
        asFormData ? serialize(dataToSend) : dataToSend
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess(successMessage || "createSuccess");
        onSuccess && onSuccess(data?.data);
        if (url) {
          queryClient.invalidateQueries(urlAfterRequest);
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    // request.mutate();
  };

  return { ...request, submitData: submitData };
};

// Custom Update Service For Update Role Permission
export const useUpdateRolePermission = <
  TBody extends IBaseUpdateDto,
  TResponse
>(
  url: string,
  onSuccess?: () => void,
  asFormData: boolean = true,
  body?: TBody | null
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  useEffect(() => {
    if (dataToSend) request.mutate();
  }, [dataToSend]);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.put<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.Update, dataToSend?.id),
        asFormData ? serialize(dataToSend) : dataToSend
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("updateSuccess");
        onSuccess && onSuccess();
        if (url) {
          queryClient.invalidateQueries(url + "-list");
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    // request.mutate();
  };

  return { ...request, submitData: submitData };
};

// For get list with isEnable
export const useGetListWithEnable = <TEntity>(
  url: string,
  search?: IBaseSearchDto,
  isEnable?: boolean
) => {
  const [data, setData] = useState<IListBaseResponse<TEntity> | undefined>(
    undefined
  );
  const request = useQuery(
    [url + "-listWithEnable", search],
    async () => {
      if (!search) search = { pageNumber: 1, pageSize: 10 };
      if (!search.pageSize) search.pageSize = 10;
      if (!search.pageNumber) search.pageNumber = 1;
      const result = await axiosInstance.post<IListBaseResponse<TEntity>>(
        url.buildUrl(BaseActions.Search, "", false),
        search
      );
      setData(result.data);
      return result.data;
    },
    {
      enabled: isEnable,
      onError: handleError,
    }
  );

  return { ...request, data };
};

// Get User Permissions
export const getPersonalPermissionsRequest = async (url) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // const response = await axiosInstance.get<any>(url);
    return response?.data;
  } catch (error) {
    handleError(error as AxiosError<any>);
  }
};

// For pdf and excel
export const getPdfAndExcelList = async (url, options) => {
  // const connectionErrorEn =
  //   "Something went wrong, make sure that you are connected to the internet";
  // const connectionErrorAr = "فشلت العملية. تأكد من اتصالك بالانترنت";
  // const currentLocale = JSON.parse(localStorage.getItem('lan'))?.locale || 'en';
  // List Default Options
  const defaultOptions = {
    pageNumber: 1,
    pageSize: 10,
    keyword: null,
    isActive: null,
    customQueries: {},
  };
  const params = { ...defaultOptions, ...options };
  const token = localStorage.getItem("token");
  const searchObj = {
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    keyword: params.keyword,
    isActive: params.isActive,
    ...params.customQueries,
  };
  try {
    const response = await axiosInstance.post(
      url,
      searchObj,
      getHeaders(token)
    );
    return response.data;
  } catch (error) {
    const x = error as AxiosError<any>;
    if (x?.response) {
      if (x.response.status === 401) {
        localStorage.removeItem("token");
        // location.reload();
        return;
      }
      throw new Error(x.response.data.messages?.join(","));
    }
    // else if (currentLocale == 'en') {
    //   throw new Error(connectionErrorEn);
    // }
    // else if (currentLocale == 'ar') {
    //   throw new Error(connectionErrorAr);
    // }
  }
};

export const useGetByIdCustomService = <TEntity>(
  url: string,
  id: string | number,
  isEnable: boolean
) => {
  const request = useQuery(
    [url + "-id", id],
    async () => {
      const result = await axiosInstance.get<IBaseResponse<TEntity>>(
        url.buildUrl(BaseActions.ById, id)
      );
      return result.data.data;
    },
    {
      enabled: isEnable,
      onError: handleError,
    }
  );

  return { ...request };
};

export const useGetByIdResponsDircutllyService = <TEntity>(
  url: string,
  id: string | number,
  isEnable: boolean,
  responseType: ResponseType = "stream",
  setVersion = true
) => {
  const request = useQuery(
    [url + "-id", id],
    async () => {
      const result = await axiosInstance.get<any>(
        url.buildUrl(BaseActions.ById, id, setVersion),
        {
          responseType: responseType,
        }
      );
      return result;
    },
    {
      enabled: isEnable,
      onError: handleError,
    }
  );

  return { ...request };
};

export const useGetCustomService = <TEntity>(
  url: string,
  id: string | number,
  isEnable: boolean = true
) => {
  const request = useQuery(
    [url + "-id", id],
    async () => {
      const result = await axiosInstance.get<IBaseResponse<TEntity>>(url);
      return result.data.data;
    },
    {
      onError: handleError,
      enabled: isEnable,
    }
  );

  return { ...request };
};

// export const handleDownloadPdf = async (
//   url: string,
//   id: string | number,
//   isEnable: boolean = true
// ) => {
//   const result = await axiosInstance.get<Blob>(
//     url.buildUrl(BaseActions.ById, "76450f3f-1ab3-4d47-fc64-08dcddfca06c"),
//     {
//       headers: { "content-type": "application/pdf" },
//       responseType: "stream",
//     }
//   );
//   // Create a blob from the response
//   const blob = new Blob([result?.data!], {
//     type: "application/pdf",
//   });
//   const url1 = window.URL.createObjectURL(blob);

//   // Create a link element to download the PDF
//   const link = document.createElement("a");
//   link.href = url;
//   link.setAttribute("download", `file_${id}.pdf`); // Name the file based on the id
//   document.body.appendChild(link);
//   link.click();

//   // Cleanup the link
//   link.parentNode?.removeChild(link);
//   window.URL.revokeObjectURL(url);
// };

export const useCustomTaskService = <TEntity, TResponse>(
  url: string,
  urlAfterSuccess: string,
  activated: string,
  deactivated: string,
  body: TEntity | null,
  onSuccess: (data: any) => void
) => {
  var result;
  const request = useMutation<IBaseResponse<TResponse>, AxiosError<any>>(
    url,
    async () => {
      result = await axiosInstance.post<IListBaseResponse<TEntity>>(
        url.buildUrl(),
        body
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess(result.data.data ? activated : deactivated);
        onSuccess && onSuccess(data?.data);
        queryClient.invalidateQueries(urlAfterSuccess + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useGetPdfService = <TEntity>(
  url: string,
  id: string | number,
  isEnable: boolean = true
) => {
  const request = useQuery(
    [url + "-id", id],
    async () => {
      const result = await axiosInstance.get<Blob>(`${url}/${id}`, {
        headers: {
          "Content-Type": "application/pdf", // Requesting PDF
        },
        responseType: "blob",
      });
      // تحويل blob إلى رابط URL
      const url1 = window.URL.createObjectURL(result.data);

      // إنشاء رابط التحميل
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", "file.pdf"); // Optional: Specify the name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url1);
      return result.data;
    },
    {
      onError: handleError,
      enabled: isEnable,
    }
  );

  return { ...request };
};

export const useGetListCustomService = <TEntity>(
  url: string,
  search?: IBaseSearchDto,
  apiVersion: boolean = true,
  isEnable?: boolean
) => {
  const [resultData, setResultData] = useState<
    IListBaseResponse<TEntity> | undefined
  >(undefined);
  const request = useQuery(
    [url + "-list", search],
    async () => {
      if (!search) search = { pageNumber: 1, pageSize: 10 };
      if (!search.pageSize) search.pageSize = 10;
      if (!search.pageNumber) search.pageNumber = 1;
      const result = await axiosInstance.post<IListBaseResponse<TEntity>>(
        url.buildUrl(BaseActions.Search, "", apiVersion),
        search
      );
      setResultData(result.data);
      return result.data;
    },
    {
      enabled: isEnable,

      onError: handleError,
    }
  );

  return { ...request, resultData };
};

export const useDeleteWithBodyService = <
  TBody extends IBaseCreateDto,
  TResponse
>(
  url: string,
  id: string | number,
  body: TBody | null,
  onSuccess: () => void
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.delete<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.Delete, id),
        { data: dataToSend }
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("deletedSuccessfully");
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useDownloadFileTableService = <
  TBody extends IBaseCreateDto,
  TResponse
>(
  url: string,
  body?: TBody | null,
  onSuccess?: (data: any) => void,
  withApiVersion: boolean = true
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  if (body) setDataToSend(body);

  // useEffect(() => {
  //   if (dataToSend) request.mutate();
  // }, [dataToSend]);

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TResponse>>(
        withApiVersion ? url.buildUrl() : url,
        dataToSend
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess("downloadAllSuccessfully");
        onSuccess && onSuccess(data?.data);
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

const paramsSerializer = (params) => {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`
    )
    .join("&");
};

export const changeNotificationItemToIsReadResource = async (url, id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.put(url + `/${id}` + "/read", null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response;
  } catch (error) {
    handleError(error as AxiosError);
  }
};
export const getPersonalNotificationRequest = async (url, option = {}) => {
  try {
    const response = await axiosInstance.post(url, {
      ...option,
    });
    return response?.data;
  } catch (error) {
    handleError(error as AxiosError);
  }
};
