import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IExamResultsListDto } from "types/entitiesTypes/examResult";

import moment from "moment";

interface IExamResultCardProps {
  handleDetails: (row: IExamResultsListDto) => void;

  ExamResultList: Array<IExamResultsListDto>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const ExamResultCard: FC<IExamResultCardProps> = (props) => {
  const {
    ExamResultList,
    loading,
    errorMessage,
    handleDetails,
    pageNumber,
    setPage,
    page,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {ExamResultList?.length != undefined && ExamResultList?.length >= 1 ? (
          ExamResultList?.map((item, index) => (
            <Grid key={`ExamResultList${index}`} item xs={12} sm={12}>
              <Card sx={{ width: "100%" }}>
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    handleClick={() => {
                      handleDetails(item);
                    }}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "0 !important",
                  }}
                >
                  <DataItem
                    label={messages["student.name"] as string}
                    value={item?.student?.fullName}
                  />

                  <DataItem
                    label={messages["exams.name"] as string}
                    value={getValueBasedOnLang(
                      item?.exam?.nameAr,
                      item?.exam?.nameEn
                    )}
                  />

                  <DataItem
                    label={messages["exams.score"] as string}
                    value={item?.score}
                  />
                  <DataItem
                    label={messages["exams.passingScore"] as string}
                    value={item?.exam?.passingScore}
                  />
                  <DataItem
                    label={messages["exams.totalMarks"] as string}
                    value={item?.exam?.totalMarks}
                  />
                  <DataItem
                    label={messages["exams.date"] as string}
                    value={moment(item?.exam?.date).format("YYYY-MM-DD HH:mm")}
                  />
                  <DataItem
                    label={messages["exams.pass"] as string}
                    value={
                      item.pass ? (
                        <CheckIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}

        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default ExamResultCard;
