import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import {
  dateTimeComparison,
  dateTimeFormatter,
} from "helpers/string/dateFormatter";
import moment from "moment";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWorkshopSessionsListDto } from "types/entitiesTypes/workshopSessions";

export const WorkShopSessionsColumns = (
  // handleAddAttachment = (row: IWorkshopSessionsListDto) => {},
  handleDetails = (row: IWorkshopSessionsListDto) => {},
  handleUpdate = (row: IWorkshopSessionsListDto) => {},
  handleDelete = (row: IWorkshopSessionsListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();
  // const now = new Date();
  const now = moment();
  const WorkShopSessionsColumns: GridColDef[] = [
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameAr} />,
    },
    {
      field: "nameEn",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameEn} />,
    },
    {
      field: "date",
      headerName: messages["session.date"] as string,
      headerAlign: "center",
      align: "center",

      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={dateTimeFormatter(params.row?.date)} />
      ),
    },
    {
      field: "duration",
      headerName: messages["session.duration"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.duration} />,
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Add Attachment */}
            {/* <AppIconButton
              label="table.actions.addAttachment"
              handleClick={() => {
                handleAddAttachment(params?.row);
              }}
              childComp={
                <AddToDriveIcon fontSize="small" sx={{ color: "#4285F4" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            /> */}

            {/* Details  */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params?.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit */}
            {dateTimeComparison(params.row?.date) && (
              <AppIconButton
                label="table.actions.edit"
                handleClick={() => {
                  handleUpdate(params.row);
                }}
                childComp={
                  <EditOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#A0CCDA" }}
                  />
                }
                // For Permissions
                schemaName={SchemaName.SystemConfigurations}
                entityName={EntityName.Countries}
                action={PermissionActionsTypes.Edit}
              />
            )}

            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return WorkShopSessionsColumns;
};
