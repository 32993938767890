import { Divider, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import DataItem from "components/basicComponents/mobile/DataItem";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter, timeFormatter } from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { IWorkShopAttendanceList } from "types/entitiesTypes/workshopAttendance";
import { getLCP } from "web-vitals";

interface IWorkShopAttendanceCardProps {
  WorkShopAttendanceList: Array<IWorkShopAttendanceList>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const WorkShopAttendanceCard: FC<IWorkShopAttendanceCardProps> = (props) => {
  const { WorkShopAttendanceList, loading, errorMessage } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {WorkShopAttendanceList?.length != undefined &&
        WorkShopAttendanceList?.length >= 1 ? (
          WorkShopAttendanceList?.map((item, index) => (
            <>
              <Grid key={`WorkShopAttendanceList${index}`} item xs={12}>
                <DataItem
                  label={messages["session.studentName"] as string}
                  value={item.studentName}
                />
                <DataItem
                  label={messages["session.name"] as string}
                  value={getValueBasedOnLang(
                    item?.workshopNameAr ? item?.workshopNameAr : "-",
                    item?.workshopNameEn ? item?.workshopNameEn : "-"
                  )}
                  marginY={2}
                />
                <DataItem
                  label={messages["instructor.name"] as string}
                  value={getValueBasedOnLang(
                    item?.instructorNameAr ? item?.instructorNameAr : "-",
                    item?.instructorNameEn ? item?.instructorNameEn : "-"
                  )}
                  marginY={2}
                />
                <DataItem
                  label={messages["session.attendanceTime"] as string}
                  value={timeFormatter(item?.attendanceTime)}
                  marginY={2}
                />
                <DataItem
                  label={messages["columns.status"] as string}
                  value={<StatusAttendance status={item.status} />}
                  marginY={2}
                />
              </Grid>

              <Grid key={`DividerHorizontal${index}`} xs={12}>
                <Divider orientation="horizontal" />
              </Grid>
            </>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {/* {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )} */}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default WorkShopAttendanceCard;
