import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICoursesListDto } from "types/entitiesTypes/courses";
import { ICreateSessionDto } from "types/entitiesTypes/session";
import * as yup from "yup";

interface IAddSessionsProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  item?: ICoursesListDto;
}

const AddSessions: React.FC<IAddSessionsProps> = (props) => {
  const { showAddModal, setShowAddModal, label, item } = props;
  const { messages } = useIntl();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const refForm = React.useRef<FormikProps<ICreateSessionDto>>(null);

  const { courseIdParams } = useParams();
  const mutation = useCreateService<ICreateSessionDto, string>(
    AppUrls.CreateWithAttach,
    null,
    true,
    () => {},
    false,
    null,
    AppUrls.Session
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    // date: yup.string().required(String(messages["validation.required"])),
    date: yup
      .date()
      .typeError(String(messages["validation.required"]))
      .required(String(messages["validation.required"]))
      .min(
        new Date(),
        String(messages["validation.timeSessionGreaterThanFrom"])
      ),
    duration: yup
      .number()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={(mutation?.isLoading && !mutation?.isError) || false}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            nameAr: "",
            nameEn: "",
            courseId: courseIdParams!,
            date: "",
            files: "",
            duration: null!,
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameAr" />}
                    name="nameAr"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="common.nameEn" />}
                    name="nameEn"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppDateTimePicker
                    value={values?.date}
                    label={"session.date"}
                    name="date"
                    onChange={setFieldValue}
                    disablePast={true}
                    minTime={false}
                    // minTime={dayjs()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextField
                    label={<IntlMessages id="session.duration" />}
                    name="duration"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <AppUploadDocument
                  formRef={refForm}
                  keyName={"files"}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  multiple
                  appGridXs={12}
                  uploadModernXs={12}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddSessions;
