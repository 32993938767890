import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SxProps, Theme } from "@mui/material";
import AppPermissionsGate, {
  IAppPermissionsProps,
} from "components/basicComponents/permessions/AppPermissionsGate";
import * as React from "react";
import { useIntl } from "react-intl";

interface IAddDataGridAttendProps extends IAppPermissionsProps {
  value: boolean;

  sxProp?: SxProps<Theme>;
}

const AddDataGridAttend: React.FC<IAddDataGridAttendProps> = (props) => {
  const { locale } = useIntl();
  const {
    value,
    sxProp = {
      padding: 0.3,
    },
    schemaName,
    entityName,
    action,
  } = props;

  return (
    <AppPermissionsGate
      schemaName={schemaName}
      entityName={entityName}
      action={action}
    >
      <IconButton sx={sxProp}>
        {value ? (
          <CheckIcon
            // sx={{ transform: locale !== "en-US" ? "rotateY(180deg)" : "none" }}
            color="info"
          />
        ) : (
          <CloseIcon color="error" />
        )}
      </IconButton>
    </AppPermissionsGate>
  );
};

export default AddDataGridAttend;
