import { Grid } from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAutocompleteService } from "services";
import { useCustomCreateService } from "services/additionalServices";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import { IRoleListDto } from "types/entitiesTypes/roles";
import { IAssignUserToRoleDto, IUserListDto } from "types/entitiesTypes/users";
import * as yup from "yup";

interface IAddRoleToUserModalProps {
  showAssignRoleModal: boolean;
  setShowAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddRoleToUserModal: React.FC<IAddRoleToUserModalProps> = (props) => {
  const { showAssignRoleModal, setShowAssignRoleModal, label } = props;
  const { messages, locale } = useIntl();
  const { userIdParams } = useParams();

  const refForm = React.useRef<FormikProps<IAssignUserToRoleDto>>(null);
  const [keywordForRoleSearch, setKeywordForRoleSearch] = useState<string>("");
  const [rolesPageNumber, setRolesPageNumber] = useState(1);

  //Get Roles
  const {
    data: rolesList,
    isFetching,
    refetch: refetchRoles,
  } = useAutocompleteService<IRoleListDto>(AppUrls.Roles, {
    keyword: keywordForRoleSearch,
    isActive: true,
    pageSize: 10,
    pageNumber: rolesPageNumber,

    exceptUserRoles: true,
    userId: userIdParams,
  });

  const mutation = useCustomCreateService<IAssignUserToRoleDto, string>(
    AppUrls.AssignUserToMultiRole,
    "roles-list",
    "createSuccess",
    null,
    false
  );

  const validationSchema = yup.object({
    roleIds: yup.array().min(1, String(messages["validation.required"])),
  });

  useEffect(() => {
    refetchRoles();
  }, [rolesPageNumber]);

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAssignRoleModal}
        onClose={() => setShowAssignRoleModal(false)}
        closeModalState={setShowAssignRoleModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"xs"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            roleIds: [],
            userId: userIdParams || "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AppBaseAutoComplete
                    label="users.roleName"
                    // options={rolesList?.data}
                    // options={rolesList?.data?.filter(
                    //   (item) =>
                    //     !values?.roleIds?.includes(item?.id) &&
                    //     item?.name != "Employee"
                    // )}
                    options={rolesList?.data
                      ?.map((item) => {
                        if (item?.name !== "Employee") {
                          const isSelected = values?.roleIds?.includes(
                            item?.id
                          );
                          return {
                            ...item,
                            selected: isSelected,
                            disabled: isSelected,
                          };
                        }
                        return null;
                      })
                      .filter(Boolean)}
                    isLoading={isFetching}
                    onChangeAutoComplete={(event, value) => {
                      // setFieldValue("roleId", value ? value?.id : "");
                      setFieldValue(
                        "roleIds",
                        value ? value?.map((item) => item?.id) : []
                      );
                      setRolesPageNumber(1);

                      value && setKeywordForRoleSearch("");
                    }}
                    multiple
                    //pagination
                    pageNumber={rolesPageNumber}
                    setPageNumber={setRolesPageNumber}
                    hasNextPage={rolesList?.hasNextPage}
                    searchText={keywordForRoleSearch}
                    filterKey={locale == "en-US" ? "name" : "nameAr"}
                    onChangeTextField={debounce((event) => {
                      setRolesPageNumber(1);

                      setKeywordForRoleSearch(event.target.value);
                    }, DEBOUNCE_TIME)}
                    error={errors.roleIds}
                    touched={touched.roleIds}
                    getOptionLabel={(option) => {
                      if (locale == "en-US" && option?.name) {
                        return option?.name;
                      } else return option?.nameAr;
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddRoleToUserModal;
