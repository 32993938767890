import { useThemeContext } from "@crema/utility/AppContextProvider/ThemeContextProvider";
import { GridColDef } from "@mui/x-data-grid";
import AddDataGridAttend from "components/basicComponents/dataGrid/AddDataGridAttend";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWorkshopParticipantsListDto } from "types/entitiesTypes/workshopParticipants";
import { BaseFileUrl } from "services/appUrls";
import AppDataGridCellLink from "components/basicComponents/dataGrid/AppDataGridCellLink";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const WorkshopParticipantsColumns = (
  isCertified: boolean,
  handleCertificate = (row: IWorkshopParticipantsListDto) => {},
  handleUpdate = (row: IWorkshopParticipantsListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();
  const { theme } = useThemeContext();

  const WorkshopParticipantsColumns: GridColDef[] = [
    {
      field: "student.fullName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      type: "dateTime",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.student?.fullName} />
      ),
    },

    {
      field: "overAllRating",
      headerName: messages[
        "WorkShopParticipants.workshop.overAllRating"
      ] as string,
      headerAlign: "center",
      align: "center",
      type: "dateTime",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <AppDataGridCell value={params.row?.overAllRating} />;
      },
    },
    {
      field: "certificateUrl",
      headerName: messages["certificateUrl"] as string,
      headerAlign: "center",
      align: "center",
      type: "dateTime",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCellLink
          value={`${
            params.row?.certificateUrl
              ? BaseFileUrl + params.row?.certificateUrl
              : "-"
          }`}
          label="common.click"
        />
      ),
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Certificate */}
            {/* <AppIconButton
              label="table.actions.certificate"
              handleClick={() => {
                isCertified && handleCertificate(params.row);
              }}
              childComp={
                <WorkspacePremiumIcon
                  style={{
                    color: isCertified ? theme.palette.primary.main : "#777",
                    cursor: isCertified ? "pointer" : "default",
                  }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            /> */}

            {/* { Edit Rating } */}
            {/* <AppIconButton
              label="table.actions.edit"
              handleClick={() => {
                handleUpdate(params.row);
              }}
              childComp={
                <EditOutlinedIcon fontSize="small" sx={{ color: "#A0CCDA" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            /> */}
          </div>
        );
      },
    },
  ];

  return WorkshopParticipantsColumns;
};
