import { Grid, Hidden } from "@mui/material";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import { FC, useCallback, useState } from "react";
import { AppUrls } from "services/appUrls";

import { useGetByIdService } from "services";
import { IWorkShopAttendanceList } from "types/entitiesTypes/workshopAttendance";
import WorkShopAttendanceCard from "./WorkShopAttendanceCard";
import { WorkShopAttendanceColumns } from "./WorkShopAttendanceColumns";
import EditWorkShopSession from "components/scientificContentManagement/workShops/workshopSessions/EditSession";
import AppEditWorkShops from "components/scientificContentManagement/workShops/editWorkShops/AppEditWorkShops";
import EditAttendance from "components/scientificContentManagement/workShops/workshopSessions/attendance/EditAttendance";
import AttendanceDetails from "components/scientificContentManagement/workShops/workshopSessions/attendance/AttendanceDetails";

interface IWorkShopAttendancesPageProps {
  sessionId?: string;
}
const WorkShopAttendancesPage: FC<IWorkShopAttendancesPageProps> = (props) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IWorkShopAttendanceList>();
  const { sessionId } = props;
  const {
    data: AttendanceList,
    isFetching,
    refetch,
    error,
  } = useGetByIdService<Array<IWorkShopAttendanceList>>(
    AppUrls.WorkshopSessionAttendances,
    `${sessionId}`
  );
  const handleUpdate = useCallback((selectedRow: IWorkShopAttendanceList) => {
    setShowEditModal(true);
    setSelectedRow(selectedRow!);
  }, []);

  const handleDetails = useCallback((selectedRow: IWorkShopAttendanceList) => {
    setShowDetailsModal(true);
    setSelectedRow(selectedRow!);
  }, []);

  const columns = WorkShopAttendanceColumns(handleDetails, handleUpdate);

  return (
    <>
      <Grid container spacing={3}>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={AttendanceList?.length ?? 0}
              pageNumber={1}
              //for pagination
              onPageSizeChange={() => {}}
              onPageChange={() => {}}
              pageSize={AttendanceList?.length ?? 0}
              columns={columns}
              rows={AttendanceList ?? []}
              loading={isFetching}
              //for selection

              checkboxSelection={false}
              //for sorting
              onSortModelChange={() => {}}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <WorkShopAttendanceCard
              WorkShopAttendanceList={AttendanceList!}
              loading={isFetching}
              errorMessage={error!}
            />
          </Grid>
        </Hidden>
      </Grid>
      {showEditModal && (
        <EditAttendance
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="attendace.edit"
          selectedRow={selectedRow!}
          refetch={refetch}
        />
      )}
      {showDetailsModal && (
        <AttendanceDetails
          setShowDetails={setShowDetailsModal!}
          showDetails={showDetailsModal}
          selectedRow={selectedRow!}

        />
      )}
    </>
  );
};

export default WorkShopAttendancesPage;
