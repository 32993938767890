import IntlMessages from "@crema/utility/IntlMessages";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppButton from "components/basicComponents/buttons/AppButton";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import * as React from "react";
import { IBaseEntity } from "types/appTypes/baseEntity/baseEntity";

interface IAppDetailsModalProps {
  label: string;
  open: boolean;
  maxWidthAsPixel?: number;
  columns?: number;
  onClose: () => void;
  maxWidth?: "lg" | "md" | "sm" | "xs" | "xl";
  loading: boolean;
  errorMessage: any;
  moreDetails?: IBaseEntity;
  spacing?: number;
  withoutSecoundTab?: boolean;
  tabs?: Array<{ id?: string; label?: string; component?: React.ReactNode }>;
  itemName: string;
  Uploadpdf: any;
  successClose: boolean;
  setShowCertificateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITab {
  id?: string;
  label?: string;
  component?: React.ReactNode;
}

const AppCertificateModal: React.FC<IAppDetailsModalProps> = (props) => {
  const {
    open,
    onClose,
    loading,
    maxWidthAsPixel,
    errorMessage,
    children,
    itemName,
    label,
    successClose,
    setShowCertificateModal,
  } = props;
  const [value, setValue] = React.useState<string>("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useDialogStyles = makeStyles({
    paper: {
      minWidth: `${maxWidthAsPixel}px`,
    },
  });
  const dialogClasses = useDialogStyles();

  React.useEffect(() => {
    if (successClose) setShowCertificateModal(false);
  }, [successClose]);

  return (
    <Dialog
      classes={dialogClasses}
      //   fullWidth
      //   maxWidth={maxWidth}
      open={open}
      fullScreen
      onClose={onClose}
    >
      <DialogTitle display={"flex"} alignItems="center" gap={10}>
        <IconButton sx={{ zIndex: 5 }} onClick={onClose}>
          <CloseIcon
            fontSize="medium"
            onClick={onClose}
            sx={{ cursor: "pointer", color: "#D72638" }}
          />
        </IconButton>
        <Typography color="primary" variant="h3">
          <IntlMessages id={label} />{" "}
          <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {"  :  "}
            {itemName}
          </span>
        </Typography>
        {/* <IconButton onClick={handleDownloadPdf}>
          <IntlMessages id={"downloadCertificate"} />
        </IconButton> */}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <LoadingAndErrorWrapper
            isLoading={loading}
            errorMessage={errorMessage?.response?.data.messages[0]}
          >
            <Box
              sx={{
                width: "100%",
                typography: "body1",
              }}
            >
              {children}
            </Box>
          </LoadingAndErrorWrapper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AppCertificateModal;
