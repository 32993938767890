import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import useGeneralAutoComplete from "./useGeneralAutoComplete";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { DiplomasType, typeDocument } from "types/entitiesTypes/enums";
import {
  ICreateStudentAttachments,
  IStudentAttachmentsList,
} from "types/entitiesTypes/studentAttachments";
import * as yup from "yup";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { IStudentListDto } from "types/entitiesTypes/student";
import { IStudentsSubscriptionListDto } from "types/entitiesTypes/studentSubscription";

interface IAddStudentAttachmentsProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  diplomaId?: string;
  studentId?: string;
}

const AddStudentAttachments: React.FC<IAddStudentAttachmentsProps> = (
  props
) => {
  const { showAddModal, setShowAddModal, label, studentId, diplomaId } = props;
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateStudentAttachments>>(null);

  const mutation = useCreateService<ICreateStudentAttachments, string>(
    AppUrls.StudentDocuments,
    null,
    true
  );
  const { studentIdParams } = useParams();
  const validationSchema = yup.object({
    type: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),

    documentPath: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
    diplomaId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    studentId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    certificateNumber: yup.string().when("type", {
      is: (type) => type === typeDocument.Certificate,
      then: yup.string().required(String(messages["validation.required"])),
      otherwise: yup.string().nullable(),
    }),
  });
  const {
    keyword: diplomaKeyword,
    setKeyword: setEducationalCenterKeyword,
    dataList: diplomasList,
    isFetching: isFetchingdiploma,
    pageNumber: diplomaPageNumber,
    setPageNumber: setEducationalCenterPageNumber,
  } = useGeneralAutoComplete<IStudentsSubscriptionListDto>({
    url: AppUrls.Subscription,
  });

  const {
    keyword: studentKeyword,
    setKeyword: setStudentKeyword,
    dataList: studentList,
    isFetching: isFetchingStudent,
    pageNumber: studentPageNumber,
    setPageNumber: setStudentPageNumber,
  } = useGeneralAutoComplete<IStudentListDto>({
    url: AppUrls.Student,
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            studentId: studentIdParams ? studentIdParams! : studentId!,
            certificateNumber: "",
            type: null!,
            documentPath: "",
            diplomaId: diplomaId ? diplomaId! : "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  {!diplomaId && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <AppBaseAutoComplete
                          key={values?.diplomaId}
                          label="diplomas.name"
                          options={diplomasList?.data
                            .filter((item) => item.studentId == studentIdParams)
                            .map((item) => item.diploma)}
                          isLoading={isFetchingdiploma}
                          defaultValue={values.diploma}
                          value={values.diploma}
                          onChangeAutoComplete={(event, value) => {
                            setFieldValue("diploma", value);

                            setFieldValue("diplomaId", value ? value?.id : "");
                            !value && setEducationalCenterKeyword("");
                          }}
                          //pagination
                          pageNumber={diplomaPageNumber}
                          setPageNumber={setEducationalCenterPageNumber}
                          hasNextPage={diplomasList?.hasNextPage}
                          searchText={diplomaKeyword}
                          onChangeTextField={debounce((event) => {
                            setEducationalCenterPageNumber(1);
                            setEducationalCenterKeyword(event.target.value);
                          }, DEBOUNCE_TIME)}
                          error={errors?.diplomaId}
                          touched={touched?.diplomaId}
                        />
                      </Grid>
                    </>
                  )}
                  {!studentIdParams && !studentId && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <AppBaseAutoComplete
                          key={values?.studentId}
                          label="student.name"
                          options={studentList?.data}
                          isLoading={isFetchingStudent}
                          defaultValue={values.student}
                          value={values.student}
                          onChangeAutoComplete={(event, value) => {
                            setFieldValue("student", value);
                            setFieldValue("studentId", value ? value?.id : "");
                            !value && setStudentKeyword("");
                          }}
                          getOptionLabel={(option) => option.fullName}
                          filterKey="fullName"
                          //pagination
                          pageNumber={studentPageNumber}
                          setPageNumber={setStudentPageNumber}
                          hasNextPage={studentList?.hasNextPage}
                          searchText={studentKeyword}
                          onChangeTextField={debounce((event) => {
                            setStudentPageNumber(1);
                            setStudentKeyword(event.target.value);
                          }, DEBOUNCE_TIME)}
                          error={errors?.studentId}
                          touched={touched?.studentId}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <AppSelectField
                      label={<IntlMessages id="student.typeDocument" />}
                      name="type"
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      <MenuItem value={typeDocument.VirtualFile}>
                        <IntlMessages id="student.attachment.virtualFile" />
                      </MenuItem>
                      <MenuItem value={typeDocument.AcademicRecord}>
                        <IntlMessages id="student.attachment.academicRecord" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Statement}>
                        <IntlMessages id="student.attachment.statement" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Certificate}>
                        <IntlMessages id="student.attachment.certificate" />
                      </MenuItem>
                    </AppSelectField>
                  </Grid>
                  {values.type === typeDocument.Certificate && (
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        label={<IntlMessages id="student.certificateNumber" />}
                        name="certificateNumber"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} />
                  <AppUploadDocument
                    formRef={refForm}
                    keyName={"documentPath"}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    errors={!!errors.documentPath}
                    helperText="validation.file"
                    appGridXs={6}
                    uploadModernXs={3}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddStudentAttachments;
