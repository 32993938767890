import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCustomizedSwitch from "components/basicComponents/inputs/AppCustomizedSwitch";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AppEditModal from "components/basicComponents/modals/AppEditModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import { generatePresignedUrl, getValueBasedOnLang } from "helpers";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IEducationalCenterDetailsDto,
  IUpdateEducationalCenterDto,
} from "types/entitiesTypes/educationalCenter";

import {
  IRegionsDetailsDto,
  IUpdateIRegionDto,
} from "types/entitiesTypes/regions";
import * as yup from "yup";

interface IEditEducationalCenterProps {
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedRow: ISelectedRow;
  onSuccess?: () => void;
  onFailed?: () => void;
}
const EditEducationalCenter: React.FC<IEditEducationalCenterProps> = (
  props
) => {
  const { showEditModal, setShowEditModal, label, selectedRow } = props;
  const { messages } = useIntl();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const [uploadedStampFiles, setUploadedStampFiles] = useState<any[]>([]);
  const [openStamp, setOpenStamp] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [openSignatureStemp, setOpenSignatureStemp] = useState(false);

  const refForm = React.useRef<FormikProps<IUpdateEducationalCenterDto>>(null);

  const {
    data: educationalCenterItem,
    error,
    isLoading,
  } = useGetByIdService<IEducationalCenterDetailsDto>(
    AppUrls.EducationalCenter,
    selectedRow?.id
  );

  const mutation = useUpdateService<IUpdateEducationalCenterDto, string>(
    AppUrls.EducationalCenter,
    () => {}
  );
  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    // addressEn: yup.string().required(String(messages["validation.required"])),
    // addressAr: yup.string().required(String(messages["validation.required"])),
    // managerSignature: yup
    //   .array()
    //   .min(1, String(messages["validation.file"]))
    //   .required("validation.file"),
    // stamp: yup
    //   .array()
    //   .min(1, String(messages["validation.file"]))
    //   .required("validation.file"),
  });

  useEffect(() => {
    if (educationalCenterItem && educationalCenterItem.managerSignature)
      setUploadedFiles([
        {
          preview: generatePresignedUrl(
            educationalCenterItem.managerSignature,
            process.env.NODE_ENV
          ),

          // BaseFileUrl + educationalCenterItem.managerSignature,
          originalUrl: educationalCenterItem.managerSignature,
        },
      ]);

    if (educationalCenterItem && educationalCenterItem.stamp)
      setUploadedStampFiles([
        {
          preview: generatePresignedUrl(
            educationalCenterItem.stamp,
            process.env.NODE_ENV
          ),
          // BaseFileUrl + educationalCenterItem.stamp,
          originalUrl: educationalCenterItem.stamp,
        },
      ]);
  }, [educationalCenterItem]);

  useEffect(() => {
    if (uploadedFiles)
      refForm.current?.setFieldValue("managerSignature", uploadedFiles);
  }, [uploadedFiles]);

  useEffect(() => {
    if (uploadedStampFiles)
      refForm.current?.setFieldValue("stamp", uploadedStampFiles);
  }, [uploadedStampFiles]);
  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppEditModal
        label={label}
        itemName={getValueBasedOnLang(
          ` - ${selectedRow?.nameAr}`,
          selectedRow?.nameEn ? ` - ${selectedRow?.nameEn}` : ""
        )}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        handleSubmit={submit}
        loading={isLoading}
        errorMessage={(error as any) || null}
        loadingForEdit={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        closeModalState={setShowEditModal}
        successClose={mutation.isSuccess}
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            id: selectedRow?.id || "",
            nameAr: educationalCenterItem?.nameAr || "",
            nameEn: educationalCenterItem?.nameEn || "",
            // addressAr: educationalCenterItem?.addressAr || "",
            // addressEn: educationalCenterItem?.addressEn || "",
            isActive: educationalCenterItem?.isActive!,
          }}
          innerRef={refForm}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            if (
              uploadedFiles?.length === 0 &&
              uploadedStampFiles?.length === 0
            ) {
              setOpenSignatureStemp(true);
            } else if (uploadedFiles?.length === 0) {
              setOpenSignature(true);
            } else if (uploadedStampFiles?.length === 0) {
              setOpenStamp(true);
            } else {
              const managerSignatureFile = uploadedFiles[0];
              const stampFile = uploadedStampFiles[0];

              if (managerSignatureFile) {
                // Image has not changed
                if (managerSignatureFile.originalUrl)
                  data.managerSignature = uploadedFiles[0].originalUrl;
                else data.managerSignature = managerSignatureFile;
              }

              if (stampFile) {
                // Image has not changed
                if (stampFile.originalUrl)
                  data.stamp = uploadedStampFiles[0].originalUrl;
                else data.stamp = stampFile;
              }
              mutation.submitData(data);
            }
          }}
        >
          {({ setFieldValue, values, touched }) => (
            <>
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameAr" />}
                      name="nameAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameEn" />}
                      name="nameEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.addressAr" />}
                      name="addressAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.addressEn" />}
                      name="addressEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"flex-start"}
                  >
                    <AppCustomizedSwitch
                      keyName="isActive"
                      onChange={setFieldValue}
                      defaultChecked={values?.isActive}
                    />
                  </Grid>
                  <AppUploadPhoto
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    keyName="managerSignature"
                    formRef={refForm}
                    // errors={
                    //   !!errors.managerSignature && touched?.managerSignature
                    // }
                    uploadText="educationalCenter.managerSignature"
                    helperText="validation.file"
                    appGridXs={12}
                    uploadModernXs={12}
                  />

                  <AppUploadPhoto
                    uploadedFiles={uploadedStampFiles}
                    setUploadedFiles={setUploadedStampFiles}
                    keyName="stamp"
                    formRef={refForm}
                    // errors={!!errors.stamp}
                    uploadText="educationalCenter.stamp"
                    helperText="validation.file"
                    appGridXs={12}
                    uploadModernXs={12}
                  />
                </Grid>
              </Form>
              {openSignatureStemp && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openSignatureStemp}
                  setOpen={setOpenSignatureStemp}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.SignatureStamp"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();

                    const formValues = refForm?.current?.values;

                    if (formValues) {
                      const newValues: IUpdateEducationalCenterDto = {
                        ...formValues,
                        stamp: "",
                        managerSignature: "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        // addressEn: formValues.addressEn ?? "",
                        // addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };

                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
              {openSignature && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openSignature}
                  setOpen={setOpenSignature}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.Signature"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();

                    const formValues = refForm?.current?.values;

                    if (formValues) {
                      const newValues: IUpdateEducationalCenterDto = {
                        ...formValues,
                        stamp: formValues.stamp ? formValues.stamp[0] : "",
                        managerSignature: "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        // addressEn: formValues.addressEn ?? "",
                        // addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };

                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
              {openStamp && (
                <AppConfirmModal
                  dialogTitle={"educationalCenter.Add.warning"}
                  open={openStamp}
                  setOpen={setOpenStamp}
                  loading={mutation.isLoading}
                  title={"educationalCenter.Add.stamp"}
                  onConfirm={async () => {
                    await refForm?.current?.validateForm();
                    const formValues = refForm?.current?.values;
                    if (formValues) {
                      const newValues: IUpdateEducationalCenterDto = {
                        ...formValues,
                        stamp: "",
                        managerSignature: formValues.managerSignature
                          ? formValues.managerSignature[0]
                          : "",
                        nameEn: formValues.nameEn ?? "",
                        nameAr: formValues.nameAr ?? "",
                        // addressEn: formValues.addressEn ?? "",
                        // addressAr: formValues.addressAr ?? "",
                        isActive: formValues.isActive ?? true,
                      };
                      mutation.submitData(newValues);
                    }
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </AppEditModal>
    </>
  );
};

export default EditEducationalCenter;
