import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as React from "react";
import styles from "./index.module.css";
import logo from "./logo.png";
import pattern from "./logo.svg";
import { useUpdateService } from "services";
import {
  IUpdateWorkshopParticipantsDto,
  IWorkshopParticipantsListDto,
} from "types/entitiesTypes/workshopParticipants";
import { AppUrls } from "services/appUrls";
import moment from "moment";
import AppButton from "components/basicComponents/buttons/AppButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  IWorkShopsDetailsDto,
  IWorkShopsListDto,
} from "types/entitiesTypes/workShops";
interface ICertificateTemplateProps {
  item: IWorkshopParticipantsListDto;
  workShop: IWorkShopsDetailsDto;
  Uploadpdf: any;
}
const CertificateTemplate: React.FC<ICertificateTemplateProps> = (props) => {
  const { item, workShop, Uploadpdf } = props;
  const { WorkShopsIdParams } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const now = moment().format("YYYY-MM-DD");
  const navigate = useNavigate();

  const printRef = React.useRef<HTMLDivElement>(null);
  const handleDownloadPdf = async () => {
    setIsLoading(true);
    if (printRef.current) {
      const pdf = new jsPDF({
        orientation: "landscape", // or 'portrait'
        unit: "mm",
        format: [297, 210], // A4 size in landscape (width x height)
      });
      const content = printRef.current;
      const children = Array.from(content.children);
      const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
      const imgWidth = 280; // Adjusted width for better fit

      const headerMargin = 10; // Set header margin
      let position = headerMargin; // Start position for content

      for (const child of children) {
        const canvas = await html2canvas(child as HTMLElement, {
          scale: 2,
          useCORS: true,
        });
        const imgData = canvas.toDataURL("image/jpeg", 0.5);
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate the height of the image

        // Check if the current image fits on the page
        if (position + imgHeight > pageHeight) {
          pdf.addPage(); // Add a new page
          position = headerMargin; // Reset position for new page with header margin
        }

        pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
        position += imgHeight; // Move position down by the height of the image
      }
      console.log("pdf", pdf);
      const pdfBlob = pdf.output("blob"); // Save the PDF
      console.log("pdfBlob", pdfBlob);
      Uploadpdf.submitData({
        Certificate: pdfBlob,
        id: item.id,
      });
      if (!Uploadpdf.isLoading) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <div>
        <div className={styles.container} ref={printRef}>
          {/* header */}
          <div className={styles.header}>
            <img className={styles.pattern} src={pattern} />
            <h1 className={styles.title}>
              شهادة حضور ورشة
              <br />
              Certificate of attendance at a workshop
            </h1>
          </div>
          {/* content */}
          <div className={styles.content}>
            <div className={styles.contentRightSide}>
              <p className={styles.presentedTo}>منحت هذه الشهادة للمتدرب :</p>
              <p className={styles.traineeName}>{item?.student?.fullName}</p>
              <p className={styles.description}>
                بعد نجاحه في إكمال ورشة{" "}
                <strong> {workShop?.nameAr ? workShop?.nameAr : "-"} </strong>{" "}
                في أكاديمية أبها التدريبية وعدد ساعاتها{" "}
                <strong> {workShop?.durationInHours} </strong> ساعة تدريبية
              </p>
              <p className={styles.issuedCertificate}>
                حررت هذه الشهادة بتاريخ {now}
              </p>
            </div>

            <div className={styles.contentLeftSide}>
              <p className={styles.presentedTo}>
                : This Certificate has been presented to
              </p>
              <p className={styles.traineeName}>{item?.student?.fullName}</p>
              <p className={styles.description}>
                On the Successful Completion of{" "}
                <strong>{workShop?.nameEn ? workShop?.nameEn : "-"}</strong>{" "}
                Workshop in Abha Training Academy with{" "}
                <strong>{workShop?.durationInHours}</strong> Training hours
              </p>
              <p className={styles.issuedCertificate}>
                This Certificate has been issued on: {now}
              </p>
            </div>
          </div>
          {/* Line */}
          <div className={styles.line} />
          <p className={styles.academicName}>أكاديمية أبها</p>
          <div className={styles.containerLogo}>
            <img className={styles.logo} src={logo} />
          </div>
          <div style={{ paddingTop: "50px" }} />
          <div className={styles.images} />
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "20px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AppButton
          label={"downloadCertificate"}
          loading={isLoading}
          onClick={handleDownloadPdf}
        />
      </div>
    </>
  );
};

export default CertificateTemplate;
