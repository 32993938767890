import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  debounce,
} from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadPhoto from "components/basicComponents/uploadFile/uploadPhoto/AppUploadPhoto";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAutocompleteService, useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { IBranchListDto } from "types/entitiesTypes/branches";
import { NotificationMethod } from "types/entitiesTypes/enums";
import { ICreateINotificationListDto } from "types/entitiesTypes/messageEngine";
import { IStatesListDto } from "types/entitiesTypes/states";
import * as yup from "yup";

import { Editor } from "@tinymce/tinymce-react";
import { Fonts } from "shared/constants/AppEnums";
import { IRoleListDto } from "types/entitiesTypes/roles";
import { IUserListDto } from "types/entitiesTypes/users";
import AppDropDownList from "components/basicComponents/inputs/AppDropDownList";
import { ICreateInstanceNotificationsDto } from "types/entitiesTypes/instanceNotifications";
import { IStudentListDto } from "types/entitiesTypes/student";

interface IAddNotificationProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddNotification: React.FC<IAddNotificationProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const { messages } = useIntl();
  const refForm =
    React.useRef<FormikProps<ICreateInstanceNotificationsDto>>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const mutation = useCreateService<ICreateInstanceNotificationsDto, string>(
    AppUrls.InstanceNotifications
  );

  const validationSchema = yup.object({
    titleAr: yup.string().required(String(messages["validation.required"])),
    titleEn: yup.string().required(String(messages["validation.required"])),

    messageAr: yup.string().required(String(messages["validation.required"])),
    messageEn: yup.string().required(String(messages["validation.required"])),
    userIds: yup.array().min(1, String(messages["validation.required"])),
    notifyDate: yup
      .date()
      .nullable()
      .typeError(String(messages["validation.required"]))
      .min(
        new Date(),
        String(messages["validation.notifyDateGreaterThanFrom"])
      ),
  });

  const [userKeyword, setUsersKeyword] = useState<string>();
  const [usersPageNumber, setUsersPageNumber] = useState(1);

  const [userValues, setUserValues] = useState<Array<IUserListDto>>();

  // Get User
  const {
    data: usersList,
    isFetching,
    refetch: refetchUsers,
  } = useAutocompleteService<IStudentListDto>(AppUrls.Student, {
    isActive: true,
    keyword: userKeyword,
    pageNumber: usersPageNumber,
    pageSize: 10,
  });

  useEffect(() => {
    refetchUsers();
  }, [usersPageNumber]);

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"md"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            userIds: [],
            // file: "",
            titleAr: "",
            titleEn: "",
            messageAr: "",
            messageEn: "",
            notifyDate: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data) => {
            // if (uploadedFiles)
            //   data = {
            //     ...data,
            //     file: uploadedFiles[0],
            //   };
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, touched, errors }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <AppTextField
                      label={<IntlMessages id="notification.titleAr" />}
                      name="titleAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AppTextField
                      label={<IntlMessages id="notification.titleEn" />}
                      name="titleEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <AppDateTimePicker
                      label="notification.notifyDate"
                      name="notifyDate"
                      value={values?.notifyDate!}
                      onChange={setFieldValue}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AppBaseAutoComplete
                      label="sidebar.usersNames"
                      filterKey="fullName"
                      multiple
                      getOptionLabel={(option) => option?.fullName}
                      isLoading={isFetching}
                      // value={values?.userIds?.length ? userValues : []}
                      defaultValue={values?.userIds?.length ? userValues : []}
                      options={usersList?.data?.filter((item) => {
                        const isSelected = values?.userIds?.includes(item?.id);
                        return {
                          ...item,
                          selected: isSelected,
                          disabled: isSelected,
                        };
                      })}
                      onChangeAutoComplete={(event, value) => {
                        setUserValues(value);
                        setFieldValue(
                          "userIds",
                          value ? value?.map((item) => item?.userId) : ""
                        );

                        setUsersPageNumber(1);
                        value && setUsersKeyword("");
                      }}
                      //pagination
                      pageNumber={usersPageNumber}
                      setPageNumber={setUsersPageNumber}
                      hasNextPage={usersList?.hasNextPage}
                      searchText={userKeyword}
                      onChangeTextField={debounce((event) => {
                        setUsersPageNumber(1);
                        setUsersKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors.userIds}
                      touched={touched.userIds}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AppTextField
                      label={<IntlMessages id="notification.messageAr" />}
                      name="messageAr"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AppTextField
                      label={<IntlMessages id="notification.messageEn" />}
                      name="messageEn"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  </Grid>

                  {/* <AppUploadPhoto
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    uploadModernXs={2}
                    appGridXs={2}
                  /> */}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddNotification;
