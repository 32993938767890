import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppCheckbox from "components/basicComponents/inputs/AppCheckbox";
import AppEditor from "components/basicComponents/inputs/AppEditor";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppDatePicker from "components/basicComponents/inputs/dateTimePickers/AppDatePicker";
import AppDateTimePicker from "components/basicComponents/inputs/dateTimePickers/AppDateTimePicker";
import AppTimePicker from "components/basicComponents/inputs/dateTimePickers/AppTimePicker";
import useGeneralAutoComplete from "components/scientificContentManagement/useGeneralAutoComplete";
import { FormikErrors, FormikHelpers, FormikTouched } from "formik";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { IInstructorsListDto } from "types/entitiesTypes/instructors";
import { ICreateWorkShopsDto } from "types/entitiesTypes/workShops";

interface ISecondStepProps {
  setFieldValue: FormikHelpers<ICreateWorkShopsDto>["setFieldValue"];
  errors: FormikErrors<ICreateWorkShopsDto>;
  touched: FormikTouched<ICreateWorkShopsDto>;
  values: ICreateWorkShopsDto;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

const SecondStep = (props: ISecondStepProps) => {
  const { errors, setFieldValue, touched, values, setFieldTouched } = props;
  const { locale } = useIntl();
  const { instructorIdParams } = useParams();

  const {
    keyword: instructorKeyword,
    setKeyword: setinstructorKeyword,
    dataList: instructorsList,
    isFetching: isFetchinginstructor,
    pageNumber: instructorPageNumber,
    setPageNumber: setinstructorPageNumber,
  } = useGeneralAutoComplete<IInstructorsListDto>({
    url: AppUrls.Instructor,
  });
  return (
    <Grid container spacing={4}>
      {!instructorIdParams && (
        <Grid item xs={12} sm={4}>
          <AppBaseAutoComplete
            label="WorkShops.instructor"
            options={instructorsList?.data}
            isLoading={isFetchinginstructor}
            defaultValue={values.instructor}
            onChangeAutoComplete={(event, value) => {
              setFieldValue("instructor", value ? value : null);
              setFieldValue("instructorId", value ? value?.id : "");
              // setFieldTouched("instructorId", false);
              !value && setinstructorKeyword("");
            }}
            //pagination
            pageNumber={instructorPageNumber}
            setPageNumber={setinstructorPageNumber}
            hasNextPage={instructorsList?.hasNextPage}
            searchText={instructorKeyword}
            onChangeTextField={debounce((event) => {
              setinstructorPageNumber(1);
              setinstructorKeyword(event.target.value);
            }, DEBOUNCE_TIME)}
            error={errors.instructorId}
            touched={touched?.instructorId}
            filterKey={locale === "en-US" ? "firstNameEn" : "firstNameAr"}
            getOptionLabel={(option) =>
              locale === "en-US" && option?.firstNameEn
                ? `${option?.firstNameEn} ${option?.lastNameEn}`
                : `${option?.firstNameAr} ${option?.lastNameAr}`
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <AppTextField
          label={<IntlMessages id="WorkShops.durationInHours" />}
          name="durationInHours"
          variant="outlined"
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AppTextField
          label={<IntlMessages id="WorkShops.code" />}
          name="code"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AppDateTimePicker
          label="WorkShops.startDate"
          name="startDate"
          value={values?.startDate!}
          onChange={setFieldValue}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid item xs={5} sm={4}>
          <AppCheckbox
            keyName="isCertified"
            onChange={setFieldValue}
            defaultChecked={values?.isCertified}
            label="WorkShops.isCertified"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AppEditor
          label="WorkShops.outcomesAr"
          name="outcomesAr"
          setFieldValue={setFieldValue}
          value={values?.outcomesAr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AppEditor
          label="WorkShops.outcomesEn"
          name="outcomesEn"
          setFieldValue={setFieldValue}
          value={values?.outcomesEn}
        />
      </Grid>
    </Grid>
  );
};

export default SecondStep;
