import { Grid, Hidden } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import { RegionsColumns } from "./RegionsColumns";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AppAdvanceFilterTextField from "components/basicComponents/filters/AppAdvanceFilterTextField";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { IRegionsListDto, IRegionsSearch } from "types/entitiesTypes/regions";
import AddRegion from "components/systemConfigurations/regions/AddRegion";
import EditRegion from "components/systemConfigurations/regions/EditRegion";
import RegionsDetails from "components/systemConfigurations/regions/RegionsDetails";
import RegionsCard from "./RegionsCard";
import { debounce } from "lodash";

const RegionsPages = () => {
  const { messages } = useIntl();
  const [search, setSearch] = useState<IRegionsSearch>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  let objectFilter = {};
  const [selectedRow, setSelectedRow] = useState<IRegionsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);
  const [inputValue, setInputValue] = useState(""); // Initialize the input value
  const [showIconSearch, setShowIconSearch] = useState(false); // State for showing the search icon

  const [restValueInput, setRestValueInput] = useState(false); // Initialize the input value
  // State for showing the search icon

  const {
    resultData: regionsList,
    isFetching,
    error,
  } = useGetListService<IRegionsListDto>(AppUrls.Regions, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IRegionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IRegionsListDto) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IRegionsListDto) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  //for Activation
  const handleToggleActive = useCallback((selectedRow: IRegionsListDto) => {
    setSelectedRow(selectedRow);
    setShowActivationModal(true);
  }, []);

  const columns = RegionsColumns(
    handleDetails,
    handleUpdate,
    handleDelete,
    handleToggleActive
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.Regions,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  const mutationForToggleActive = useToggleActiveService<boolean>(
    AppUrls.Regions,
    selectedRow?.id || "",
    () => setShowActivationModal(false)
  );

  const pdfHeaders: IPdfHeader[] = [
    { key: "nameAr", value: String(messages["common.nameAr"]) },
    {
      key: "nameEn",
      value: String(messages["common.nameEn"]),
    },
    {
      key: "isActive",
      value: String(messages["common.isActive"]),
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[{ name: messages["sidebar.systemConfigurations.title"] }]}
          currentPathName={"sidebar.regions"}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="systemConfigurations.regions.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Regions}
            keyword={search?.keyword}
            isActive={search?.isActive}
            customQueries={{
              ...objectFilter,
            }}
            restInputValue={restValueInput}
            setRestInputValue={setRestValueInput}
            showIconSearch={showIconSearch}
            setShowIconSearch={setShowIconSearch}
            advancedFilter={advancedFilter}
            totalCount={regionsList?.totalCount || 0}
            excelFileTitle={String(messages["sidebar.regions"])}
            pdfHeaders={pdfHeaders}
            withExportExcelButton={true}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={false}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            // advanceFiltersInputs={[
            //   <AppAdvanceFilterTextField
            //     fieldName="nameAr"
            //     label="common.nameAr"
            //     setAdvancedFilter={setAdvancedFilter}
            //     advancedFilter={advancedFilter}
            //   />,
            //   <AppAdvanceFilterTextField
            //     fieldName="nameEn"
            //     label="common.nameEn"
            //     setAdvancedFilter={setAdvancedFilter}
            //     advancedFilter={advancedFilter}
            //   />,
            // ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={regionsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={regionsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <RegionsCard
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              handleDetails={handleDetails}
              regionsList={regionsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((regionsList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              handleToggleActive={handleToggleActive}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddRegion
          label="systemConfigurations.regions.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showEditModal && (
        <EditRegion
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          label="systemConfigurations.regions.edit"
          selectedRow={{
            id: selectedRow?.id || "",
            nameAr: selectedRow?.nameAr || "",
            nameEn: selectedRow?.nameEn || "",
          }}
        />
      )}

      {showDetailsModal && (
        <RegionsDetails
          id={selectedRow?.id!}
          setShowDetails={setShowDetailsModal}
          showDetails={showDetailsModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={
            "systemConfigurations.regions.actions.delete.dialogTitle"
          }
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          title={"systemConfigurations.regions.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
      {showActivationModal && (
        <AppConfirmModal
          dialogTitle={
            selectedRow?.isActive
              ? "regions.actions.deactivationTitle"
              : "regions.actions.activationTitle"
          }
          open={showActivationModal}
          setOpen={setShowActivationModal}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.nameAr || "",
            selectedRow?.nameEn || ""
          )}
          loading={mutationForToggleActive.isLoading}
          title={
            selectedRow?.isActive
              ? "regions.actions.deactivationConfirmation"
              : "regions.actions.activationConfirmation"
          }
          onConfirm={() => {
            mutationForToggleActive.mutate();
          }}
        />
      )}
    </>
  );
};

export default RegionsPages;
