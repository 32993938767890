import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Rating, Typography } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { Fonts } from "shared/constants/AppEnums";
import {
  IListRatingDetailsDto,
  IListRatingDto,
} from "types/entitiesTypes/workShops";

interface IRatingWorkshopsDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  selectedRow: IListRatingDto;
}

const RatingWorkshopsDetails = (props: IRatingWorkshopsDetailsProps) => {
  const { showDetails, setShowDetails, selectedRow } = props;
  const { locale, messages } = useIntl();

  // const {
  //   data: ratingItem,
  //   error,
  //   isLoading,
  // } = useGetByIdService<IListRatingDetailsDto>(
  //   AppUrls.WorkshopRates,
  //   selectedRow.id
  // );

  return (
    <>
      <BaseDetails
        itemName={""}
        label="systemConfigurations.RatingWorkshops.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={false}
        errorMessage={null!}
        maxWidth="sm"
        columns={12}
      >
        <Grid container spacing={5} item xs={12}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"trainingMaterial"} />
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"clarityObjectives"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.clarityObjectives}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"materialApplication"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.materialApplication}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"durationMaterial"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.durationMaterial * 5}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"instructor"} />
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"integrateTrainingCodes"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.integrateTrainingCodes * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"visualAids"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.visualAids * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"discussions"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.discussions * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"expressClarify"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.expressClarify * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"individualDifferences"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.individualDifferences * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"exercisesCaseStudies"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.exercisesCaseStudies * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"manageCourse"} />{" "}
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.manageCourse * 5}
              readOnly
            />
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"learningMotivation"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.learningMotivation * 5}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.SEMI_BOLD }}>
              <IntlMessages id={"learning"} />
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"courseBenefits"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.courseBenefits * 5}
              readOnly
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: Fonts.REGULAR }}>
              <IntlMessages id={"applicableKnowledge"} />
            </Typography>
            <Rating
              name="read-only"
              value={selectedRow?.applicableKnowledge * 5}
              readOnly
            />
          </Grid>
        </Grid>
      </BaseDetails>
    </>
  );
};

export default RatingWorkshopsDetails;
