import {
  Card,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import { Link } from "react-router-dom";
import IntlMessages from "@crema/utility/IntlMessages";
import AppPermissionsGate, {
  IAppPermissionsProps,
} from "../permessions/AppPermissionsGate";
import { AxiosError } from "axios";
import Error403 from "pages/errorPages/Error403";

export interface IProfileRoutes {
  path: string;
  routeComponent: React.ReactNode;
}

export interface IProfileComponents extends IAppPermissionsProps {
  toPath: string;
  icon: React.ReactNode;
  label: string;
  // show?: boolean;
}

interface IAppProfileProps {
  breadcrumbPaths?: any[];
  currentBreadcrumbPath: string;
  childrenComponents: IProfileComponents[];
  sidebarRoutes: IProfileRoutes[];
  activePath?: number;
  isShowBreadcrumb?: boolean;
  error?: AxiosError<any, any> | undefined;
  withStatus?: boolean;
  statusComponent?: React.ReactNode;
}

const AppProfile: React.FC<IAppProfileProps> = (props) => {
  const {
    breadcrumbPaths,
    currentBreadcrumbPath,
    childrenComponents,
    sidebarRoutes,
    activePath = 5,
    isShowBreadcrumb = true,
    error,
    withStatus = false,
    statusComponent,
  } = props;
  const location = useLocation();
  const path = location.pathname.split("/");
  const currentPath = path[activePath];
  if (error && error?.response?.status == 403) {
    return <Error403 />;
  }

  return (
    <Grid container spacing={4}>
      <AppBreadcrumb
        paths={breadcrumbPaths}
        currentPathName={currentBreadcrumbPath}
        isShow={isShowBreadcrumb}
        withStatus={withStatus}
        StatusComponent={statusComponent!}
      />
      {/* Components */}
      <Grid item xs={12} md={3}>
        <Card>
          <CardContent sx={{ p: 0 }}>
            <List
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {childrenComponents.map((item, index) => {
                return (
                  <Link
                    to={item.toPath}
                    style={{ textDecoration: "none", color: "black" }}
                    key={`childrenComponents${index}`}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 10 }}
                        selected={
                          index == 0 ? !currentPath : currentPath == item.toPath
                        }
                      >
                        <ListItemIcon style={{ minWidth: 30 }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          secondary={<IntlMessages id={item.label} />}
                        />
                      </ListItemButton>
                    </List>
                  </Link>
                  // </AppPermissionsGate>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
      {/**/}
      <Grid item xs={12} md={9}>
        <Routes>
          {sidebarRoutes.map((item, index) => {
            return (
              <Route
                key={`sidebarRoutes${index}`}
                path={item.path}
                element={item.routeComponent}
              />
            );
          })}
        </Routes>
      </Grid>
    </Grid>
  );
};

export default AppProfile;
