import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import defaultImage from "assets/appDefaultImages/branch.png";
import DataItem from "components/basicComponents/mobile/DataItem";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { IStudentListDto } from "types/entitiesTypes/student";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { AxiosError } from "axios";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import StatusBadge from "components/basicComponents/badge/StatusBadge";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";

interface IStudentCardProps {
  StudentsList: Array<IStudentListDto>;
  handleToggleActive: (row: IStudentListDto) => void;
  handleUpdate: (row: IStudentListDto) => void;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
}
const StudentCard: FC<IStudentCardProps> = (props) => {
  const {
    StudentsList,
    pageNumber,
    setPage,
    page,
    loading,
    errorMessage,
    handleUpdate,
    handleToggleActive,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {StudentsList?.length != undefined && StudentsList?.length >= 1 ? (
          StudentsList?.map((item, index) => (
            <Grid key={`StudentsList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                {/* <CardHeader title={} /> */}
                <Box
                  marginX={4}
                  marginTop={3}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  {/* Details */}
                  <AppIconButton
                    label="table.actions.details"
                    withLink={true}
                    toPathLink={`/trainStructure/student/profile/${item.id}`}
                    childComp={
                      <RemoveRedEyeIcon
                        fontSize="small"
                        sx={{ color: "#007991" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Details}
                  />

                  {/* Activation */}
                  <AddDataGridActivation
                    value={item.isActive}
                    handleClick={() => {
                      handleToggleActive(item);
                    }}
                    // For Permissions
                    schemaName={SchemaName.ClientsManagement}
                    entityName={EntityName.Departments}
                    action={PermissionActionsTypes.Activate}
                  />
                  {/* Edit */}
                  <AppIconButton
                    label="table.actions.edit"
                    handleClick={() => {
                      handleUpdate(item);
                    }}
                    childComp={
                      <EditOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#A0CCDA" }}
                      />
                    }
                    // For Permissions
                    schemaName={SchemaName.SystemConfigurations}
                    entityName={EntityName.Countries}
                    action={PermissionActionsTypes.Edit}
                  />
                </Box>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <DataItem
                    label={messages["student.fullName"] as string}
                    value={item.fullName}
                  />
                  <DataItem
                    label={messages["student.lastName"] as string}
                    value={item.lastName}
                  />
                  <DataItem
                    label={messages["common.phone"] as string}
                    value={item.phoneNumber}
                  />

                  <DataItem
                    label={messages["common.nationalNumber"] as string}
                    value={item.nationalNumber}
                  />

                  <DataItem
                    label={messages["common.email"] as string}
                    value={item.email}
                  />
                  <DataItem
                    label={messages["student.academicNumber"] as string}
                    value={item.academicNumber}
                  />
                  {/* <DataItem
                    label={messages["student.jobPosition"] as string}
                    value={item.jobPosition}
                  />
                  <DataItem
                    label={messages["student.address"] as string}
                    value={item.address}
                  /> */}
                  <DataItem
                    label={messages["common.isActive"] as string}
                    value={<StatusBadge isActive={item?.isActive} />}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default StudentCard;
