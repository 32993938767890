import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid } from "@mui/material";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateAttachmentsDto } from "types/entitiesTypes/attachments";
import * as yup from "yup";

interface IAddAttachmentsProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  sessionId: string;
  refetch?: () => {} | null;
}

const AddAttachments: React.FC<IAddAttachmentsProps> = (props) => {
  const { showAddModal, setShowAddModal, label, sessionId, refetch } = props;
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const { messages } = useIntl();
  const refForm = React.useRef<FormikProps<ICreateAttachmentsDto>>(null);

  const mutation = useCreateService<ICreateAttachmentsDto, string>(
    AppUrls.SessionAttachment,
    null,
    true,
    () => {
      if (refetch) refetch();
    },
    false,
    null,
    `${AppUrls.Session}/${sessionId}/Attachments`
  );

  const validationSchema = yup.object({
    nameAr: yup.string().required(String(messages["validation.required"])),
    nameEn: yup.string().required(String(messages["validation.required"])),
    file: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            sessionId: sessionId!,
            nameAr: "",
            nameEn: "",
            file: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameAr" />}
                      name="nameAr"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppTextField
                      label={<IntlMessages id="common.nameEn" />}
                      name="nameEn"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <AppUploadDocument
                    formRef={refForm}
                    keyName={"file"}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    errors={!!errors.file}
                    helperText="validation.file"
                    appGridXs={12}
                    uploadModernXs={6}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddAttachments;
