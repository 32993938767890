import AppSelectField from "@crema/core/AppFormComponents/AppSelectField";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, MenuItem } from "@mui/material";
import AppBaseAutoComplete from "components/basicComponents/inputs/autoComplete/AppBaseAutoComplete";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import AppUploadDocument from "components/basicComponents/uploadFile/uploadDocument/AppUploadDocument";
import useGeneralAutoComplete from "./useGeneralAutoComplete";
import { Form, Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAutocompleteService, useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { DEBOUNCE_TIME } from "shared/constants/AppConst";
import { DiplomasType, typeDocument } from "types/entitiesTypes/enums";
import {
  ICreateStudentAttachments,
  IStudentAttachmentsList,
} from "types/entitiesTypes/studentAttachments";
import * as yup from "yup";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { IStudentListDto } from "types/entitiesTypes/student";
import {
  IStudentsSubscriptionListDto,
  IStudentSubscriptionDetailsDto,
} from "types/entitiesTypes/studentSubscription";
import { ICoursesDiplomaListDto } from "types/entitiesTypes/courses";

interface IAddAttachmentsProps {
  showAddModal: boolean;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddAttachments: React.FC<IAddAttachmentsProps> = (props) => {
  const { showAddModal, setShowAddModal, label } = props;
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const { messages, locale } = useIntl();
  const [studentPageNumber, setStudentPageNumber] = useState(1);
  const [studentKeyword, setStudentKeyword] = useState("");
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);
  const refForm = React.useRef<FormikProps<ICreateStudentAttachments>>(null);
  const [diplomaId, setDiplomaId] = useState("");
  const mutation = useCreateService<ICreateStudentAttachments, string>(
    AppUrls.StudentDocuments,
    null,
    true
  );
  const { studentIdParams } = useParams();
  const validationSchema = yup.object({
    type: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    certificateNumber: yup.string().when("type", {
      is: typeDocument.Certificate,
      then: yup.string().required(String(messages["validation.required"])),
      otherwise: yup.string().nullable(),
    }),
    documentPath: yup
      .array()
      .min(1, String(messages["validation.file"]))
      .required("validation.file"),
    diplomaId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
    studentId: yup
      .string()
      .required(String(messages["validation.required"]))
      .typeError(String(messages["validation.required"])),
  });

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: diplomaList,
    isFetching: isFetchingDiploma,
    refetch: refetchDiploma,
  } = useAutocompleteService<ICoursesDiplomaListDto>(AppUrls.Diplomas, {
    isActive: true,
    keyword: diplomaKeyword,
    pageSize: 10,
    pageNumber: pageNumber,
  });

  useEffect(() => {
    refetchDiploma();
  }, [pageNumber]);

  const {
    data: studentList,
    isFetching: isFetchingStudent,
    refetch,
  } = useAutocompleteService<IStudentsSubscriptionListDto>(
    AppUrls.Subscription,
    {
      isActive: true,
      keyword: studentKeyword,
      pageSize: 10,
      pageNumber: studentPageNumber,
    }
  );

  useEffect(() => {
    refetch();
    // if (refForm?.current) {
    //   const currentValues = refForm.current.values;
    //   refForm.current.setFieldValue("student", "");
    // }
  }, [studentPageNumber]);
  // const {
  //   keyword: studentKeyword,
  //   setKeyword: setStudentKeyword,
  //   dataList: studentList,
  //   isFetching: isFetchingStudent,
  //   pageNumber: studentPageNumber,
  //   setPageNumber: setStudentPageNumber,
  // } = useGeneralAutoComplete<IStudentsSubscriptionListDto>({
  //   url: AppUrls.Subscription,
  // });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        closeModalState={setShowAddModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth={"sm"}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            studentId: "",
            certificateNumber: "",
            type: null!,
            documentPath: "",
            diplomaId: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <AppBaseAutoComplete
                      label="course.diploma"
                      getOptionLabel={(option) =>
                        locale == "en-US" ? option.nameEn : option.nameAr
                      }
                      // defaultValue={branchItem?.state ?? null}
                      options={diplomaList?.data}
                      isLoading={isFetchingDiploma}
                      onChangeAutoComplete={(event, value) => {
                        if (value?.id !== diplomaId) {
                          setFieldValue("diplomaId", value ? value?.id : "");
                          !value && setDiplomaKeyword("");
                        }
                      }}
                      //pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      hasNextPage={diplomaList?.hasNextPage}
                      searchText={diplomaKeyword}
                      onChangeTextField={debounce((event) => {
                        setPageNumber(1);
                        setDiplomaKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors?.diplomaId}
                      touched={touched?.diplomaId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AppBaseAutoComplete
                      // key={`${values?.diplomaId}-${values?.studentId}`}
                      label="student.name"
                      options={studentList?.data
                        .filter((item) => item.diplomaId == values.diplomaId)
                        .map((item) => item.student)}
                      isLoading={isFetchingStudent}
                      onChangeAutoComplete={(event, value) => {
                        setFieldValue("studentId", value ? value?.id : "");
                        !value && setStudentKeyword("");
                      }}
                      getOptionLabel={(option) => option.fullName}
                      filterKey="fullName"
                      //pagination
                      pageNumber={studentPageNumber}
                      setPageNumber={setStudentPageNumber}
                      hasNextPage={studentList?.hasNextPage}
                      searchText={studentKeyword}
                      onChangeTextField={debounce((event) => {
                        setStudentPageNumber(1);
                        setStudentKeyword(event.target.value);
                      }, DEBOUNCE_TIME)}
                      error={errors?.studentId}
                      touched={touched?.studentId}
                      disabled={!values?.diplomaId ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AppSelectField
                      label={<IntlMessages id="student.typeDocument" />}
                      name="type"
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      <MenuItem value={typeDocument.VirtualFile}>
                        <IntlMessages id="student.attachment.virtualFile" />
                      </MenuItem>
                      <MenuItem value={typeDocument.AcademicRecord}>
                        <IntlMessages id="student.attachment.academicRecord" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Statement}>
                        <IntlMessages id="student.attachment.statement" />
                      </MenuItem>
                      <MenuItem value={typeDocument.Certificate}>
                        <IntlMessages id="student.attachment.certificate" />
                      </MenuItem>
                    </AppSelectField>
                  </Grid>
                  {values.type === typeDocument.Certificate && (
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        label={<IntlMessages id="student.certificateNumber" />}
                        name="certificateNumber"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} />
                  <AppUploadDocument
                    formRef={refForm}
                    keyName={"documentPath"}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    errors={!!errors.documentPath}
                    helperText="validation.file"
                    appGridXs={6}
                    uploadModernXs={3}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default AddAttachments;
