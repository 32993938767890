import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useAdvanceFilterAutocompleteService,
  useDeleteService,
  useGetListService,
  useToggleActiveService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { ICategoriesListDto } from "types/entitiesTypes/categories";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IInstructorsListDto } from "types/entitiesTypes/instructors";
import { IRegionsListDto } from "types/entitiesTypes/regions";
import RatingCard from "./RatingCard";
import { RatingColumns } from "./RatingColumns";
import {
  IListRatingDetailsDto,
  IListRatingDto,
  IListRatingSearch,
} from "types/entitiesTypes/workShops";
import RatingWorkshopsDetails from "components/scientificContentManagement/workShops/rating/RatingWorkshops";

const RatingPages = () => {
  const { messages, locale } = useIntl();
  const { WorkShopsIdParams } = useParams();

  const [search, setSearch] = useState<IListRatingSearch>({
    pageNumber: 1,
    pageSize: 10,
    workshopId: WorkShopsIdParams!,
  });
  const [selectedRow, setSelectedRow] = useState<IListRatingDto>();
  const [showDetails, setShowDetails] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  // let objectFilter = {
  //   instructorId: search.instructorId ? search.instructorId : null,
  //   educationalCenterId: search.educationalCenterId
  //     ? search.educationalCenterId
  //     : null,
  //   regionId: search.regionId ? search.regionId : null,
  // };

  // const [instructorKeyword, setInstructorKeyword] = useState<string>("");
  // const [instructorPageNumber, setInstructorPageNumber] = useState(1);

  const {
    resultData: RatingList,
    isFetching,
    error,
  } = useGetListService<IListRatingDto>(AppUrls.WorkshopRates, {
    ...search,
    advancedFilter,
  });

  // const {
  //   data: instructorList,
  //   isFetching: isFetchingInstructors,
  //   refetch: refetchInstructors,
  // } = useAdvanceFilterAutocompleteService<IInstructorsListDto>(
  //   AppUrls.Instructor,
  //   {
  //     keyword: instructorKeyword,
  //     pageSize: 10,
  //     pageNumber: instructorPageNumber,
  //   },
  //   Boolean(!instructorIdParams)
  // );
  // useEffect(() => {
  //   if (Boolean(!instructorIdParams)) refetchInstructors();
  // }, [instructorPageNumber, instructorIdParams]);

  // For details
  const handleDetails = useCallback((selectedRow: IListRatingDto) => {
    setShowDetails(true);
    setSelectedRow(selectedRow);
  }, []);

  const columns = RatingColumns(handleDetails);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            showAddButton={false}
            showAddButtonWithPage={false}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.WorkshopRates}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={RatingList?.totalCount || 0}
            withExportExcelButton={true}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={
              [
                // Boolean(!instructorIdParams) && (
                //   <AppAdvanceFilterAutoComplete
                //     label="Rating.instructor"
                //     fieldName="instructorId"
                //     filterKey="firstNameAr"
                //     getOptionLabel={(option) =>
                //       `${option?.firstNameAr} ${option?.lastNameAr}`
                //     }
                //     options={instructorList?.data}
                //     isLoading={isFetchingInstructors}
                //     searchText={instructorKeyword}
                //     setSearchText={setInstructorKeyword}
                //     onChangeTextField={debounce((event) => {
                //       setInstructorKeyword(event.target.value);
                //     }, DEBOUNCE_TIME)}
                //     //pagination
                //     pageNumber={instructorPageNumber}
                //     setPageNumber={setInstructorPageNumber}
                //     hasNextPage={instructorList?.hasNextPage}
                //     setAdvancedFilter={setSearch}
                //     advancedFilter={search}
                //   />
                // ),
              ]
            }
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={RatingList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={RatingList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <RatingCard
              handleDetails={handleDetails}
              RatingList={RatingList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((RatingList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showDetails && (
        <RatingWorkshopsDetails
          selectedRow={selectedRow!}
          setShowDetails={setShowDetails}
          showDetails={showDetails}
        />
      )}
    </>
  );
};

export default RatingPages;
