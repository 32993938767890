import IntlMessages from "@crema/utility/IntlMessages";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppButton from "components/basicComponents/buttons/AppButton";
import * as React from "react";
interface IAppAddModalProps {
  label: string;
  secLabel?: string;
  withLabel?: boolean;
  open: boolean;
  onClose: () => void;
  loadingForAdd: boolean;
  maxWidthAsPixel?: number;
  maxHeightAsPixel?: number;
  itemName?: string;
  secItemName?: string;

  labelForAddBtn?: string;

  maxWidth?: "lg" | "md" | "sm" | "xs" | "xl";
  handleSubmit: () => void;
  closeModalState: (isOpen: boolean) => void;
  successClose: boolean;
}

const AppAddModal: React.FC<IAppAddModalProps> = (props) => {
  const {
    maxWidthAsPixel,
    maxHeightAsPixel,
    label,
    withLabel = true,
    secLabel,
    open,
    onClose,
    loadingForAdd,
    maxWidth = !maxWidthAsPixel ? "md" : undefined,
    children,
    handleSubmit,
    closeModalState,
    successClose,
    itemName,
    secItemName,
    labelForAddBtn,
  } = props;

  const useDialogStyles = makeStyles({
    paper: {
      minWidth: `${maxWidthAsPixel}px`,
      minHeight: `${maxHeightAsPixel}px`,
      zIndex: "9999 !important",
    },
  });

  const dialogClasses = useDialogStyles();

  React.useEffect(() => {
    if (successClose) closeModalState(false);
  }, [successClose]);

  return (
    <Dialog fullWidth maxWidth={maxWidth} classes={dialogClasses} open={open}>
      <DialogTitle>
        {secLabel && secItemName && (
          <>
            <Typography color="green" variant="h3" fontWeight={"600"}>
              <IntlMessages id={secLabel} />
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {secItemName}
              </span>
              {secItemName && <div style={{ margin: "10px 0" }} />}
            </Typography>
          </>
        )}
        {withLabel && (
          <>
            <Typography color="primary" variant="h3">
              <IntlMessages id={label} />
              {itemName && (
                <span
                  style={{ fontWeight: "bold", textTransform: "capitalize" }}
                >
                  {itemName}
                </span>
              )}
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <AppButton label="common.cancel" color="warning" onClick={onClose} />
        <AppButton
          label={
            labelForAddBtn && labelForAddBtn
              ? `${labelForAddBtn}`
              : "common.add"
          }
          loading={loadingForAdd}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AppAddModal;
