import { Card, CardContent, Grid, Pagination, Typography } from "@mui/material";
import { AxiosError } from "axios";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import DataItem from "components/basicComponents/mobile/DataItem";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { FC } from "react";
import { useIntl } from "react-intl";
import { IAttendanceList } from "types/entitiesTypes/attendance";

interface IAttendancesCardProps {
  AttendancesList: Array<IAttendanceList>;
  pageNumber?: number;
  setPage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  loading: boolean;
  errorMessage: AxiosError<any, any> | null;
  isSelected?: boolean;
}
const AttendancesCard: FC<IAttendancesCardProps> = (props) => {
  const {
    AttendancesList,
    loading,
    errorMessage,
    setPage,
    page,
    pageNumber,
    isSelected,
  } = props;
  const { messages, locale } = useIntl();
  return (
    <Grid container spacing={4}>
      <LoadingAndErrorWrapper
        isLoading={loading}
        errorMessage={errorMessage?.response?.data.messages[0]}
      >
        {AttendancesList?.length != undefined &&
        AttendancesList?.length >= 1 ? (
          AttendancesList?.map((item, index) => (
            <Grid key={`AttendancesList${index}`} item xs={12}>
              <Card sx={{ width: "100%" }}>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                    paddingTop: "10px",
                  }}
                >
                  <DataItem
                    label={messages["session.studentName"] as string}
                    value={item?.studentName}
                  />
                  <DataItem
                    label={messages["educationalCenter.name"] as string}
                    value={getValueBasedOnLang(
                      item?.educationalCenterNameAr,
                      item?.educationalCenterNameEn
                    )}
                  />
                  <DataItem
                    label={messages["diploma.name"] as string}
                    value={getValueBasedOnLang(
                      item?.diplomaNameAr,
                      item?.diplomaNameEn
                    )}
                  />

                  <DataItem
                    label={messages["session.courseName"] as string}
                    value={item?.courseName}
                  />
                  <DataItem
                    label={messages["session.name"] as string}
                    value={item?.sessionName}
                  />
                  <DataItem
                    label={messages["session.date"] as string}
                    value={dateTimeFormatter(item?.sessionDate)}
                  />
                  <DataItem
                    label={messages["columns.status"] as string}
                    value={<StatusAttendance status={item?.status} />}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            marginTop={10}
          >
            <Typography variant="h5" component={"span"}>
              {!isSelected
                ? messages["message.filter"]
                : messages["common.dataFound"]}
            </Typography>
          </Grid>
        )}
        {pageNumber! > 1 && (
          <Grid
            display={"flex"}
            alignItems={"cneter"}
            justifyContent={"center"}
            marginTop={5}
            item
            xs={12}
          >
            <Pagination
              count={pageNumber}
              page={page}
              color="primary"
              onChange={(event, page) => {
                setPage && setPage(event, page);
              }}
            />
          </Grid>
        )}
      </LoadingAndErrorWrapper>
    </Grid>
  );
};

export default AttendancesCard;
