import IntlMessages from "@crema/utility/IntlMessages";
import { Divider, Grid } from "@mui/material";
import defaultImage from "assets/appDefaultImages/user.png";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { removeHTMLTags } from "helpers";
import { useIntl } from "react-intl";
import { IWorkShopsDetailsDto } from "types/entitiesTypes/workShops";

interface IUserDetailsProps {
  WorkShopsItem: IWorkShopsDetailsDto;
}

const WorkShopsDetails = (props: IUserDetailsProps) => {
  const { WorkShopsItem } = props;
  const { locale, messages } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={WorkShopsItem as IWorkShopsDetailsDto}
      >
        <Grid container item spacing={5} xs={12}>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="WorkShops.BasicInformation" />
            </Divider>
          </Grid>
          <AppItemView
            label="common.nameAr"
            value={WorkShopsItem?.nameAr}
            sm={3}
          />
          <AppItemView
            label="common.nameEn"
            value={WorkShopsItem?.nameEn}
            sm={3}
          />

          <AppItemView
            label="WorkShops.educationalCenter"
            value={
              locale === "en-US" && WorkShopsItem?.educationalCenter?.nameEn
                ? WorkShopsItem?.educationalCenter?.nameEn
                : WorkShopsItem?.educationalCenter?.nameAr
            }
            sm={3}
          />
          <AppItemView
            label="WorkShops.region"
            value={
              locale === "en-US" && WorkShopsItem?.region?.nameEn
                ? WorkShopsItem?.region?.nameEn
                : WorkShopsItem?.region?.nameAr
            }
            sm={3}
          />
          {/* <AppItemView
            label="WorkShops.link"
           
            value={
              <AppDataGridCellLink
                // value={`${BaseFileUrl}${params.row?.documentFileKey}`}
                value={WorkShopsItem?.link!}
                label="WorkShops.linkUrl"
              />
            }
            type="text"
            sm={3}
          /> */}
          <AppItemView
            label="common.descriptionAr"
            value={removeHTMLTags(WorkShopsItem?.descriptionAr)}
            sm={3}
          />
          <AppItemView
            label="common.descriptionEn"
            value={removeHTMLTags(WorkShopsItem?.descriptionEn)}
            sm={3}
          />

          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="WorkShops.CourseDetails" />
            </Divider>
          </Grid>
          <AppItemView
            label="WorkShops.instructor"
            value={
              locale === "en-US" && WorkShopsItem?.instructor?.firstNameEn
                ? `${WorkShopsItem?.instructor?.firstNameEn || ""} ${
                    WorkShopsItem?.instructor?.lastNameEn || ""
                  }`
                : `${WorkShopsItem?.instructor?.firstNameAr || ""} ${
                    WorkShopsItem?.instructor?.lastNameAr || ""
                  }`
            }
            sm={3}
          />

          <AppItemView
            label="WorkShops.durationInHours"
            value={WorkShopsItem?.durationInHours}
            sm={3}
          />
          <AppItemView
            label="WorkShops.code"
            value={WorkShopsItem?.code}
            sm={3}
          />
          <AppItemView
            label="common.startDate"
            value={WorkShopsItem?.startDate}
            sm={3}
            type="dateTime"
          />
          <AppItemView
            label="WorkShops.isCertified"
            value={WorkShopsItem?.isCertified}
            sm={3}
            type="isExist"
          />
          <AppItemView
            label="common.isActive"
            value={WorkShopsItem?.isActive}
            type="isActive"
            sm={3}
          />
          <AppItemView
            label="WorkShops.outcomesAr"
            value={removeHTMLTags(WorkShopsItem?.outcomesAr)}
            sm={3}
          />
          <AppItemView
            label="WorkShops.outcomesEn"
            value={removeHTMLTags(WorkShopsItem?.outcomesEn)}
            sm={3}
          />
          <Grid item sm={6} xs={12}>
            <AppViewImage
              image={WorkShopsItem?.imageUrl}
              defaultImage={defaultImage}
              sxStyle={{ width: "70px", height: "70px" }}
              label="WorkShops.image"
              sm={6}
            />
          </Grid>
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default WorkShopsDetails;
