import VideoCallIcon from "@mui/icons-material/VideoCall";
import LoadingAndErrorWrapper from "components/basicComponents/others/LoadingAndErrorWrapper";
import AppProfile, {
  IProfileComponents,
  IProfileRoutes,
} from "components/basicComponents/profile/AppProfile";
import WorkShopsDetails from "components/scientificContentManagement/workShops/WorkShopsDetails";
import { getValueBasedOnLang } from "helpers/index";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { MdGroups } from "react-icons/md";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IWorkShopsDetailsDto } from "types/entitiesTypes/workShops";
import WorkshopParticipantsPages from "./workshopParticipants/WorkshopParticipantsPages";
import WorkShopSessionsPages from "./workshopSessions/WorkshopSessionsPages";
import RatingPages from "./rating/RatingPages";

const WorkShopsProfile: React.FC = () => {
  const { messages } = useIntl();
  const { WorkShopsIdParams } = useParams();
  const {
    data: WorkShopsItem,
    error,
    isLoading,
  } = useGetByIdService<IWorkShopsDetailsDto>(
    AppUrls.WorkShops,
    WorkShopsIdParams || ""
  );
  const sidebarRoutes: IProfileRoutes[] = [
    {
      path: "/",
      routeComponent: <WorkShopsDetails WorkShopsItem={WorkShopsItem!} />,
    },
    {
      path: "/workshopParticipants",
      routeComponent: (
        <WorkshopParticipantsPages workShopItem={WorkShopsItem!} />
      ),
    },
    {
      path: "/workshopSessions",
      routeComponent: <WorkShopSessionsPages />,
    },
    {
      path: "/workshopRates",
      routeComponent: <RatingPages />,
    },
  ];

  const childrenComponents: IProfileComponents[] = [
    {
      toPath: "",
      icon: <HiOutlineInformationCircle size={20} />,
      label: "details",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "workshopParticipants",
      icon: <MdGroups size={20} />,
      label: "Participants",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "workshopSessions",
      icon: <VideoCallIcon fontSize="small" />,
      label: "sidebar.WorkShopSession",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
    {
      toPath: "workshopRates",
      icon: <VideoCallIcon fontSize="small" />,
      label: "sidebar.Rating",
      schemaName: SchemaName.SystemConfigurations,
      entityName: EntityName.States,
      action: PermissionActionsTypes.Details,
    },
  ];

  return (
    <>
      <LoadingAndErrorWrapper
        isLoading={isLoading}
        errorMessage={error?.response?.data.messages[0]}
      >
        <AppProfile
          error={error}
          breadcrumbPaths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
            {
              name: messages["sidebar.WorkShops"],
              path: "/scientificContentManagement/WorkShops",
            },
            {
              name: getValueBasedOnLang(
                WorkShopsItem?.nameAr || "",
                WorkShopsItem?.nameEn || ""
              ),
            },
          ]}
          currentBreadcrumbPath="details"
          sidebarRoutes={sidebarRoutes}
          childrenComponents={childrenComponents}
        />
      </LoadingAndErrorWrapper>
    </>
  );
};

export default WorkShopsProfile;
