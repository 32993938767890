import { GridColDef } from "@mui/x-data-grid";
import StatusAttendance from "components/basicComponents/badge/StatusAttendance";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { getValueBasedOnLang } from "helpers";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { FormattedTime, useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IWorkShopAttendanceList } from "types/entitiesTypes/workshopAttendance";
import moment from "moment";

export const WorkShopAttendanceColumns = (
  handleDetails = (row: IWorkShopAttendanceList) => {},
  handleUpdate = (row: IWorkShopAttendanceList) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();

  const WorkShopAttendanceColumns: GridColDef[] = [
    {
      field: "studentName",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.studentName} />
      ),
    },
    {
      field: locale == "en-US" ? "workshopNameEn" : "workshopNameAr",
      headerName: messages["WorkShops.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.workshopNameAr,
            params.row?.workshopNameEn
          )}
        />
      ),
    },
    // {
    //   field:
    //     locale == "en-US" ? "workshopSessionNameEn" : "workshopSessionNameAr",
    //   headerName: messages["session.name"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell
    //       value={getValueBasedOnLang(
    //         params.row?.workshopSessionNameAr,
    //         params.row?.workshopSessionNameEn
    //       )}
    //     />
    //   ),
    // },
    {
      field: locale == "en-US" ? "instructorNameEn" : "instructorNameAr",
      headerName: messages["instructor.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.instructorNameAr,
            params.row?.instructorNameEn
          )}
        />
      ),
    },
    {
      field: "attendanceTime",
      headerName: messages["session.attendanceTime"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={
            params.row?.attendanceTime
              ? moment(params.row?.attendanceTime).format("HH:MM A")
              : "-"
          }
        />
      ),
    },
    // {
    //   field: "rate",
    //   headerName: messages["WorkShopParticipants.overAllRating"] as string,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 0.2,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => <AppDataGridCell value={params.row?.rate} />,
    // },
    {
      field: "status",
      headerName: messages["columns.status"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StatusAttendance status={params.row?.status} />,
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => handleDetails(params.row)}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
            {/* Edit */}
            {/* <AppIconButton
              label="table.actions.edit"
              handleClick={() => {
                if (params.row.status == 1) handleUpdate(params.row);
              }}
              childComp={
                <EditOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: params.row.status !== 1 ? "#777" : "#A0CCDA",
                    cursor: params.row.status == 1 ? "pointer" : "default",
                  }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            /> */}
          </div>
        );
      },
    },
  ];

  return WorkShopAttendanceColumns;
};
