import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { dateTimeFormatter } from "helpers/string/dateFormatter";
import { useIntl } from "react-intl";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { IPaymentListDto } from "types/entitiesTypes/payment";
import { paymentStatus, paymentType } from "shared/constants/AppEnums";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getValueBasedOnLang } from "helpers";
import { useParams } from "react-router-dom";
import moment from "moment";

export const enumPaymentType = (value) => {
  if (value == 2) return `invoices.payment.paymentType.${paymentType.Cash}`;

  if (value == 3)
    return `invoices.payment.paymentType.${paymentType.E_Payment}`;

  return `invoices.payment.paymentType.${paymentType.Undefined}`;
};

export const enumPaymentStatus = (value) => {
  if (value == 1)
    return `invoices.payment.paymentStatus.${paymentStatus.Approved}`;

  if (value == 2)
    return `invoices.payment.paymentStatus.${paymentStatus.Rejected}`;
  if (value == 3)
    return `invoices.payment.paymentStatus.${paymentStatus.Refunded}`;

  return `invoices.payment.paymentStatus.${paymentStatus.Unpaid}`;
};

export const PaymentColumns = (
  handleDetails = (row: IPaymentListDto) => {},
  handleApprove = (row: IPaymentListDto) => {}
): GridColDef[] => {
  const { messages, locale } = useIntl();
  const { invoiceIdParams, subscriptionIdParams } = useParams();

  const PaymentColumns: GridColDef[] = [
    {
      field: "subscription.student",
      headerName: messages["student.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell value={params.row?.subscription?.student?.fullName} />
      ),
    },
    {
      field:
        locale === "ar-SA"
          ? "subscription.diploma.nameAr"
          : "subscription.diploma.nameEn",
      headerName: messages["diploma.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.subscription?.diploma?.nameAr || "-",
            params.row?.subscription?.diploma?.nameEn || "-"
          )}
        />
      ),
    },
    {
      field:
        locale === "ar-SA"
          ? "subscription.diploma.educationalCenter.nameAr"
          : "subscription.diploma.educationalCenter.nameEn",
      headerName: messages["educationalCenter.name"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={getValueBasedOnLang(
            params.row?.subscription?.diploma?.educationalCenter?.nameAr || "-",
            params.row?.subscription?.diploma?.educationalCenter?.nameEn || "-"
          )}
        />
      ),
    },
    {
      field: "amount",
      headerName: messages["invoices.payment.amount"] as string,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.amount} />,
    },
    // {
    //   field: "paymentNumberS",
    //   headerName: messages["invoices.payment.paymentNumberS"] as string,
    //   headerAlign: "center",
    //   align: "center",

    //   flex: 0.2,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <AppDataGridCell value={params.row?.paymentNumberS} />
    //   ),
    // },
    {
      field: "dueDate",
      headerName: messages["diplomas.installments.dueDate"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={moment(params.row?.dueDate).format("yyyy-MM-DD")}
        />
      ),
    },
    {
      field: "paymentStatus",
      headerName: messages["invoices.payment.paymentStatus"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={String(messages[enumPaymentStatus(params.row?.paymentStatus)])}
        />
      ),
    },

    {
      field: "paymentType",
      headerName: messages["invoices.payment.paymentType"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <AppDataGridCell
          value={String(messages[enumPaymentType(params.row?.paymentType)])}
        />
      ),
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {Boolean(
              !subscriptionIdParams && params.row?.paymentStatus === 0
            ) && (
              <AppIconButton
                label="table.actions.approve"
                handleClick={() => {
                  handleApprove(params?.row);
                }}
                childComp={
                  <CheckCircleIcon
                    fontSize="small"
                    sx={{ color: "#0c8700bd" }}
                  />
                }
                // For Permissions
                schemaName={SchemaName.SystemConfigurations}
                entityName={EntityName.Countries}
                action={PermissionActionsTypes.Details}
              />
            )}

            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params?.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />
          </div>
        );
      },
    },
  ];

  return PaymentColumns;
};
