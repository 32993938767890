import IntlMessages from "@crema/utility/IntlMessages";
import { Divider, Grid } from "@mui/material";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";
import { IDiplomasDetailsDto } from "types/entitiesTypes/diplomas";
import { DiplomasType } from "types/entitiesTypes/enums";
import defaultImage from "assets/appDefaultImages/user.png";
import { useIntl } from "react-intl";
import { removeHTMLTags } from "helpers";

interface IUserDetailsProps {
  diplomasItem: IDiplomasDetailsDto;
}

const DiplomasDetails = (props: IUserDetailsProps) => {
  const { diplomasItem } = props;
  const { locale, messages } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={12}
        moreDetails={diplomasItem as IDiplomasDetailsDto}
      >
        <Grid container item spacing={5} xs={12}>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.BasicInformation" />
            </Divider>
          </Grid>
          <AppItemView
            label="common.nameAr"
            value={diplomasItem?.nameAr}
            sm={3}
          />
          <AppItemView
            label="common.nameEn"
            value={diplomasItem?.nameEn}
            sm={3}
          />

          <AppItemView
            label="diplomas.educationalCenter"
            value={
              locale === "en-US" && diplomasItem?.educationalCenter?.nameEn
                ? diplomasItem?.educationalCenter?.nameEn
                : diplomasItem?.educationalCenter?.nameAr
            }
            sm={3}
          />
          <AppItemView
            label="diplomas.region"
            value={
              locale === "en-US" && diplomasItem?.region?.nameEn
                ? diplomasItem?.region?.nameEn
                : diplomasItem?.region?.nameAr
            }
            sm={3}
          />

          <AppItemView
            label="diplomas.isFree"
            value={diplomasItem?.isFree}
            type="isExist"
            sm={12}
          />
          <AppItemView
            label="common.descriptionAr"
            value={removeHTMLTags(diplomasItem?.descriptionAr)}
            sm={6}
          />
          <AppItemView
            label="common.descriptionEn"
            value={removeHTMLTags(diplomasItem?.descriptionEn)}
            sm={6}
          />
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.CourseDetails" />
            </Divider>
          </Grid>
          <AppItemView
            label="diplomas.instructor"
            value={
              locale === "en-US" && diplomasItem?.instructor?.firstNameEn
                ? `${diplomasItem?.instructor?.firstNameEn || ""} ${
                    diplomasItem?.instructor?.lastNameEn || ""
                  }`
                : `${diplomasItem?.instructor?.firstNameAr || ""} ${
                    diplomasItem?.instructor?.lastNameAr || ""
                  }`
            }
            sm={3}
          />
          <AppItemView
            label="diplomas.instructorSalary"
            value={diplomasItem?.instructorSalary}
            sm={3}
          />
          <AppItemView
            label="diplomas.durationInHours"
            value={diplomasItem?.durationInHours}
            sm={3}
          />
          <AppItemView
            label="diplomas.coursesCount"
            value={diplomasItem?.coursesCount}
            sm={3}
          />
          <AppItemView
            label="common.startDate"
            value={diplomasItem?.startDate}
            sm={3}
            type="dateTime"
          />
          <AppItemView
            label="diplomas.category"
            value={
              locale === "en-US" && diplomasItem?.category?.nameEn
                ? diplomasItem?.category?.nameEn
                : diplomasItem?.category?.nameAr
            }
            sm={3}
          />

          <AppItemView
            label="diplomas.isCertified"
            value={diplomasItem?.isCertified}
            sm={3}
            type="isExist"
          />
          <AppItemView
            label="common.isActive"
            value={diplomasItem?.isActive}
            type="isActive"
            sm={3}
          />
          <AppItemView
            label="diplomas.whatsappChatLink"
            value={diplomasItem?.whatsappChatLink}
            sm={12}
          />
          <Grid item sm={6} xs={12}>
            <AppViewImage
              image={diplomasItem?.coverPhoto}
              defaultImage={defaultImage}
              sxStyle={{ width: "70px", height: "70px" }}
              label="diplomas.coverPhotoPath"
              sm={6}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ color: "#0A8FDC", fontWeight: "bold" }}>
              <IntlMessages id="diplomas.FinancialDetails" />
            </Divider>
          </Grid>

          <AppItemView
            label="diplomas.singlePaymentCost"
            value={diplomasItem?.singlePaymentCost}
            sm={4}
          />
          <AppItemView
            label="diplomas.multiplePaymentsCost"
            value={diplomasItem?.multiplePaymentsCost}
            sm={4}
          />
          {/* <AppItemView
            label="diplomas.discountCost"
            value={diplomasItem?.discountCost}
            sm={4}
          />
          <AppItemView label="diplomas.tax" value={diplomasItem?.tax} sm={4} /> */}

          <AppItemView
            label="diplomas.expenses"
            value={diplomasItem?.expenses}
            sm={4}
          />

          <AppItemView
            label="diplomas.educationalCenterFeesType"
            value={
              diplomasItem?.educationalCenterFeesType ===
              DiplomasType.FixedValue
                ? messages["diplomas.fixedValue"]
                : messages["diplomas.percentageValue"]
            }
            sm={4}
          />

          <AppItemView
            label="diplomas.educationalCenterFees"
            value={diplomasItem?.educationalCenterFees}
            sm={4}
          />

          <AppItemView
            label="diplomas.outcomes"
            value={removeHTMLTags(diplomasItem?.outcomes)}
            sm={12}
          />
        </Grid>
      </BaseProfileDetails>
    </>
  );
};

export default DiplomasDetails;
