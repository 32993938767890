import { useAuthMethod } from "@crema/utility/AuthHooks";
import { Grid } from "@mui/material";
import AppPasswordField from "components/basicComponents/inputs/AppPasswordField";
import AppAddModal from "components/basicComponents/modals/AppAddModal";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useCreateService, useUpdateService } from "services";
import { AppUrls } from "services/appUrls";
import { ISelectedRow } from "types/appTypes/baseEntity/baseDto";
import {
  IChangePasswordDto,
  IInstructorsListDto,
} from "types/entitiesTypes/instructors";
import * as yup from "yup";

interface IChangeInstructorPasswordProps {
  showChangePasswordModal: boolean;
  setShowChangePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  selectedRow: IInstructorsListDto;
}

const ChangeInstructorPassword: React.FC<IChangeInstructorPasswordProps> = (
  props
) => {
  const {
    showChangePasswordModal,
    setShowChangePasswordModal,
    label,
    selectedRow,
  } = props;
  const { messages, locale } = useIntl();

  const refForm = React.useRef<FormikProps<IChangePasswordDto>>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);

  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const mutation = useCreateService<IChangePasswordDto, string>(
    AppUrls.AuthChangePassword,
    null,
    false
  );
  const noArabicRegex = /^[^\u0600-\u06FF]+$/;
  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .matches(noArabicRegex, String(messages["validation.noArabic"]))
      .required(String(messages["validation.required"]))
      .min(6, String(messages["common.password.lengthValidation"])),
    newPassword: yup
      .string()
      .matches(noArabicRegex, String(messages["validation.noArabic"]))
      .required(String(messages["validation.required"]))
      .min(6, String(messages["common.password.lengthValidation"])),
    // confirmNewPassword: yup
    //   .string()
    //   .matches(noArabicRegex, String(messages["validation.noArabic"]))
    //   .required(String(messages["validation.required"]))
    //   .oneOf(
    //     [yup.ref("newPassword"), null],
    //     String(messages["common.cofirmNewPassword.validation"])
    //   ),
  });

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  return (
    <>
      <AppAddModal
        label={label}
        itemName={` - ${
          locale ? selectedRow.firstNameEn : selectedRow.firstNameAr
        }`}
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
        closeModalState={setShowChangePasswordModal}
        successClose={mutation.isSuccess}
        handleSubmit={submit}
        labelForAddBtn="common.save"
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
        maxWidth="sm"
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            userId: selectedRow?.userId!,
            currentPassword: "",
            newPassword: "",
          }}
          validationSchema={validationSchema}
          innerRef={refForm}
          onSubmit={async (data, { setSubmitting }) => {
            mutation.submitData(data);
          }}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <AppPasswordField
                    label="common.currentPassword"
                    name="currentPassword"
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppPasswordField
                    label="common.newPassword"
                    name="newPassword"
                    showPassword={showNewPassword}
                    setShowPassword={setShowNewPassword}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AppAddModal>
    </>
  );
};

export default ChangeInstructorPassword;
