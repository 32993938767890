import { Grid, Hidden } from "@mui/material";

import { GridSelectionModel } from "@mui/x-data-grid";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import AppFilters from "components/basicComponents/filters/AppFilters";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  useAdvanceFilterAutocompleteService,
  useDeleteService,
  useGetListService,
} from "services";
import { AppUrls } from "services/appUrls";
import {
  DEBOUNCE_TIME,
  EntityName,
  SchemaName,
} from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";

import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import AddAttachments from "components/trainStructure/attachments/AddAttachments";
import ImportDocument from "components/trainStructure/attachments/ImportDocument";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { IPdfHeader } from "types/appTypes/baseEntity/baseDto";
import { IDiplomasListDto } from "types/entitiesTypes/diplomas";
import { IStudentListDto } from "types/entitiesTypes/student";
import {
  IStudentAttachmentsList,
  IStudentAttachmentsSearch,
} from "types/entitiesTypes/studentAttachments";
import StudentAttachmentsCard from "./AttachmentsCard";
import { StudentAttachmentsColumns } from "./AttachmentsColumns";
import { getValueBasedOnLang } from "helpers";
import { IEducationalCenterListDto } from "types/entitiesTypes/educationalCenter";

const AttachmentsSidePage = () => {
  const { messages } = useIntl();
  const { studentIdParams } = useParams();
  const [search, setSearch] = useState<IStudentAttachmentsSearch>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const [studentKeyword, setStudentKeyword] = useState<string>("");
  const [studentPageNumber, setStudentPageNumber] = useState(1);

  const [diplomaKeyword, setDiplomaKeyword] = useState<string>("");
  const [diplomaPageNumber, setDiplomaPageNumber] = useState(1);
  let objectFilter = {
    studentId: search.studentId ? search.studentId : null,
    diplomaId: search.diplomaId ? search.diplomaId : null,
    educationalCenterId: search.educationalCenterId
      ? search.educationalCenterId
      : null,
  };
  const [educationalCenterKeyword, setEducationalCenterKeyword] =
    useState<string>("");
  const [educationalCenterPageNumber, setEducationalCenterPageNumber] =
    useState(1);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IStudentAttachmentsList>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivationModal, setShowActivationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // For hide breadcrumb in country profile
  const show = window.location.href;
  // For Advance Filters
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);

  const {
    resultData: studentAttachmentsList,
    isFetching,
    error,
    refetch
  } = useGetListService<IStudentAttachmentsList>(AppUrls.StudentDocuments, {
    ...search,
    advancedFilter,
  });

  // For details
  const handleDetails = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDetailsModal(true);
  }, []);

  // For edit
  const handleUpdate = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowEditModal(true);
  }, []);

  // for delete
  const handleDelete = useCallback((selectedRow: IStudentAttachmentsList) => {
    setSelectedRow(selectedRow);
    setShowDeleteModal(true);
  }, []);

  const mutationForDelete = useDeleteService<string>(
    AppUrls.StudentDocuments,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );
  const pdfHeaders: IPdfHeader[] = [
    {
      key: "subscription.student.id",
      value: String(messages["student.id"]),
    },
    {
      key: "subscription.student.fullName",
      value: String(messages["student.name"]),
    },
    {
      key: "subscription.student.phoneNumber",
      value: String(messages["common.phone"]),
    },
    {
      key: "certificateNumber",
      value: String(messages["student.certificateNumber"]),
    },

    {
      key: "subscription.diploma.nameAr",
      value: String(messages["diploma.nameAr"]),
    },
    {
      key: "subscription.diploma.nameEn",
      value: String(messages["diploma.nameEn"]),
    },
  ];
  const {
    data: educationalCentersList,
    isFetching: isFetchingEducationalCenters,
    refetch: refetchEducationalCenters,
  } = useAdvanceFilterAutocompleteService<IEducationalCenterListDto>(
    AppUrls.EducationalCenter,
    {
      keyword: educationalCenterKeyword,
      pageSize: 10,
      pageNumber: educationalCenterPageNumber,
    }
  );

  const {
    data: diplomasList,
    isFetching: isFetchingDiplomas,
    refetch: refetchDiplomas,
  } = useAdvanceFilterAutocompleteService<IDiplomasListDto>(AppUrls.Diplomas, {
    keyword: diplomaKeyword,
    isActive: true,
    pageSize: 10,
    pageNumber: diplomaPageNumber,
  });

  const {
    data: studentsList,
    isFetching: isFetchingStudents,
    refetch: refetchStudents,
  } = useAdvanceFilterAutocompleteService<IStudentListDto>(
    AppUrls.Student,
    {
      keyword: studentKeyword,
      isActive: true,
      pageSize: 10,
      pageNumber: studentPageNumber,
    },
    Boolean(!studentIdParams)
  );

  useEffect(() => {
    if (diplomaPageNumber > 1) refetchDiplomas();
  }, [diplomaPageNumber]);

  useEffect(() => {
    if (studentPageNumber > 1) refetchStudents();
  }, [studentPageNumber, studentIdParams]);

  useEffect(() => {
    if (educationalCenterPageNumber > 1) refetchEducationalCenters();
  }, [educationalCenterPageNumber]);
  //for Activation

  const columns = StudentAttachmentsColumns(handleDelete);

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          paths={[
            { name: messages["sidebar.scientificContentManagement.title"] },
          ]}
          currentPathName={"sidebar.requestsEducationalDocuments"}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
              advancedFilter,
            }}
            addTitle="student.attachments.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            withExportExcelButton={true}
            showImportButton={true}
            setImportModal={setShowImportModal}
            successUrl={AppUrls.StudentDocuments}
            keyword={search?.keyword}
            isActive={search?.isActive}
            advancedFilter={advancedFilter}
            totalCount={studentAttachmentsList?.totalCount || 0}
            pdfHeaders={pdfHeaders}
            excelFileTitle={String(
              messages["sidebar.requestsEducationalDocuments"]
            )}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            customQueries={{
              ...objectFilter,
            }}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[
              <AppAdvanceFilterAutoComplete
                label="diplomas.name"
                fieldName="diplomaId"
                options={diplomasList?.data}
                isLoading={isFetchingDiplomas}
                searchText={diplomaKeyword}
                setSearchText={setDiplomaKeyword}
                onChangeTextField={debounce((event) => {
                  setDiplomaKeyword(event.target.value);
                }, DEBOUNCE_TIME)}
                //pagination
                pageNumber={diplomaPageNumber}
                setPageNumber={setDiplomaPageNumber}
                hasNextPage={diplomasList?.hasNextPage}
                setAdvancedFilter={setSearch}
                advancedFilter={search}
              />,
              <AppAdvanceFilterAutoComplete
                label="session.studentName"
                fieldName="studentId"
                options={studentsList?.data}
                isLoading={isFetchingStudents}
                searchText={studentKeyword}
                filterKey="fullName"
                getOptionLabel={(option) => option.fullName}
                setSearchText={setStudentKeyword}
                onChangeTextField={debounce((event) => {
                  setStudentKeyword(event.target.value);
                }, DEBOUNCE_TIME)}
                //pagination
                pageNumber={studentPageNumber}
                setPageNumber={setStudentPageNumber}
                hasNextPage={studentsList?.hasNextPage}
                setAdvancedFilter={setSearch}
                advancedFilter={search}
              />,
              <AppAdvanceFilterAutoComplete
                label="diplomas.educationalCenter"
                fieldName="educationalCenterId"
                options={educationalCentersList?.data}
                isLoading={isFetchingEducationalCenters}
                searchText={educationalCenterKeyword}
                setSearchText={setEducationalCenterKeyword}
                onChangeTextField={debounce((event) => {
                  setEducationalCenterKeyword(event.target.value);
                }, DEBOUNCE_TIME)}
                //pagination
                pageNumber={educationalCenterPageNumber}
                setPageNumber={setEducationalCenterPageNumber}
                hasNextPage={educationalCentersList?.hasNextPage}
                advancedFilter={search}
                getOptionLabel={(option) =>
                  getValueBasedOnLang(option.nameAr, option.nameEn)
                }
                setAdvancedFilter={setSearch}
              />,
            ]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={studentAttachmentsList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={studentAttachmentsList?.data ?? []}
              loading={isFetching}
              //for selection
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              checkboxSelection={false}
              selectionModel={selectionModel}
              //for sorting
              onSortModelChange={(result: string[] | null) => {
                setSearch({
                  ...search,
                  orderBy: result,
                });
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <StudentAttachmentsCard
              handleDelete={handleDelete}
              studentAttachmentsList={studentAttachmentsList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil(
                (studentAttachmentsList?.totalCount || 0) / 10
              )}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* Add State */}
      {showAddModal && (
        <AddAttachments
          label="student.attachments"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {showImportModal && (
        <ImportDocument
          label="student.attachments"
          showAddModal={showImportModal}
          setShowAddModal={setShowImportModal}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle="attachment.actions.delete.dialog.title"
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          title="attachment.actions.delete.title"
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}
    </>
  );
};

export default AttachmentsSidePage;
