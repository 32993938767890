import { Grid, Hidden, MenuItem } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import AppBreadcrumb from "components/basicComponents/breadcrumb/AppBreadcrumb";
import AppDataGrid from "components/basicComponents/dataGrid/AppDataGrid";
import { useCallback, useEffect, useState } from "react";
import {
  useAdvanceFilterAutocompleteService,
  useDeleteService,
  useGetListService,
  useNotificationService,
} from "services";
import { AppUrls } from "services/appUrls";
import { NotificationColumns } from "./NotificationColumns";
import {
  INotificationPersonalListDto,
  INotificationPersonalSearch,
} from "types/entitiesTypes/notification";
import NotificationDetailsModal from "components/notification/NotificationDetailsModal";
import { IInstanceNotificationsListDto } from "types/entitiesTypes/instanceNotifications";
import AppConfirmModal from "components/basicComponents/modals/AppConfirmModal";
import { getValueBasedOnLangForConfirmModal } from "helpers";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import AppFilters from "components/basicComponents/filters/AppFilters";
import AddNotification from "components/notification/AddNotification";
import NotificationCard from "./NotificationCard";
import AppAdvanceFilterAutoComplete from "components/basicComponents/filters/AppAdvanceFilterAutoComplete";
import AppAdvanceFilterSelect from "components/basicComponents/filters/AppAdvanceFilterSelect";
import IntlMessages from "@crema/utility/IntlMessages";
import { useIntl } from "react-intl";
export let pageConfingForColumns = {
  pageSize: 10,
  pageNumber: 1,
};

const NotificationPages = () => {
  const { locale } = useIntl();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [isOpenAdvanceFilters, setIsOpenAdvanceFilters] =
    useState<boolean>(false);
  const [search, setSearch] = useState<INotificationPersonalSearch>({
    pageNumber: 1,
    pageSize: 10,
    orderBy: ["createdOn desc"],
  });

  const [selectedRow, setSelectedRow] =
    useState<IInstanceNotificationsListDto>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [showResendNotificationModal, setShowResendNotificationModal] =
    useState<boolean>(false);
  const [showCancelNotificationModal, setShowCancelNotificationModal] =
    useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const {
    resultData: notificationList,
    isFetching,
    refetch,
    error,
  } = useGetListService<IInstanceNotificationsListDto>(
    AppUrls.InstanceNotifications,
    {
      ...search,
    },
    false,
    true
  );

  // For resend Notify
  const handleCancelSendNotification = useCallback(
    (selectedRow: IInstanceNotificationsListDto) => {
      setSelectedRow(selectedRow);
      setShowCancelNotificationModal(true);
    },
    []
  );
  const handleResendNotification = useCallback(
    (selectedRow: IInstanceNotificationsListDto) => {
      setSelectedRow(selectedRow);
      setShowResendNotificationModal(true);
    },
    []
  );
  // for delete
  const handleDelete = useCallback(
    (selectedRow: IInstanceNotificationsListDto) => {
      setSelectedRow(selectedRow);
      setShowDeleteModal(true);
    },
    []
  );

  const handleGetDetails = useCallback(
    (selectedRow: IInstanceNotificationsListDto) => {
      setSelectedRow(selectedRow);
      setShowDetails(true);
    },
    []
  );

  const mutationForDelete = useDeleteService<string>(
    AppUrls.InstanceNotifications,
    selectedRow?.id || "",
    () => setShowDeleteModal(false)
  );

  const mutationForCancel = useNotificationService<boolean>(
    AppUrls.CancelNotification,
    selectedRow?.id || "",
    AppUrls.InstanceNotifications,
    () => setShowCancelNotificationModal(false)
  );

  const mutationForResend = useNotificationService<boolean>(
    AppUrls.ResendNotification,
    selectedRow?.id || "",
    AppUrls.InstanceNotifications,
    () => setShowResendNotificationModal(false)
  );

  const columns = NotificationColumns(
    handleCancelSendNotification,
    handleResendNotification,
    handleDelete,
    handleGetDetails
  );

  return (
    <>
      <Grid container spacing={3}>
        <AppBreadcrumb
          // paths={[{ name: messages["sidebar.notifications.title"] }]}
          currentPathName={"sidebar.notifications.title"}
        />

        <Grid item xs={12}>
          <AppFilters
            //excel pdf
            filterAll={{
              ...search,
            }}
            addTitle="notification.add"
            setShowAddModal={setShowAddModal}
            onChangeKeywordValue={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setSearch({ ...search, keyword: event.target.value });
            }}
            selectionModel={selectionModel as string[]}
            setSelectionModel={setSelectionModel}
            successUrl={AppUrls.Regions}
            keyword={search?.keyword}
            isActive={search?.isActive}
            // For Permissions
            schemaName={SchemaName.SystemConfigurations}
            entityName={EntityName.Countries}
            action={PermissionActionsTypes.Add}
            // Advance Filters
            showAdvanceFiltersButton={true}
            isOpenAdvanceFilters={isOpenAdvanceFilters}
            setIsOpenAdvanceFilters={setIsOpenAdvanceFilters}
            setAdvancedFilter={setAdvancedFilter}
            advanceFiltersInputs={[]}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <AppDataGrid
              rowCount={notificationList?.totalCount ?? 0}
              pageNumber={search?.pageNumber ?? 0}
              //for pagination
              onPageSizeChange={(pageSize: number) => {
                setSearch({ ...search, pageSize: pageSize });
              }}
              onPageChange={(pageNumber: number) => {
                setSearch({ ...search, pageNumber: pageNumber + 1 });
              }}
              pageSize={search?.pageSize ?? 10}
              columns={columns}
              rows={notificationList?.data ?? []}
              loading={isFetching}
              //for selection
              checkboxSelection={false}
              onSelectionModelChange={(
                newSelectionModel: GridSelectionModel
              ) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              //for sorting
              // onSortModelChange={(result: string[] | null) => {
              //   setSearch({
              //     ...search,
              //     orderBy: result,
              //   });
              // }}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <NotificationCard
              handleDelete={handleDelete}
              handleCancelSendNotification={handleCancelSendNotification}
              handleGetDetails={handleGetDetails}
              NotificationList={notificationList?.data!}
              setPage={(event, page) => {
                setSearch({
                  ...search,
                  pageNumber: page,
                });
              }}
              pageNumber={Math.ceil((notificationList?.totalCount || 0) / 10)}
              page={search?.pageNumber}
              loading={isFetching}
              errorMessage={error}
              handleResendNotification={handleResendNotification}
            />
          </Grid>
        </Hidden>
      </Grid>

      {showAddModal && (
        <AddNotification
          label="notification.add"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}

      {/* details */}
      {showDetails && (
        <NotificationDetailsModal
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          selectedRow={selectedRow!}
        />
      )}

      {showCancelNotificationModal && (
        <AppConfirmModal
          dialogTitle={"notification.actions.cancelNotification.dialogTitle"}
          open={showCancelNotificationModal}
          setOpen={setShowCancelNotificationModal}
          loading={mutationForCancel.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.titleAr || "",
            selectedRow?.titleEn || ""
          )}
          title={"notification.actions.cancelNotification.title"}
          onConfirm={() => {
            mutationForCancel.mutate();
          }}
        />
      )}

      {showDeleteModal && (
        <AppConfirmModal
          dialogTitle={"notification.actions.delete.dialogTitle"}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          loading={mutationForDelete.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.titleAr || "",
            selectedRow?.titleEn || ""
          )}
          title={"notification.actions.delete.title"}
          onConfirm={() => {
            mutationForDelete.mutate();
          }}
        />
      )}

      {showResendNotificationModal && (
        <AppConfirmModal
          dialogTitle={"notification.actions.resend.dialogTitle"}
          open={showResendNotificationModal}
          setOpen={setShowResendNotificationModal}
          loading={mutationForResend.isLoading}
          itemName={getValueBasedOnLangForConfirmModal(
            selectedRow?.titleAr || "",
            selectedRow?.titleEn || ""
          )}
          title={"notification.actions.resend.title"}
          onConfirm={() => {
            mutationForResend.mutate();
          }}
        />
      )}
    </>
  );
};

export default NotificationPages;
