import { Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";

import * as React from "react";
import { useIntl } from "react-intl";

interface IAppDataGridProps {
  isSelected?: boolean;
  showMessage?: string;
  headerHeight?: number;
  pageSize: number;
  pageNumber: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (pageNumber: number) => void;
  onSortModelChange?: (result: string[] | null) => void;
  columns: GridColDef[];
  rows: any[];
  loading: boolean;
  checkboxSelection?: boolean;
  rowCount: number;
  //for menu
  disableColumnMenu?: boolean;
  disableColumnSelector?: boolean;
  //for selections
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void;
  selectionModel?: GridSelectionModel;
  // When use data grid with another id key
  getRowId?: (row: any) => any;
}
var arabic = /[\u0600-\u06FF]/;
// const useStyles = makeStyles({
//   dataGrid: {
//     direction: "ltr",
//     transform: "rotateY(180deg)",
//     "& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary":
//       {
//         transform: "rotateY(180deg)",
//         color: "#141414 !important",
//       },
//     "& .MuiDataGrid-columnHeaderTitle": {
//       direction: "rtl !important",
//       transform: "rotateY(180deg)",
//     },

//     "& .MuiDataGrid-footerContainer": {
//       transform: "rotateY(180deg)",
//     },
//     "& .MuiButton-startIcon.MuiButton-iconSizeSmall": {
//       marginLeft: "-2px !important",
//       marginRight: "8px !important",
//     },
//   },
// });
const AppDataGrid: React.FC<IAppDataGridProps> = (props) => {
  const {
    isSelected,
    headerHeight = 40,
    showMessage,
    pageSize,
    pageNumber,
    columns,
    rows,
    loading,
    rowCount,
    onPageSizeChange,
    onPageChange,
    checkboxSelection = true,
    onSelectionModelChange = () => {},
    onSortModelChange,
    disableColumnMenu = true,
    disableColumnSelector = false,
    selectionModel,
    getRowId,
  } = props;

  const langObj = JSON.parse(localStorage.getItem("lan") || "{}");

  const { locale, messages } = useIntl();
  // const classes = useStyles();
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent sx={{ width: "100%" }}>
        <DataGrid
          // className={langObj?.name == "Arabic" ? classes.dataGrid : ""}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={(pageNumber) => onPageChange(pageNumber)}
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
          pageSize={pageSize}
          page={pageNumber - 1}
          //For sorting
          sortingMode="client"
          // onSortModelChange={(modal: GridSortModel) => {
          //   onSortModelChange(
          //     modal.length === 0 ? null : [`${modal[0].field} ${modal[0].sort}`]
          //   );
          // }}
          //for selection
          onSelectionModelChange={(selectionModel) =>
            onSelectionModelChange(selectionModel)
          }
          selectionModel={selectionModel}
          disableSelectionOnClick
          //For style
          headerHeight={headerHeight}
          autoHeight
          disableColumnMenu={disableColumnMenu}
          checkboxSelection={checkboxSelection}
          disableColumnSelector={disableColumnSelector}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={getRowId}
          localeText={{
            footerRowSelected: (count) => {
              if (locale == "en-US") {
                if (count == 1)
                  return `${count.toLocaleString()} ${
                    messages["common.rowSelected"]
                  }`;
                return `${count.toLocaleString()} ${
                  messages["common.rowSelected"]
                }`;
              } else {
                return ` ${count.toLocaleString()} ${
                  messages["common.rowSelected"]
                }`;
              }
            },
            noRowsLabel:
              !isSelected && showMessage
                ? String(messages["message.filter"])
                : locale == "en-US"
                ? "There are no records to display"
                : "لا يوجد بيانات لعرضها",

            MuiTablePagination: {
              labelRowsPerPage:
                locale == "en-US" ? "Rows per page" : "عدد الأسطر في الصفحة",
              labelDisplayedRows: ({ from, to, count }) => {
                return locale == "en-US"
                  ? `${from} - ${to} of  ${count}`
                  : `${from} - ${to} من ${count}`;
              },
            },
          }}
          //rest of props
          columns={columns}
          rows={rows}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

export default AppDataGrid;
