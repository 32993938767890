import { Grid } from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import AppItemView from "components/basicComponents/others/AppItemView";
import AppViewImage from "components/basicComponents/others/AppViewImage";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { IEducationalCenterDetailsDto } from "types/entitiesTypes/educationalCenter";
import defaultImage from "assets/appDefaultImages/user.png";
import BaseProfileDetails from "components/basicComponents/profile/BaseProfileDetails";

interface IEducationalCenterDetailsProps {
  educationalCenterItem: IEducationalCenterDetailsDto;
}

const EducationalCenterDetails = (props: IEducationalCenterDetailsProps) => {
  const { educationalCenterItem } = props;
  const { locale } = useIntl();

  return (
    <>
      <BaseProfileDetails
        columns={16}
        moreDetails={educationalCenterItem as IEducationalCenterDetailsDto}
      >
        <Grid item xs={12} sm={4}>
          <AppViewImage
            image={educationalCenterItem?.managerSignature || null}
            defaultImage={defaultImage}
            sxStyle={{ width: "70px", height: "70px" }}
            label="educationalCenter.managerSignature"
            marginTop={2}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppViewImage
            image={educationalCenterItem?.stamp || null}
            defaultImage={defaultImage}
            sxStyle={{ width: "70px", height: "70px" }}
            label="educationalCenter.stamp"
            marginTop={2}
          />
        </Grid>

        <AppItemView
          label="common.nameAr"
          value={educationalCenterItem?.nameAr}
          sm={4}
        />
        <AppItemView
          label="common.nameEn"
          value={educationalCenterItem?.nameEn}
          sm={4}
        />
        {/* <AppItemView
          label="common.addressAr"
          value={educationalCenterItem?.addressAr}
          sm={4}
        />
        <AppItemView
          label="common.addressEn"
          value={educationalCenterItem?.addressEn}
          sm={4}
        /> */}
        <AppItemView
          label="common.isActive"
          value={educationalCenterItem?.isActive}
          type="isActive"
          sm={4}
        />
      </BaseProfileDetails>
    </>
  );
};

export default EducationalCenterDetails;
