import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridColDef } from "@mui/x-data-grid";
import AddDataGridActivation from "components/basicComponents/dataGrid/AddDataGridActivation";
import AppDataGridCell from "components/basicComponents/dataGrid/AppDataGridCell";
import AppIconButton from "components/basicComponents/others/AppIconButton";
import { useIntl } from "react-intl";
import { PermissionActionsTypes } from "types/entitiesTypes/enums";
import { EntityName, SchemaName } from "shared/constants/AppConst";
import { ICategoriesListDto } from "types/entitiesTypes/categories";
import StatusBadge from "components/basicComponents/badge/StatusBadge";

export const CategoriesColumns = (
  handleDetails = (row: ICategoriesListDto) => {},

  handleUpdate = (row: ICategoriesListDto) => {},
  handleDelete = (row: ICategoriesListDto) => {},
  handleToggleActive = (row: ICategoriesListDto) => {}
): GridColDef[] => {
  const { messages } = useIntl();

  const CategoriesColumns: GridColDef[] = [
    {
      field: "nameAr",
      headerName: messages["common.nameAr"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameAr} />,
    },
    {
      field: "nameEn",
      headerName: messages["common.nameEn"] as string,
      headerAlign: "center",
      align: "center",
      flex: 0.2,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => <AppDataGridCell value={params.row?.nameEn} />,
    },
    {
      field: "isActive",
      headerName: messages["common.isActive"] as string,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      align: "center",
      renderCell: (params) => {
        return <StatusBadge isActive={params?.row?.isActive} />;
      },
    },
    {
      field: "actions",
      headerName: messages["common.actions"] as string,
      sortable: false,
      align: "center",
      filterable: false,
      headerAlign: "center",
      flex: 0.1,

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            {/* Details */}
            <AppIconButton
              label="table.actions.details"
              handleClick={() => {
                handleDetails(params.row);
              }}
              childComp={
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#007991" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Details}
            />

            {/* Edit */}
            <AppIconButton
              label="table.actions.edit"
              handleClick={() => {
                handleUpdate(params.row);
              }}
              childComp={
                <EditOutlinedIcon fontSize="small" sx={{ color: "#A0CCDA" }} />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Edit}
            />
            {/* Activation */}
            <AddDataGridActivation
              value={params.row?.isActive!}
              handleClick={() => {
                handleToggleActive(params.row);
              }}
              // For Permissions
              schemaName={SchemaName.ClientsManagement}
              entityName={EntityName.Departments}
              action={PermissionActionsTypes.Activate}
            />
            {/* Delete */}
            <AppIconButton
              label="table.actions.delete"
              handleClick={() => {
                handleDelete(params.row);
              }}
              childComp={
                <DeleteOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#D72638" }}
                />
              }
              // For Permissions
              schemaName={SchemaName.SystemConfigurations}
              entityName={EntityName.Countries}
              action={PermissionActionsTypes.Delete}
            />
          </div>
        );
      },
    },
  ];

  return CategoriesColumns;
};
