import IntlMessages from "@crema/utility/IntlMessages";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { ColorLibConnector } from "components/basicComponents/modals/stepper/ColorLibConnector";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { useCreateService } from "services";
import { AppUrls } from "services/appUrls";
import { ICreateWorkShopsDto } from "types/entitiesTypes/workShops";
import useValidationSchema from "../validationSchemas";

import { useNavigate, useParams } from "react-router-dom";
import { FirstStep, SecondStep } from "../AddSteps";
import { ColorLibStepIcon } from "../ColorLibStepIcon";
import AppAddWorkShops from "./AppAddWorkShops";
import moment from "moment";

interface IAddWorkShopsProps {
  onSuccess?: () => void;
  onFailed?: () => void;
}

const AddWorkShops: React.FC<IAddWorkShopsProps> = (props) => {
  const { instructorIdParams, educationalCenterIdParams } = useParams();
  const refForm = React.useRef<FormikProps<ICreateWorkShopsDto>>(null);
  const { validationSchemas } = useValidationSchema();
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const steps = ["WorkShops.BasicInformation", "WorkShops.CourseDetails"];

  const navigate = useNavigate();
  const mutation = useCreateService<ICreateWorkShopsDto, string>(
    AppUrls.WorkShops,
    null,
    true,
    () => {
      navigate("/scientificContentManagement/WorkShops");
    }
  );

  const submit = () => {
    if (refForm.current) {
      refForm.current.submitForm();
    }
  };

  const currentValidationSchema = validationSchemas[activeStep];

  const handleNext = async () => {
    if (refForm.current) {
      await refForm.current.validateForm();
      const errors = refForm.current.errors;

      if (Object.keys(errors).length > 0) {
        return;
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubmit = async (data: ICreateWorkShopsDto) => {
    if (activeStep === steps.length - 1) {
      if (uploadedFiles) {
        data = {
          ...data,
          image: uploadedFiles[0],
          startDate: moment.utc(data?.startDate).local().format(),
        };
      }
      await mutation.submitData(data);
    } else {
      handleNext();
    }
  };

  return (
    <>
      <AppAddWorkShops
        handleSubmit={submit}
        handleBack={activeStep > 0 ? () => handleBack() : undefined}
        labelForAddBtn={
          activeStep === steps.length - 1 ? "common.add" : "common.next"
        }
        loadingForAdd={
          (refForm.current?.isSubmitting && !refForm.current?.isValidating) ||
          false
        }
      >
        <Formik
          validationSchema={currentValidationSchema}
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{
            educationalCenterId: educationalCenterIdParams
              ? educationalCenterIdParams
              : "",
            regionId: "",
            instructorId: instructorIdParams ? instructorIdParams : "",
            nameAr: "",
            nameEn: "",
            descriptionAr: "",
            descriptionEn: "",
            code: "",
            durationInHours: null!,
            isCertified: false,
            outcomesAr: "",
            outcomesEn: "",
            startDate: "",
            image: "",
            isActive: true,
          }}
          innerRef={refForm}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values, setFieldTouched }) => {
            return (
              <Form>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  spacing={4}
                >
                  <Grid item>
                    <Stepper
                      activeStep={activeStep}
                      sx={{ py: 3 }}
                      alternativeLabel
                      connector={<ColorLibConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={index}>
                          <StepLabel StepIconComponent={ColorLibStepIcon}>
                            <IntlMessages id={label} />
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid item>
                    {activeStep === 0 && (
                      <FirstStep
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        refForm={refForm}
                      />
                    )}
                    {activeStep === 1 && (
                      <SecondStep
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        // setFieldTouched={setFieldTouched}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AppAddWorkShops>
    </>
  );
};

export default AddWorkShops;
