import IntlMessages from "@crema/utility/IntlMessages";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BaseDetails from "components/basicComponents/details/BaseDetails";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useGetByIdService } from "services";
import { AppUrls } from "services/appUrls";
import { Fonts } from "shared/constants/AppEnums";
import {
  IExamAnswers,
  IExamResultDetailsDto,
} from "types/entitiesTypes/examResult";

interface IExamResultDetailsProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  showDetails: boolean;
  id: string;
}

const ExamResultDetails = (props: IExamResultDetailsProps) => {
  const { showDetails, setShowDetails, id } = props;
  const { locale, messages } = useIntl();

  const {
    data: examResultItem,
    error,
    isLoading,
  } = useGetByIdService<IExamResultDetailsDto>(AppUrls.ExamResult, id);

  const getCurrentOptionForQuestion = useCallback(
    (questionId: string) => {
      return (
        examResultItem?.answersJson?.filter(
          (answerItem) => answerItem?.questionId === questionId
        ) || []
      ); // Return an empty array if answersJson is undefined
    },
    [examResultItem]
  );

  const getQuestions = useCallback(() => {
    return examResultItem?.answersJson.reduce<IExamAnswers[]>(
      (acc, current) => {
        const x = acc.find((item) => item.questionId === current.questionId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    ); // Specify the initial value as an empty array of IExamAnswers
  }, [examResultItem]);
  const questions = getQuestions();

  return (
    <>
      <BaseDetails
        itemName={
          ""
          //   locale == "en-US" && examResultItem?.exam?.nameEn
          //     ? `- ${examResultItem?.exam?.nameEn!}`
          //     : `- ${examResultItem?.exam?.nameAr!}`
        }
        label="examResults.details.label"
        open={showDetails}
        onClose={() => setShowDetails(false)}
        loading={isLoading}
        errorMessage={error}
        moreDetails={examResultItem as IExamResultDetailsDto}
        maxWidth="md"
        columns={12}
      >
        <Grid container>
          <Grid item xs={12}>
            {questions?.length ? (
              questions?.map((item, indexQuestion) => {
                return (
                  <Grid marginY={2} container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: Fonts.SEMI_BOLD,
                        }}
                        component={"span"}
                      >
                        <IntlMessages id="exam.question" /> {indexQuestion + 1}{" "}
                        :{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: Fonts.SEMI_BOLD,
                        }}
                        component={"span"}
                      >
                        {locale == "en-US"
                          ? item?.questionAr
                          : item?.questionEn}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} spacing={2}>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                        aria-readonly
                      >
                        {getCurrentOptionForQuestion(item?.questionId) &&
                          getCurrentOptionForQuestion(item?.questionId)?.map(
                            (answerItem, indexAnswer) => {
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        cursor: "text",

                                        textDecoration:
                                          (answerItem?.isCorrect === true &&
                                            answerItem?.isSelected == true) ||
                                          answerItem?.isSelected === true ||
                                          (answerItem?.isCorrect === false &&
                                            answerItem?.isSelected == false)
                                            ? "unset"
                                            : "line-through",
                                        color:
                                          (answerItem?.isCorrect === true &&
                                            answerItem?.isSelected == true) ||
                                          answerItem?.isSelected === true
                                            ? "green"
                                            : answerItem?.isCorrect === false &&
                                              answerItem?.isSelected == false
                                            ? "black"
                                            : "red",
                                      }}
                                      value={indexAnswer}
                                      control={
                                        answerItem?.type === 2 ? (
                                          <Radio
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                              cursor: "text",
                                              color:
                                                (answerItem?.isCorrect ===
                                                  true &&
                                                  answerItem?.isSelected ==
                                                    true) ||
                                                answerItem?.isSelected === true
                                                  ? "green"
                                                  : answerItem?.isCorrect ===
                                                      false &&
                                                    answerItem?.isSelected ==
                                                      false
                                                  ? "black"
                                                  : "red",
                                              "&.Mui-checked": {
                                                color:
                                                  (answerItem?.isCorrect ===
                                                    true &&
                                                    answerItem?.isSelected ==
                                                      true) ||
                                                  answerItem?.isSelected ===
                                                    true
                                                    ? "green"
                                                    : answerItem?.isCorrect ===
                                                        false &&
                                                      answerItem?.isSelected ==
                                                        false
                                                    ? "black"
                                                    : "red",
                                              },
                                            }}
                                          />
                                        ) : (
                                          <Checkbox
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                              cursor: "text",
                                              color:
                                                (answerItem?.isCorrect ===
                                                  true &&
                                                  answerItem?.isSelected ==
                                                    true) ||
                                                answerItem?.isSelected === true
                                                  ? "green"
                                                  : answerItem?.isCorrect ===
                                                      false &&
                                                    answerItem?.isSelected ==
                                                      false
                                                  ? "black"
                                                  : "red",
                                              "&.Mui-checked": {
                                                color:
                                                  (answerItem?.isCorrect ===
                                                    true &&
                                                    answerItem?.isSelected ==
                                                      true) ||
                                                  answerItem?.isSelected ===
                                                    true
                                                    ? "green"
                                                    : answerItem?.isCorrect ===
                                                        false &&
                                                      answerItem?.isSelected ==
                                                        false
                                                    ? "black"
                                                    : "red",
                                              },
                                            }}
                                          />
                                        )
                                      }
                                      label={
                                        locale === "en-US"
                                          ? answerItem?.optionEn
                                          : answerItem?.optionAr
                                      }
                                      checked={answerItem?.isCorrect}
                                    />

                                    {answerItem?.isSelected &&
                                      answerItem?.isCorrect && (
                                        <CheckIcon sx={{ color: "green" }} />
                                      )}

                                    {answerItem?.isSelected === true ||
                                    (answerItem?.isCorrect === false &&
                                      answerItem?.isSelected === false) ? (
                                      ""
                                    ) : answerItem?.isCorrect &&
                                      answerItem?.isSelected ? (
                                      <CheckIcon sx={{ color: "green" }} />
                                    ) : (
                                      <CloseIcon sx={{ color: "red" }} />
                                    )}
                                  </Grid>
                                </>
                              );
                            }
                          )}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                item
                xs={12}
                marginTop={8}
              >
                <Typography variant="h5" component={"span"}>
                  {messages["common.dataFound"]}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </BaseDetails>
    </>
  );
};

export default ExamResultDetails;
