export const settings = (key: string) => {
  const show =
    key.toLowerCase() === "privacy policy"
      ? "systemConfigurations.systemSettings.privacyPolicy"
      : key.toLowerCase() === "refund period"
      ? "systemConfigurations.systemSettings.refundPeriod"
      : key.toLowerCase() === "tax"
      ? "systemConfigurations.systemSettings.tax"
      : key.toLowerCase() === "terms"
      ? "systemConfigurations.systemSettings.terms"
      : key.toLowerCase() === "allow cash payment"
      ? "systemConfigurations.systemSettings.allowCashPayment"
      : key.toLowerCase() === "certificate count"
      ? "systemConfigurations.systemSettings.certificateCount"
      : key.toLowerCase() === "user count"
      ? "systemConfigurations.systemSettings.userCount"
      : key;
  return show;
};
