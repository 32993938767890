import { queryClient, store } from "App";
import { AxiosError } from "axios";
import { serialize } from "object-to-formdata";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { showMessage } from "redux/actions";
import {
  IBaseCreateDto,
  IBaseUpdateDto,
} from "types/appTypes/baseEntity/baseDto";
import {
  IBaseDashboardReportsResponse,
  IBaseReportResponse,
  IBaseResponse,
  IListBaseResponse,
} from "types/appTypes/response/baseResponse";
import {
  IBaseAutoCompleteSearchDto,
  IBaseReportSearchDto,
  IBaseSearchDto,
} from "types/appTypes/searchDto";
import axiosInstance from "./axiosInstance";
import { BaseActions } from "./baseActions";

export const useGetListService = <TEntity>(
  url: string,
  search?: IBaseSearchDto,
  apiVersion: boolean = false,
  withSearch: boolean = true
) => {
  const [resultData, setResultData] = useState<
    IListBaseResponse<TEntity> | undefined
  >(undefined);
  const request = useQuery(
    [url + "-list", search],
    async () => {
      if (!search) search = { pageNumber: 1, pageSize: 10 };
      if (!search.pageSize) search.pageSize = 10;
      if (!search.pageNumber) search.pageNumber = 1;
      const result = await axiosInstance.post<IListBaseResponse<TEntity>>(
        withSearch ? url.buildUrl(BaseActions.Search, "", apiVersion) : url,
        search
      );
      setResultData(result.data);
      return result.data;
    },
    {
      onError: handleError,
    }
  );

  return { ...request, resultData };
};
export const useNotificationService = <TResponse>(
  url: string,
  id: string | number,
  urlAfterSuccess: string,
  onSuccess: () => void
) => {
  var result;
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.post<IBaseResponse<TResponse>>(url, { id });
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("setAllSuccessfully");
        onSuccess();
        queryClient.invalidateQueries(urlAfterSuccess + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};
export const useAutocompleteService = <TEntity>(
  url: string,
  search?: IBaseAutoCompleteSearchDto,
  isEnable: boolean = true,
  withSearch: boolean = true
) => {
  const request = useQuery(
    [url + "-autoComplete", search],
    async () => {
      if (isEnable) {
        const result = await axiosInstance.post<IListBaseResponse<TEntity>>(
          url.buildUrl(
            withSearch ? BaseActions.Search : BaseActions.RelationSearch,
            "",
            false
          ),
          search
        );
        return result.data;
      }
    },
    {
      onError: handleError,
      enabled: isEnable,
    }
  );

  return { ...request };
};

export const useGetByIdService = <TEntity, T = null>(
  url: string,
  id: string | number,
  options?: T
) => {
  const [error, setError] = useState<AxiosError<any>>();
  const request = useQuery(
    [url + "-id", id],
    async () => {
      try {
        const result = await axiosInstance.get<IBaseResponse<TEntity>>(
          url.buildUrl(BaseActions.ById, id, false),
          { params: { ...options } }
        );

        return result.data?.data;
      } catch (error) {
        const axiosError = error as AxiosError<any>;

        setError(axiosError);
      }
    },
    {
      onError: handleError,
    }
  );

  return { ...request, error: error };
};

export const useCreateService = <
  TBody extends IBaseCreateDto,
  TResponse,
  T = null
>(
  url: string,
  body?: TBody | null,
  asFormData: boolean = true,
  onSuccess?: (data: any) => void,
  withApiVersion: boolean = false,
  options?: T,
  urlAfterSuccess?: string,
  endRefresh: string = "list"
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  let filteredDataHasValue;

  if (body) setDataToSend(body);

  if (dataToSend) {
    filteredDataHasValue = Object.keys(dataToSend)
      .filter((key) => dataToSend[key] !== "" && dataToSend[key] !== null)
      .reduce((obj, key) => {
        obj[key] = dataToSend[key];
        return obj;
      }, {});
  }

  const serializedData = serialize(filteredDataHasValue, {
    noFilesWithArrayNotation: true,
    indices: true,
    allowEmptyArrays: false,
    dotsForObjectNotation: true,
  });

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = asFormData
        ? await axiosInstance.postForm<IBaseResponse<TResponse>>(
            withApiVersion ? url.buildUrl() : url,
            serializedData,
            {
              formSerializer: {
                indexes: true,
                dots: false,

                metaTokens: false,
              },
              params: { ...options },
            }
          )
        : await axiosInstance.post<IBaseResponse<TResponse>>(
            withApiVersion ? url.buildUrl() : url,
            filteredDataHasValue,
            { params: { ...options } }
          );
      return result.data;
    },
    {
      onSuccess: (data) => {
        handleSuccess("createSuccess");
        onSuccess && onSuccess(data?.data);
        if (urlAfterSuccess) {
          queryClient.invalidateQueries(urlAfterSuccess + `-${endRefresh}`);
        } else if (url) {
          queryClient.invalidateQueries(url + `-${endRefresh}`);
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useUpdateService = <TBody extends IBaseUpdateDto, TResponse>(
  url: string,
  onSuccess?: () => void,
  asFormData: boolean = true,
  body?: TBody | null,
  withApiVersion: boolean = false
) => {
  const [dataToSend, setDataToSend] = useState<TBody>();
  let filteredDataHasValue;

  if (body) setDataToSend(body);

  if (dataToSend) {
    filteredDataHasValue = Object.keys(dataToSend)
      .filter((key) => dataToSend[key] !== "" && dataToSend[key] !== null)
      .reduce((obj, key) => {
        obj[key] = dataToSend[key];
        return obj;
      }, {});
  }

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.put<IBaseResponse<TResponse>>(
        withApiVersion ? url.buildUrl() : url,
        asFormData
          ? serialize(filteredDataHasValue, {
              noFilesWithArrayNotation: true,
            })
          : filteredDataHasValue
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("updateSuccess");
        onSuccess && onSuccess();
        if (url) {
          queryClient.invalidateQueries(url + "-list");
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody) => {
    setDataToSend(data);
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

export const useToggleActiveService = <TResponse>(
  url: string,
  id: string | number,
  onSuccess: () => void,
  successfullyTitle?: string,
  unsuccessfullyTitle?: string
) => {
  var result;
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.put<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.ToggleActive, id, false)
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess(
          result.data.data ? "activatedSuccessfully" : "deactivatedSuccessfully"
        );
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useToggleUnlockService = <TResponse>(
  url: string,
  id: string | number,
  onSuccess: () => void,
  successfullyTitle?: string,
  unsuccessfullyTitle?: string
) => {
  var result;
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      result = await axiosInstance.put<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.Unlock, id)
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess(
          result.data.data
            ? `${successfullyTitle}` || "activatedSuccessfully"
            : `${unsuccessfullyTitle}` || "activatedSuccessfully"
        );
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useDeleteService = <TResponse>(
  url: string,
  id: string | number,
  onSuccess: () => void
) => {
  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.delete<IBaseResponse<TResponse>>(
        url.buildUrl(BaseActions.Delete, id, false)
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("deletedSuccessfully");
        onSuccess();
        queryClient.invalidateQueries(url + "-list");
      },
      onError: handleError,
    }
  );

  return { ...request };
};

export const useSetAllService = <TBody, TResponse>(
  url: string,
  body?: TBody | null,
  baseActions?: string,
  onSuccess?: () => void
) => {
  var dataToSend: TBody;
  var successUrl: string;
  if (body) dataToSend = body;

  const request = useMutation<IBaseResponse<TResponse>, AxiosError>(
    url,
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TResponse>>(
        url.buildUrl(baseActions),
        dataToSend
      );
      return result.data;
    },
    {
      onSuccess: () => {
        handleSuccess("setAllSuccessfully");
        onSuccess && onSuccess();
        if (successUrl) {
          queryClient.invalidateQueries(successUrl + "-list");
        }
      },
      onError: handleError,
    }
  );

  const submitData = (data: TBody, hasSuccessUrl?: string) => {
    dataToSend = data;
    if (hasSuccessUrl) successUrl = hasSuccessUrl;
    request.mutate();
  };

  return { ...request, submitData: submitData };
};

// For Reports
export const useGetReportData = <TEntity>(
  url: string,
  search?: IBaseReportSearchDto | null,
  isEnable?: boolean
) => {
  const [reportData, setReportData] = useState<
    IBaseReportResponse<TEntity> | undefined
  >(undefined);
  const request = useQuery(
    [url + "-report", search],
    async () => {
      const result = await axiosInstance.post<IBaseReportResponse<TEntity>>(
        url.buildReportUrl(),
        search
      );
      setReportData(result.data);
      return result.data;
    },
    {
      enabled: isEnable,
      onError: handleError,
    }
  );

  return { ...request, reportData };
};

//For dashboard reports
export const useGetDashboardReportsData = (url: string) => {
  const [dashboardReportData, setDashboardReportData] = useState<
    IBaseDashboardReportsResponse | undefined
  >(undefined);
  const request = useQuery(
    [url + "-report"],
    async () => {
      const result = await axiosInstance.get<IBaseDashboardReportsResponse>(
        url
      );
      setDashboardReportData(result.data);
      return result.data;
    },
    {
      onError: handleError,
    }
  );

  return { ...request, dashboardReportData };
};

// For count of reports
export const useGetReportCount = <TEntity>(
  url: string,
  search?: IBaseReportSearchDto
) => {
  const [reportCount, setReportCount] = useState<
    IBaseResponse<TEntity> | undefined
  >(undefined);

  const request = useQuery(
    [url + "-reportCount", search],
    async () => {
      const result = await axiosInstance.post<IBaseResponse<TEntity>>(
        url.buildReportUrl(),
        search
      );
      setReportCount(result.data);
      return result.data;
    },
    {
      onError: handleError,
    }
  );

  return { ...request, reportCount };
};

// Auto Complete For Advance Filters
export const useAdvanceFilterAutocompleteService = <TEntity>(
  url: string,
  search?: IBaseAutoCompleteSearchDto,
  isEnable?: boolean
) => {
  const request = useQuery(
    [url + "-AdvanceAutoComplete", search],
    async () => {
      const result = await axiosInstance.post<IListBaseResponse<TEntity>>(
        url.buildUrl(BaseActions.Search, "", false),
        search
      );
      return result.data;
    },
    {
      onError: handleError,
      enabled: isEnable,
    }
  );

  return { ...request };
};

// TODO Delete this function (Currently for override it)
export const handleError = (error: AxiosError<any>) => {
  // console.log("error", error);
  // if (error.response) {
  //   if (error.response.status == 401) {
  //     localStorage.removeItem("token");
  //     window.location.reload();
  //     // logout();
  //   } else {
  //     store.dispatch(
  //       fetchError(
  //         (error.response.data as IBackendErrorResponse).messages?.join(",") ??
  //           ""
  //       )
  //     );
  //   }
  // } else {
  //   store.dispatch(fetchError("common.error"));
  // }
};

export const handleSuccess = (message: string) =>
  store.dispatch(showMessage(message));
